import { AIInvocationEvent } from "@/types/ai.typebox";
import { Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";
import { JSONSchema, JSONSchemaObject } from "openai/lib/jsonschema";

export type AIProvider = "openai" | "groq";

export interface AIModel {
  provider: AIProvider;
  modelName: string;
  contextWindow: number;
  inputPrice?: number; // Price per million tokens for input
  outputPrice?: number; // Price per million tokens for output
}

export const AI_MODELS = {
  // OpenAI models
  "4": {
    provider: "openai",
    modelName: "gpt-4o",
    contextWindow: 70000,
    inputPrice: 2.5,
    outputPrice: 10,
  },
  "4om": {
    provider: "openai",
    modelName: "gpt-4o-mini",
    contextWindow: 70000,
    inputPrice: 0.15,
    outputPrice: 0.6,
  },

  // Groq models
  "gemma-9b": {
    provider: "groq",
    modelName: "gemma2-9b-it",
    contextWindow: 8192,
    inputPrice: 0.2,
    outputPrice: 0.2,
  },
  "llama-8b": {
    provider: "groq",
    modelName: "llama-3.1-8b-instant",
    contextWindow: 128000,
    inputPrice: 0.05,
    outputPrice: 0.08,
  },
  "llama-70b": {
    provider: "groq",
    modelName: "llama-3.3-70b-versatile",
    contextWindow: 128000,
    inputPrice: 0.59,
    outputPrice: 0.79,
  },

  // Groq preview models (not guaranteed to be available)
  "preview-qwen-qwq": {
    provider: "groq",
    modelName: "qwen-qwq-32b",
    contextWindow: 128000,
    inputPrice: 0.29,
    outputPrice: 0.39,
  },
  "preview-mistral-saba": {
    provider: "groq",
    modelName: "mistral-saba-24b",
    contextWindow: 128000,
    inputPrice: 0.79,
    outputPrice: 0.79,
  },
  "preview-qwen-2.5": {
    provider: "groq",
    modelName: "qwen-2.5-32b",
    contextWindow: 128000,
    inputPrice: 0.79,
    outputPrice: 0.79,
  },
} as const satisfies Record<string, AIModel>;

export type AIModelKey = keyof typeof AI_MODELS;

export const ChatRole = {
  User: "user",
  Tool: "tool",
  Assistant: "assistant",
  System: "system",
  Function: "function",
  Error: "error",
} as const;
export type ChatRole = (typeof ChatRole)[keyof typeof ChatRole];

export type FunctionCall = { name: string; arguments: string };
export type ToolCall = { type: "function"; function: FunctionCall };
export type ToolCallWithId = { id: string } & ToolCall;

export type OpenAIChatMessage = {
  content: string | null;
  role: "user" | "assistant" | "system" | "function" | "tool";
  function_call?: FunctionCall;
  tool_calls?: ToolCallWithId[];
  tool_call_id?: string;
  refusal?: string;
  /**
   * The name of the author of this message. `name` is required if role is
   * `function`, and it should be the name of the function whose response is in the
   * `content`. May contain a-z, A-Z, 0-9, and underscores, with a maximum length of
   * 64 characters.
   */
  name?: string;
};

export type AIFunction = {
  name: string;
  description: string;

  // the top-level return is always an object.
  parameters: JSONSchemaObject;
};

type StrictJSONSchema =
  | {
      type: "object";
      properties?: Record<string, StrictJSONSchema>;
      additionalProperties: false;
      required?: string[];
      title?: string;
      description?: string;
    }
  | {
      type: "array";
      items: StrictJSONSchema;
      title?: string;
      description?: string;
    }
  | {
      type: "string";
      title?: string;
      enum?: string[];
      description?: string;
    }
  | ({
      title?: string;
      description?: string;
    } & (
      | {
          type: ["object", ...string[]];
          properties: Record<string, StrictJSONSchema>;
          additionalProperties: false;
          required: string[];
        }
      | {
          type: ["array", ...string[]];
          items: StrictJSONSchema;
        }
      | {
          type: ["string", ...string[]];
          enum?: string[];
        }
      | {
          type: ("number" | "boolean" | "null")[];
        }
    ))
  | {
      type: "number" | "boolean" | "null";
      title?: string;
      description?: string;
    };

export type StrictAIFunction = Omit<AIFunction, "parameters"> & {
  // NOTE: if you set this, you must also handle refusals (Currently we throw an error in aiApi.ts)
  strict: true;
  parameters: StrictJSONSchema;
};

export abstract class ExecutableAITool {
  abstract readonly functionSchema: AIFunction;

  abstract execute(argsString: string): string | Promise<string>;
}

export type AIRefusalResponse = {
  refusal?: string | null;
};

export type MessageAttachments = {
  searchId?: string;
  tool_calls?: ToolCallWithId[];
  error?: string;
};

export type UIMessage = {
  id?: string;
  userId?: string;
  content?: string | null;
  role: string;
  tool_call_id?: string;
  attachments?: MessageAttachments;
  createdAt?: Date;
};

export type ChatRequestPayload = {
  entityId: string;
  message: string;
  conversationId: string;
  history: OpenAIChatMessage[];
  model?: AIModelKey;
};

export type AIInvocationMetadata = {
  entityId?: string;
} & Record<string, string>;

export type AIInvocationEvent = Static<typeof AIInvocationEvent>;

export function isAIInvocationEvent(u: unknown): u is AIInvocationEvent {
  return Value.Check(AIInvocationEvent, u);
}
