import entityFinder from "@/entities/entityFinder";
import { HatchetWorkflow } from "@/hatchet/hatchetTypes";
import { cleanBaseUrl } from "@/lib/utils";
import { linkedinCompanySummary } from "@/parsers/linkedinCompanyParser";
import { linkedinProfileSummary } from "@/parsers/linkedinParser";
import {
  CharacteristicType,
  CompanyFact,
  entityMeta,
  EntityType,
  EntityWithAttributes,
  FactsPick,
  FactType,
  isLinkedinCompanyProfile,
  isLinkedinPersonProfile,
  PartialCharacteristic,
  PersonAttributes,
  PersonCompanyRelationship,
  ProfilePageSection,
  RelatedEntityPick,
  RelationshipWithEntity,
  sortRelationships,
  UserMeta,
  userMeta,
  UserWithMeta,
} from "@/types";
import { Entity, User } from "@prisma/client";
import { JsonValue } from "@prisma/client/runtime/library";
import moment from "moment";

export function objToString(json: JsonValue | object, nested = false): string {
  const result: string[] = [];

  if (typeof json == "string") return json;

  if (!json || typeof json != "object") return JSON.stringify(json);

  const possibleProfile = json as PersonAttributes;
  const possibleWorkExperience = json as PersonAttributes["workExperiences"][0];

  // Handle the 'name' key if it exists
  if (possibleProfile.name) {
    result.push(possibleProfile.name);
  }

  if (possibleWorkExperience.startDate) {
    result.push(`${possibleWorkExperience.startDate} - ${possibleWorkExperience.endDate || ""}`);
  }

  const obj = json as Record<string, object>;

  const regularKeys = Object.keys(obj).filter(
    (key) => key != "name" && key != "startDate" && key != "endDate" && typeof obj[key] != "object",
  );

  if (nested) {
    result.push(...regularKeys.map((key) => String(obj[key])));
  } else {
    result.push(...regularKeys.map((key) => `${key}: ${String(obj[key])}`));
  }

  // Handle array keys
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (!value) return;
    if (Array.isArray(value)) {
      result.push(`${key}:`);
      value.forEach((item, index) =>
        result.push("- " + objToString(item as object, true).replace(/\n/g, "\n  ")),
      );
    } else if (typeof value == "object") {
      result.push(`${key}:`);
      result.push(objToString(value, true).replace(/\n/g, "\n  "));
    }
  });

  return result.join(nested ? ", " : "\n");
}

export function entityDescription(entity: EntityWithAttributes): string {
  return (
    entity.type == EntityType.Person && isLinkedinPersonProfile(entity.attributes?.[0]?.value) ?
      linkedinProfileSummary(entity.attributes[0].value)
    : entity.type == EntityType.Company && isLinkedinCompanyProfile(entity.attributes?.[0]?.value) ?
      linkedinCompanySummary(entity.attributes[0].value)
    : entity.attributes && Object.keys(entity.attributes).length > 1 ?
      objToString({ url: entity.url, description: entity.description, ...entity.attributes?.[0] })
    : entity.description || entity.type
  );
}

export const entityUrl = (entity: Partial<Entity> | null | undefined) => {
  return maybeEntityUrl(entity) || "#";
};

export const maybeEntityUrl = (entity: Partial<Entity> | null | undefined) => {
  if (!entity || entityMeta(entity as Pick<Entity, "meta">)?.disableLinks) {
    return undefined;
  }
  if (entity?.slug) {
    return entity.slug;
  }
  if (entity?.id) {
    return `/entity/${entity.id}`;
  }
  if (entity?.url) {
    return `/profile?url=${encodeURIComponent(entity.url)}`;
  }
  return undefined;
};

export const isEntityLinkable = (entity: Partial<Entity> | null | undefined) => {
  if (!entity) return false;
  if (!maybeEntityUrl(entity)) return false;
  for (const name of unlinkableNames) {
    if ((entity.searchName || entity.name?.toLowerCase()) === name) return false;
  }
  return true;
};

export const entityIsUser = (entity: { id: string; url: string }, user: UserWithMeta | User) => {
  const meta = userMeta(user);
  return (
    (entity.id && meta.entity_id == entity.id) ||
    entity.url?.includes(`linkedin.com/in/${meta.li_profile}`)
  );
};

export const entityWorkflow = (entity: Entity): HatchetWorkflow => {
  return entity.type == EntityType.Person ?
      HatchetWorkflow.PersonLoader
    : HatchetWorkflow.CompanyLoader;
};

const unlinkableNames = [
  "Self-Employed",
  "Independent Consultant",
  "Freelance",
  "Consulting",
  "Stealth Startup",
  "Stealth Company",
  "Confidential",
  "Various",
  "Various Startups",
  "Multiple Clients",
  "Personal Projects",
  "Entrepreneur",
  "Career Break",
  "Volunteer Work",
  "Contract Work",
  "Independent Contractor",
  "Unemployed",
  "Advisor",
  "Mentor",
  "Sole Practicioner",
  "Traveling",
  "Reskilling",
  "Upskilling",
  "Self-development",
  "Multiple Startups",
  "Multiple Funds",
  "Funds",
].map((name) => name.toLowerCase());

export const extractImageId = (imageUrl: string) => {
  const match = imageUrl.match(/\/image\/v2\/([^/]+)\//);
  return match ? match[1] : undefined;
};

export const buildWorkAndEducationSections = (relationships: RelationshipWithEntity[]) => {
  const relationshipSections: Partial<Record<ProfilePageSection, RelationshipWithEntity[]>> = {};

  relationships.forEach((relationship) => {
    const section =
      (
        relationship.type == PersonCompanyRelationship.WorkedAt ||
        relationship.type == PersonCompanyRelationship.Founded
      ) ?
        ProfilePageSection.WorkHistory
      : relationship.type == PersonCompanyRelationship.EducatedAt ? ProfilePageSection.Education
      : relationship.type == PersonCompanyRelationship.InvestedIn ? ProfilePageSection.Investments
      : relationship.type == PersonCompanyRelationship.VolunteeredAt ?
        ProfilePageSection.Volunteering
      : relationship.type == PersonCompanyRelationship.OtherExperience ?
        ProfilePageSection.OtherExperience
      : undefined;

    if (section) {
      relationshipSections[section] = [...(relationshipSections[section] || []), relationship];
    }
  });

  // sort all arrays by order, if everything has an order field
  // if not, sort by date, using order as a tie-breaker
  Object.values(relationshipSections).forEach((section) => {
    sortRelationships(section);
  });

  // Remove duplicates from investments
  const investments = relationshipSections[ProfilePageSection.Investments] || [];
  const uniqueInvestments = investments.reduce((acc: RelationshipWithEntity[], curr) => {
    const existingIndex = acc.findIndex((x) => x.toId && x.toId === curr.toId);

    if (existingIndex === -1) {
      acc.push(curr);
      return acc;
    }

    const existing = acc[existingIndex];
    const existingDate = existing.startedDate;
    const currDate = curr.startedDate;

    // Keep experience with earlier date
    if (existingDate && currDate && existingDate > currDate) {
      acc[existingIndex] = curr;
    }
    // Keep the one with a date if other has none
    else if (!existingDate && currDate) {
      acc[existingIndex] = curr;
    }
    // Keep existing if both have no date (first created)
    return acc;
  }, []);

  relationshipSections[ProfilePageSection.Investments] = uniqueInvestments;

  return relationshipSections;
};

export function groupExperiences(
  experiences:
    | (RelationshipWithEntity & { to?: RelatedEntityPick | Entity | undefined | null })[]
    | undefined,
) {
  if (!experiences) return [];
  const result: RelationshipWithEntity[][] = [];
  let previousExperience = undefined;
  let currentGroupedExperiences: RelationshipWithEntity[] = [];
  for (const experience of experiences) {
    if (shouldExperiencesBeSeparated(previousExperience, experience)) {
      result.push(currentGroupedExperiences);
      currentGroupedExperiences = [];
    }
    currentGroupedExperiences.push(experience);
    previousExperience = experience;
  }
  if (currentGroupedExperiences.length > 0) result.push(currentGroupedExperiences);
  return mergeGroupsWithNoTimeBreaks(result);
}

function shouldExperiencesBeSeparated(
  previous: RelationshipWithEntity | undefined,
  current: RelationshipWithEntity,
) {
  if (!previous) return false;
  // We are creating a new group if
  return (
    // We know the current group ID and it's different than current iteration
    (previous.toId && current.toId !== previous.toId) ||
    // We do not know the current group ID, but we know the name and it differs
    (!previous.toId && previous.toName && current.toName !== previous.toName) ||
    // We know nothing - safer to not group
    (!previous.toId && !previous.toName && !current.toId && !current.toName)
  );
}

function mergeGroupsWithNoTimeBreaks(groups: RelationshipWithEntity[][]) {
  const result: RelationshipWithEntity[][] = [];
  // Uses relationship ID of the first experience in group.
  const groupAddedOutOfOrder: Record<string, boolean> = {};
  groups.forEach((group, index) => {
    if (group.length < 1) return;
    if (groupAddedOutOfOrder[group[0].id]) return;
    const otherGroups = findGroupsToMerge(group, groups.slice(index + 1));
    for (const otherGroup of otherGroups) {
      group.push(...otherGroup);
      groupAddedOutOfOrder[otherGroup[0].id] = true;
    }
    result.push(group);
  });
  return result;
}

function findGroupsToMerge(
  originalGroup: RelationshipWithEntity[],
  groups: RelationshipWithEntity[][],
) {
  if (originalGroup.length < 1) return [];
  const result: RelationshipWithEntity[][] = [];
  let lastExperienceOfPreviousGroup = originalGroup[originalGroup.length - 1];
  let previousGroupStarted = moment(lastExperienceOfPreviousGroup.startedDate);
  for (const group of groups) {
    if (group.length < 1) continue;
    const firstExperienceInGroup = group[0];
    if (!shouldExperiencesBeSeparated(lastExperienceOfPreviousGroup, firstExperienceInGroup)) {
      const currentGroupEnded = moment(firstExperienceInGroup.endedDate);
      // add if time difference is less than 2 months
      if (previousGroupStarted.diff(currentGroupEnded, "months") <= 2) {
        result.push(group);
        lastExperienceOfPreviousGroup = group[group.length - 1];
        previousGroupStarted = moment(lastExperienceOfPreviousGroup.startedDate);
      }
    }
  }
  return result;
}

export function buildStatsToShow(facts: FactsPick) {
  const statsToShow: FactType[] = [];
  if (facts[CompanyFact.Employees]) {
    statsToShow.push(CompanyFact.Employees);
  } else if (facts[CompanyFact.CompanySize]) {
    // employees is more fine-grained than company size
    statsToShow.push(CompanyFact.CompanySize);
  }
  // bandage fix for crb issue
  if (facts[CompanyFact.TotalFunding]) {
    const fundingValue = String(facts[CompanyFact.TotalFunding]?.value);
    const dollarCount = (fundingValue.match(/\$/g) || []).length;
    if (dollarCount === 1) {
      statsToShow.push(CompanyFact.TotalFunding);
    }
  }

  if (facts[CompanyFact.LatestRound]) {
    const latestRound = facts[CompanyFact.LatestRound]?.value;
    const latestRoundDate = latestRound?.announcedDate;
    const isRecent = moment().diff(moment(latestRoundDate), "years") <= 2;
    if (isRecent) {
      statsToShow.push(CompanyFact.LatestRound);
    }
  }

  const factTypes = [
    CompanyFact.FoundedYear,
    CompanyFact.NumExits,
    CompanyFact.NumFundsRaised,
    CompanyFact.NumInvestments,
    CompanyFact.LatestFundSize,
    CompanyFact.LatestFundDate,
    CompanyFact.InvestmentRounds,
    CompanyFact.InvestmentSectors,
    CompanyFact.InvestmentGeographies,
    CompanyFact.FamousInvestments,
    CompanyFact.State,
  ];

  for (const factType of factTypes) {
    if (facts[factType]) {
      statsToShow.push(factType);
    }
  }

  return statsToShow;
}

export function buildCharacteristicsToShow(characteristics: PartialCharacteristic | undefined) {
  if (!characteristics) {
    return [];
  }

  const types: CharacteristicType[] = Object.keys(characteristics).map(
    (key) => key as CharacteristicType,
  );

  // show all of them for now
  return types;
}

export function getUserLinkedinProfileURL(userMeta: UserMeta, requireVerified: boolean) {
  if (userMeta.li_profile) {
    return userMeta.li_profile;
  }

  if (!requireVerified && userMeta.li_profile_unv) {
    return userMeta.li_profile_unv;
  }

  return undefined;
}
