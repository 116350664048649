import { RawSectorConfig } from "./types";

// For backwards compatibility with the old sector config
export const sectorV1ToV2Mapping: Record<string, string> = {
  "financial-technology": "financial-services",
  "real-estate-proptech": "real-estate-construction",
  "life-sciences-biotech": "life-sciences",
};

const sectorConfig = {
  generalist: {
    name: "Generalist",
    synonyms: [],
    subcategories: {},
  },
  "ai-ml": {
    name: "Artificial Intelligence & Machine Learning",
    synonyms: [
      "AI",
      "Artificial Intelligence",
      "ML",
      "Machine Learning",
      "Intelligent Systems",
      "Computational Intelligence",
      "Smart Systems",
      "Cognitive Computing",
      "AI/ML",
      "Deep Learning",
    ],
    subcategories: {
      "general-ai": {
        name: "General AI",
        synonyms: [
          "AGI",
          "Artificial General Intelligence",
          "Strong AI",
          "AI Systems",
          "Frontier Technology",
        ],
      },
      "machine-learning": {
        name: "Machine Learning",
        synonyms: [
          "ML",
          "Statistical Learning",
          "Algorithmic Learning",
          "Automated Learning",
          "Deep Learning",
          "MLOps",
        ],
      },
      "computer-vision": {
        name: "Computer Vision",
        synonyms: [
          "CV",
          "Image Recognition",
          "Visual AI",
          "Image Processing",
          "Computer Sight",
          "Visual Recognition",
        ],
      },
      nlp: {
        name: "Natural Language Processing",
        synonyms: [
          "NLP",
          "Text Analytics",
          "Computational Linguistics",
          "Language AI",
          "Text Processing",
          "Language Understanding",
          "Transcription",
        ],
      },
      "generative-ai": {
        name: "Generative AI",
        synonyms: [
          "GenAI",
          "Creative AI",
          "Foundation Models",
          "LLMs",
          "Large Language Models",
          "Content Generation AI",
          "Generative Tech AI",
          "AI Copilots",
        ],
      },
      "ai-infrastructure": {
        name: "AI Infrastructure",
        synonyms: [
          "AI Compute",
          "AI Platforms",
          "Model Deployment",
          "AI Hardware",
          "ML Ops",
          "AI Cloud",
          "AI Systems",
          "Next-Gen Computing",
        ],
      },
      "ai-ethics": {
        name: "AI Ethics & Governance",
        synonyms: [
          "Responsible AI",
          "AI Governance",
          "Ethical AI",
          "AI Safety",
          "AI Alignment",
          "Fair AI",
          "Explainable AI",
        ],
      },
      "autonomous-systems": {
        name: "Autonomous Systems",
        synonyms: [
          "Self-driving",
          "Robotics AI",
          "Autonomous Agents",
          "Autonomous Machines",
          "Self-operating Systems",
          "Automation",
        ],
      },
      "ai-applications": {
        name: "AI Applications",
        synonyms: [
          "AI Tools",
          "Applied AI",
          "Vertical AI",
          "AI Solutions",
          "Domain-specific AI",
          "AI Use Cases",
          "Food AI",
        ],
      },
      "data-extraction": {
        name: "Data & Document Intelligence",
        synonyms: [
          "AI Data Extraction",
          "Intelligent Document Processing",
          "Document Intelligence",
          "Data Extraction",
          "Content Analysis",
        ],
      },
    },
  },
  "enterprise-software": {
    name: "Enterprise Software & SaaS",
    synonyms: [
      "B2B Software",
      "Business Software",
      "Corporate Software",
      "Enterprise Applications",
      "Business Applications",
      "Enterprise Tech",
      "Enterprise Solutions",
      "B2B Tech",
      "B2B",
      "Software Solutions",
      "Software",
    ],
    subcategories: {
      saas: {
        name: "SaaS",
        synonyms: [
          "Software-as-a-Service",
          "Cloud Software",
          "Subscription Software",
          "Web-based Software",
          "Cloud Applications",
          "Enterprise SaaS",
        ],
      },
      "enterprise-applications": {
        name: "Enterprise Applications",
        synonyms: [
          "Business Applications",
          "Corporate Software",
          "Enterprise Management Software",
          "Business Systems",
          "Enterprise Platforms",
          "Operations Software",
        ],
      },
      "enterprise-infrastructure": {
        name: "Enterprise Infrastructure",
        synonyms: [
          "Business Infrastructure",
          "Corporate Infrastructure",
          "Enterprise Systems",
          "Business Technology Stack",
          "Digital Infrastructure",
        ],
      },
      "smb-software": {
        name: "SMB Software",
        synonyms: [
          "Small Business Software",
          "Mid-market Software",
          "Small Enterprise Software",
          "SME Software",
          "Small Business Solutions",
        ],
      },
      "vertical-saas": {
        name: "Vertical SaaS",
        synonyms: [
          "Industry-Specific SaaS",
          "Specialized SaaS",
          "Vertical-Specific Software",
          "Niche SaaS",
          "Domain-Specific SaaS",
        ],
      },
      "it-operations": {
        name: "IT Operations",
        synonyms: [
          "ITOps",
          "IT Management",
          "IT Infrastructure Management",
          "Technology Operations",
          "IT Service Management",
          "Network-as-a-Service",
        ],
      },
      "business-process-automation": {
        name: "Business Process Automation",
        synonyms: [
          "BPA",
          "Workflow Automation",
          "Process Automation",
          "Business Automation",
          "Digital Process Automation",
          "Automation",
        ],
      },
      "customer-experience": {
        name: "Customer Experience & Success",
        synonyms: [
          "CX Technology",
          "Experience Management",
          "Customer Journey Tools",
          "CX Platforms",
          "Experience Tech",
          "Customer Service",
          "Customer Success Automation",
          "Customer Service Training",
          "Customer Service QA",
          "Customer Loyalty",
          "Contact Center Training",
        ],
      },
      "professional-services": {
        name: "Professional Services Technology",
        synonyms: [
          "Legal Tech",
          "Management Consulting",
          "Professional Services Automation",
          "Services Optimization",
          "Consulting Technology",
          "Legal Technology",
        ],
      },
      "crm-sales": {
        name: "CRM & Sales Technology",
        synonyms: [
          "Customer Relationship Management",
          "Sales Enablement",
          "Sales Performance Management",
          "Revenue Operations",
          "Sales Platforms",
          "Sales Enablement Software",
          "Conversation Intelligence",
        ],
      },
      "risk-compliance": {
        name: "Risk & Compliance Management",
        synonyms: [
          "GRC Software",
          "Governance Technology",
          "Compliance Management",
          "Risk Management",
          "Regulatory Technology",
          "Fraud Detection",
          "Data Loss Prevention",
        ],
      },
      "project-management": {
        name: "Project & Resource Management",
        synonyms: [
          "Project Management Software",
          "Resource Planning",
          "Task Management",
          "Project Collaboration",
          "Work Management",
          "Meeting Management Software",
          "Training Management Systems",
        ],
      },
      "enterprise-mobile": {
        name: "Enterprise Mobile Solutions",
        synonyms: [
          "Business Mobile Apps",
          "Enterprise Mobility",
          "Corporate Mobile Solutions",
          "B2B Mobile Applications",
          "Mobile Enterprise",
        ],
      },
      "document-management": {
        name: "Document & Content Management",
        synonyms: [
          "Enterprise Content Management",
          "Document Systems",
          "Content Collaboration",
          "Document Exchange",
          "Information Management",
        ],
      },
      "collaboration-tools": {
        name: "Collaboration Tools",
        synonyms: [
          "Enterprise Collaboration",
          "Team Collaboration",
          "Digital Workplace",
          "Collaborative Software",
          "Productivity Collaboration",
        ],
      },
      "smart-cities": {
        name: "Government & Smart Cities",
        synonyms: [
          "GovTech",
          "Public Sector Technology",
          "Urban Technology",
          "Civic Tech",
          "Municipal Technology",
          "City Infrastructure",
        ],
      },
    },
  },
  "data-analytics": {
    name: "Data & Analytics",
    synonyms: [
      "Big Data",
      "Data Science",
      "Analytics Platforms",
      "Business Intelligence",
      "Data Processing",
      "Data Management",
      "Information Analytics",
      "Data Systems",
      "Analytics",
      "Data",
      "Data Analytics",
    ],
    subcategories: {
      "data-services": {
        name: "Data Services",
        synonyms: [
          "Data Platforms",
          "Data Infrastructure",
          "Data Solutions",
          "Information Services",
          "Data Products",
        ],
      },
      "analytics-platforms": {
        name: "Analytics Platforms",
        synonyms: [
          "BI Tools",
          "Analytical Solutions",
          "Insight Platforms",
          "Data Analytics Platforms",
          "Analysis Tools",
          "Marketing Analytics",
        ],
      },
      "business-intelligence": {
        name: "Business Intelligence",
        synonyms: [
          "Corporate Analytics",
          "Enterprise Insights",
          "Decision Support Systems",
          "Business Analytics",
          "Corporate Intelligence",
          "Value Management",
        ],
      },
      "big-data": {
        name: "Big Data",
        synonyms: [
          "Large Data Sets",
          "Data at Scale",
          "Massive Data",
          "Data Lakes",
          "Data Warehousing",
        ],
      },
      "data-infrastructure": {
        name: "Data Infrastructure",
        synonyms: [
          "Data Engineering",
          "Data Pipelines",
          "Data Architecture",
          "Data Systems",
          "Information Infrastructure",
          "Internet Infrastructure",
        ],
      },
      "data-privacy-governance": {
        name: "Data Privacy & Governance",
        synonyms: [
          "Information Governance",
          "Data Compliance",
          "Data Management",
          "Data Stewardship",
          "Privacy Management",
        ],
      },
      "predictive-analytics": {
        name: "Predictive Analytics",
        synonyms: [
          "Predictive Modeling",
          "Forecasting",
          "Future Analysis",
          "Predictive Intelligence",
          "Advanced Analytics",
        ],
      },
      "realtime-analytics": {
        name: "Real-time Analytics",
        synonyms: [
          "Stream Analytics",
          "Real-time Processing",
          "Live Analytics",
          "Streaming Data Analysis",
          "Event Analytics",
        ],
      },
      "ai-analytics": {
        name: "AI-powered Analytics",
        synonyms: [
          "Machine Learning Analytics",
          "Cognitive Analytics",
          "Intelligent Data Analysis",
          "AI-driven Insights",
          "Automated Analysis",
        ],
      },
      "customer-data": {
        name: "Customer Data Platforms",
        synonyms: [
          "CDP",
          "Customer Analytics",
          "Consumer Data Management",
          "Audience Data",
          "Customer Insights",
          "Personalization",
        ],
      },
    },
  },
  "cloud-infrastructure": {
    name: "Cloud Infrastructure",
    synonyms: [
      "Cloud Computing",
      "Cloud Technology",
      "Cloud Platforms",
      "Cloud Services",
      "Infrastructure-as-a-Service",
      "IaaS",
      "Cloud Architecture",
      "Cloud Systems",
      "Cloudtech",
      "Infrastructure",
    ],
    subcategories: {
      "cloud-services": {
        name: "Cloud Services",
        synonyms: [
          "Hosted Services",
          "Web Services",
          "Remote Computing Services",
          "Cloud-based Services",
          "XaaS",
        ],
      },
      "cloudtech-devops": {
        name: "CloudTech & DevOps",
        synonyms: [
          "Cloud Development",
          "Cloud Operations",
          "Cloud Engineering",
          "DevOps Solutions",
          "Cloud Development Platforms",
        ],
      },
      "distributed-computing": {
        name: "Distributed Computing",
        synonyms: [
          "Distributed Systems",
          "Distributed Processing",
          "Decentralized Computing",
          "Grid Computing",
        ],
      },
      "serverless-computing": {
        name: "Serverless Computing",
        synonyms: [
          "Function-as-a-Service",
          "FaaS",
          "Event-driven Computing",
          "Cloud Functions",
          "Serverless Architecture",
        ],
      },
      "containers-orchestration": {
        name: "Containers & Orchestration",
        synonyms: [
          "Docker",
          "Kubernetes",
          "Container Management",
          "Microservices",
          "Container Orchestration",
        ],
      },
      "edge-computing": {
        name: "Edge Computing",
        synonyms: [
          "Distributed Computing",
          "Fog Computing",
          "On-site Computing",
          "Edge Processing",
          "Edge Networks",
          "Edge AI",
        ],
      },
      "cloud-storage": {
        name: "Cloud Storage",
        synonyms: [
          "Online Storage",
          "Remote Storage",
          "Storage-as-a-Service",
          "Hosted Storage",
          "Digital Storage",
        ],
      },
      "cloud-security": {
        name: "Cloud Security",
        synonyms: [
          "Cloud Protection",
          "Cloud Data Security",
          "Secure Cloud",
          "Cloud Security Posture Management",
          "CSPM",
        ],
      },
      "multicloud-management": {
        name: "Multi-cloud Management",
        synonyms: [
          "Cloud Management",
          "Multi-cloud Operations",
          "Cross-cloud Management",
          "Hybrid Cloud Management",
        ],
      },
      telecommunications: {
        name: "Telecommunications",
        synonyms: [
          "Telecom Technology",
          "Communications Infrastructure",
          "Network Technology",
          "Communications Systems",
          "Networking",
        ],
      },
    },
  },
  security: {
    name: "Security & Identity",
    synonyms: [
      "Cybersecurity",
      "Information Security",
      "InfoSec",
      "Network Security",
      "Computer Security",
      "Digital Security",
      "IT Security",
      "Data Protection",
    ],
    subcategories: {
      cybersecurity: {
        name: "Cybersecurity",
        synonyms: [
          "Digital Defense",
          "Cyber Defense",
          "Information Security",
          "Computer Security",
          "IT Security",
        ],
      },
      "network-security": {
        name: "Network Security",
        synonyms: [
          "Internet Security",
          "Communications Security",
          "Network Defense",
          "Network Protection",
          "Perimeter Security",
        ],
      },
      "application-security": {
        name: "Application Security",
        synonyms: [
          "AppSec",
          "Software Security",
          "Code Security",
          "DevSecOps",
          "Secure Development",
        ],
      },
      "identity-access-security": {
        name: "Identity & Access Security",
        synonyms: [
          "Access Control",
          "Identity Protection",
          "Authentication Security",
          "Authorization Security",
          "Identity Security",
          "Identity & Access Management",
          "IAM",
        ],
      },
      "security-operations": {
        name: "Security Operations",
        synonyms: [
          "SecOps",
          "Security Management",
          "Security Monitoring",
          "SOC",
          "Security Center",
        ],
      },
      "endpoint-security": {
        name: "Endpoint Security",
        synonyms: [
          "Device Security",
          "Endpoint Protection",
          "Client Security",
          "Device Defense",
          "EDR",
        ],
      },
      "data-security": {
        name: "Data Security",
        synonyms: [
          "Information Protection",
          "Data Protection",
          "Data Defense",
          "Information Security",
          "Data Privacy",
        ],
      },
      "security-compliance": {
        name: "Security Compliance",
        synonyms: [
          "Regulatory Security",
          "Compliance Protection",
          "Security Standards",
          "Security Governance",
          "Regulatory Protection",
        ],
      },
      "content-moderation": {
        name: "Content Moderation & Security",
        synonyms: [
          "Digital Content Security",
          "User Generated Content Protection",
          "Content Filtering",
          "Content Safety",
          "Trust & Safety",
        ],
      },
    },
  },
  "web3-blockchain": {
    name: "Web3 & Blockchain",
    synonyms: [
      "Crypto",
      "Decentralized Tech",
      "Distributed Ledger Technology",
      "DLT",
      "Blockchain Technology",
      "Crypto Technology",
      "Digital Assets",
      "Decentralized Systems",
      "Web3",
      "Web3 Crypto",
    ],
    subcategories: {
      cryptocurrency: {
        name: "Cryptocurrency",
        synonyms: ["Digital Currency", "Virtual Currency", "Crypto Assets", "Tokens", "Coins"],
      },
      defi: {
        name: "Decentralized Finance",
        synonyms: [
          "DeFi",
          "Open Finance",
          "Blockchain Finance",
          "Decentralized Banking",
          "Crypto Finance",
        ],
      },
      nfts: {
        name: "NFTs",
        synonyms: [
          "Non-Fungible Tokens",
          "Digital Collectibles",
          "Crypto Collectibles",
          "Digital Assets",
          "Tokenized Assets",
        ],
      },
      "web3-infrastructure": {
        name: "Web3 Infrastructure",
        synonyms: [
          "Decentralized Infrastructure",
          "Crypto Infrastructure",
          "Blockchain Infrastructure",
          "Web3 Platforms",
        ],
      },
      "smart-contracts": {
        name: "Smart Contracts",
        synonyms: [
          "Self-executing Contracts",
          "Code-based Contracts",
          "Digital Contracts",
          "Automated Agreements",
        ],
      },
      daos: {
        name: "DAOs",
        synonyms: [
          "Decentralized Autonomous Organizations",
          "Blockchain Organizations",
          "Decentralized Governance",
          "Web3 Organizations",
        ],
      },
      "crypto-wallets": {
        name: "Crypto Wallets & Custody",
        synonyms: [
          "Digital Wallets",
          "Asset Custody",
          "Crypto Storage",
          "Key Management",
          "Digital Asset Management",
        ],
      },
      "blockchain-analytics": {
        name: "Blockchain Analytics",
        synonyms: [
          "On-chain Analytics",
          "Blockchain Intelligence",
          "Chain Analysis",
          "Crypto Analytics",
          "Transaction Monitoring",
        ],
      },
      "decentralized-science": {
        name: "Decentralized Science",
        synonyms: [
          "DeSci",
          "Open Science",
          "Blockchain Science",
          "Science DAOs",
          "Decentralized Research",
        ],
      },
    },
  },
  "financial-services": {
    name: "Financial Services Technology",
    synonyms: [
      "Fintech",
      "FinTech",
      "Financial Services Technology",
      "Financial Innovation",
      "Digital Finance",
      "Financial Software",
      "Finance Tech",
      "Banking Technology",
      "Financial Technology",
      "Inclusive Fintech",
    ],
    subcategories: {
      payments: {
        name: "Payments",
        synonyms: [
          "Payment Processing",
          "Digital Payments",
          "Payment Tech",
          "Transaction Technology",
          "Payment Solutions",
          "Mobile Payments",
        ],
      },
      "banking-technology": {
        name: "Banking Technology",
        synonyms: [
          "Digital Banking",
          "Neobanks",
          "Challenger Banks",
          "Online Banking",
          "Banking Innovation",
          "Banking as a Service",
          "Commercial Banking",
        ],
      },
      lending: {
        name: "Lending",
        synonyms: [
          "Credit Technology",
          "Loan Tech",
          "Alternative Lending",
          "Digital Lending",
          "Lending Platforms",
          "Commercial Lending",
        ],
      },
      "wealth-management": {
        name: "Wealth Management",
        synonyms: [
          "Investment Technology",
          "Wealth Tech",
          "Investment Management",
          "Digital Wealth",
          "Asset Management Tech",
          "Investment",
        ],
      },
      "insurance-tech": {
        name: "Insurance Tech",
        synonyms: [
          "InsurTech",
          "Digital Insurance",
          "Insurance Innovation",
          "Insurance Technology",
          "Insurtech",
          "Insurance",
        ],
      },
      "personal-finance": {
        name: "Personal Finance",
        synonyms: [
          "PFM",
          "Financial Wellness",
          "Money Management",
          "Financial Health",
          "Consumer Finance",
        ],
      },
      "financial-infrastructure": {
        name: "Financial Infrastructure",
        synonyms: [
          "Banking Infrastructure",
          "Finance Backend",
          "Financial Plumbing",
          "Finance APIs",
          "Financial Systems",
        ],
      },
      regtech: {
        name: "Regtech",
        synonyms: [
          "Regulatory Technology",
          "Compliance Tech",
          "Regulatory Compliance Software",
          "Compliance Solutions",
        ],
      },
      "tax-accounting-software": {
        name: "Tax & Accounting Software",
        synonyms: [
          "Tax Tech",
          "Accounting Technology",
          "Financial Management Software",
          "Tax Automation",
          "Payroll",
        ],
      },
      "b2b-payments": {
        name: "B2B Payments",
        synonyms: [
          "Business Payments",
          "Commercial Payments",
          "Enterprise Payment Solutions",
          "Business Transaction Technology",
        ],
      },
      "mortgage-tech": {
        name: "Mortgage Tech",
        synonyms: [
          "Mortgage Technology",
          "Digital Mortgages",
          "Home Loan Technology",
          "Mortgage Innovation",
          "Property Finance Tech",
        ],
      },
      "investment-capital": {
        name: "Investment & Capital",
        synonyms: [
          "Venture Capital",
          "Private Equity",
          "Investment Platforms",
          "Capital Markets Technology",
          "Investment Management Solutions",
        ],
      },
      "impact-investing": {
        name: "Impact & ESG Investing",
        synonyms: [
          "Sustainable Finance",
          "ESG Technology",
          "Impact Measurement",
          "Social Impact Investing",
          "Green Finance",
          "Impact",
        ],
      },
    },
  },
  "developer-tools": {
    name: "Developer Tools & Engineering",
    synonyms: [
      "Dev Tools",
      "Software Development Tools",
      "Programming Tools",
      "Coding Platforms",
      "Software Engineering Tools",
      "Developer Infrastructure",
      "Engineering Tools",
      "Programming Infrastructure",
    ],
    subcategories: {
      devops: {
        name: "DevOps",
        synonyms: [
          "Development Operations",
          "Software Delivery",
          "Continuous Integration/Deployment",
          "CI/CD",
          "Development Automation",
        ],
      },
      "developer-productivity": {
        name: "Developer Productivity",
        synonyms: [
          "Engineering Productivity",
          "Developer Efficiency",
          "Programming Productivity",
          "Dev Experience",
          "DX",
        ],
      },
      "api-infrastructure": {
        name: "API Infrastructure",
        synonyms: [
          "API Platforms",
          "Interface Tools",
          "Application Programming Interfaces",
          "API Management",
          "API Gateway",
          "Shipping API",
        ],
      },
      "testing-tools": {
        name: "Testing Tools",
        synonyms: [
          "QA Tools",
          "Software Testing",
          "Test Automation",
          "Quality Assurance",
          "Test Management",
        ],
      },
      "code-collaboration": {
        name: "Code Collaboration",
        synonyms: [
          "Software Collaboration",
          "Team Coding",
          "Collaborative Development",
          "Code Sharing",
          "Pair Programming",
        ],
      },
      "development-frameworks": {
        name: "Development Frameworks",
        synonyms: [
          "Coding Frameworks",
          "Programming Frameworks",
          "Software Frameworks",
          "Development Libraries",
          "Code Frameworks",
        ],
      },
      "no-code-low-code-platforms": {
        name: "No-Code/Low-Code Platforms",
        synonyms: [
          "Visual Development",
          "Citizen Development",
          "Drag-and-Drop Development",
          "No Code",
          "Low Code",
        ],
      },
      "ci-cd-tools": {
        name: "CI/CD Tools",
        synonyms: [
          "Continuous Integration",
          "Continuous Deployment",
          "Build Automation",
          "Deployment Automation",
          "Pipeline Tools",
        ],
      },
      "design-tools": {
        name: "Design & Prototyping Tools",
        synonyms: [
          "UX Tools",
          "UI Design",
          "Product Design",
          "Wireframing",
          "Prototyping",
          "UX Innovation",
        ],
      },
      "software-infrastructure": {
        name: "Software Infrastructure",
        synonyms: [
          "Development Infrastructure",
          "Code Infrastructure",
          "Developer Environment",
          "Engineering Infrastructure",
          "Software Architecture",
        ],
      },
      "product-management": {
        name: "Product Management Tools",
        synonyms: [
          "Product Development",
          "Feature Management",
          "Product Analytics",
          "Product Operations",
          "Product Lifecycle Management",
        ],
      },
    },
  },
  "future-of-work": {
    name: "Future of Work & HRtech",
    synonyms: [
      "Workplace Technology",
      "Work Innovation",
      "Workforce Technology",
      "Workplace Transformation",
      "Remote Work Technology",
      "Distributed Work",
      "New Work",
      "Work Tech",
      "Human Capital",
      "HRtech",
      "HR Technology",
    ],
    subcategories: {
      "remote-work-tools": {
        name: "Remote Work Tools",
        synonyms: [
          "Distributed Work Software",
          "Virtual Office Tools",
          "WFH Technology",
          "Remote Collaboration",
          "Telework Tech",
        ],
      },
      "productivity-software": {
        name: "Productivity Software",
        synonyms: [
          "Efficiency Tools",
          "Workplace Productivity",
          "Task Management",
          "Personal Productivity",
          "Workflow Tools",
        ],
      },
      "team-collaboration": {
        name: "Team Collaboration",
        synonyms: [
          "Collaborative Software",
          "Teamwork Platforms",
          "Group Productivity Tools",
          "Team Coordination",
          "Collaboration Tools",
        ],
      },
      "hr-technology": {
        name: "HR Technology",
        synonyms: [
          "Human Resources Tech",
          "People Tech",
          "Talent Tech",
          "Workforce Management",
          "People Operations Tech",
        ],
      },
      "talent-management": {
        name: "Talent Management",
        synonyms: [
          "Workforce Management",
          "Talent Development",
          "Employee Growth",
          "Career Development",
          "Leadership Development",
          "Leadership",
        ],
      },
      "upskilling-learning": {
        name: "Upskilling & Learning",
        synonyms: [
          "Corporate Training Tech",
          "Professional Development Platforms",
          "Workforce Learning",
          "Skills Development",
          "Personal Development",
          "Career Development",
        ],
      },
      "workforce-management": {
        name: "Workforce Management",
        synonyms: [
          "Staff Management",
          "Employee Management",
          "Workforce Solutions",
          "People Management",
          "Workforce Operations",
          "Gig Economy",
        ],
      },
      "freelance-contractor-tools": {
        name: "Freelance & Contractor Tools",
        synonyms: [
          "Independent Worker Tech",
          "Freelancer Platforms",
          "Contractor Management",
          "Gig Worker Tools",
          "1099 Technology",
        ],
      },
      "diversity-inclusion": {
        name: "Diversity & Inclusion",
        synonyms: [
          "DEI Technology",
          "Diversity Tech",
          "Inclusive Workplace",
          "Equity Technology",
          "Social Justice",
          "Diverse Investors",
          "Investors Who Invested in Diverse Founders",
          "Investors Who Invested in Female Founders",
        ],
      },
      "community-engagement": {
        name: "Community & Engagement Platforms",
        synonyms: [
          "Employee Engagement",
          "Workplace Community",
          "Internal Communication",
          "Community Driven",
          "Community Engagement",
        ],
      },
      "video-messaging": {
        name: "Video Collaboration",
        synonyms: [
          "Video Messaging",
          "Screen Recording",
          "Video Creation",
          "Video Editing",
          "Video Translation",
        ],
      },
    },
  },
  "health-technology": {
    name: "Health Technology",
    synonyms: [
      "Healthtech",
      "Digital Health",
      "Healthcare Technology",
      "Medical Technology",
      "Healthcare IT",
      "Health Innovation",
      "MedTech",
      "Health Informatics",
      "Health",
      "Healthcare Technology",
      "Digital Health",
    ],
    subcategories: {
      "health-it": {
        name: "Health IT",
        synonyms: [
          "Healthcare Information Technology",
          "Healthcare Software",
          "Clinical IT",
          "Medical IT",
          "Health Systems",
        ],
      },
      "digital-health": {
        name: "Digital Health",
        synonyms: [
          "Virtual Health",
          "Connected Health",
          "eHealth",
          "Health Tech",
          "Digital Medicine",
        ],
      },
      "health-hospital-services": {
        name: "Health & Hospital Services",
        synonyms: [
          "Clinical Services",
          "Hospital Tech",
          "Medical Services",
          "Clinical Operations Technology",
          "Provider Technology",
          "Healthcare Services",
          "Healthcare Service",
        ],
      },
      "consumer-health": {
        name: "Consumer Health",
        synonyms: [
          "Personal Health Tech",
          "Wellness Technology",
          "Patient Technology",
          "Health Consumer Tech",
          "Direct Health Services",
          "Human Health",
        ],
      },
      telehealth: {
        name: "Telehealth",
        synonyms: [
          "Telemedicine",
          "Remote Healthcare",
          "Virtual Care",
          "Remote Medicine",
          "Digital Care",
        ],
      },
      "mental-health-tech": {
        name: "Mental Health Tech",
        synonyms: [
          "Digital Therapeutics",
          "Behavioral Health Tech",
          "Mental Wellness Technology",
          "Psychiatric Technology",
          "Mental Health",
          "Precision Psychiatry",
        ],
      },
      "medical-devices": {
        name: "Medical Devices",
        synonyms: [
          "MedTech Devices",
          "Medical Hardware",
          "Clinical Devices",
          "Health Devices",
          "Medical Equipment Technology",
        ],
      },
      "healthcare-data-analytics": {
        name: "Healthcare Data Analytics",
        synonyms: [
          "Health Analytics",
          "Medical Data",
          "Clinical Analytics",
          "Health Insights",
          "Medical Intelligence",
        ],
      },
      "remote-patient-monitoring": {
        name: "Remote Patient Monitoring",
        synonyms: [
          "RPM",
          "Remote Monitoring",
          "Patient Tracking",
          "Connected Care",
          "Remote Care Technology",
        ],
      },
      femtech: {
        name: "Femtech",
        synonyms: [
          "Female Health Technology",
          "Women's Health Tech",
          "Female Technology",
          "Women's Health Innovation",
        ],
      },
      "pet-tech": {
        name: "Pet Tech",
        synonyms: [
          "Animal Health Technology",
          "Veterinary Technology",
          "Pet Care Tech",
          "Pet Health Innovation",
          "Animal Technology",
          "Pet",
        ],
      },
      "care-management": {
        name: "Care Management",
        synonyms: [
          "Patient Care Technology",
          "Care Coordination",
          "Care Navigation",
          "Patient Management",
          "CareNav",
          "CareOps",
          "CareStack",
          "CareCoordination",
          "CareService",
          "CareAutomation",
          "CareProcessAutomation",
          "CareEcosystem",
          "CareTech",
        ],
      },
    },
  },
  "life-sciences": {
    name: "Life Sciences & Biotechnology",
    synonyms: [
      "Biotechnology",
      "Life Sciences Technology",
      "Biological Technology",
      "BioTech",
      "Health Sciences",
      "Biological Sciences",
      "Biomedical Technology",
      "Life Sciences Innovation",
      "Bio",
      "TechBio",
    ],
    subcategories: {
      "genomics-precision-medicine": {
        name: "Genomics & Precision Medicine",
        synonyms: [
          "Genetic Technology",
          "DNA Technology",
          "Gene Sequencing",
          "Personalized Medicine",
          "Genomic Medicine",
        ],
      },
      therapeutics: {
        name: "Therapeutics",
        synonyms: [
          "Treatment Development",
          "Drug Technology",
          "Medicine Development",
          "Therapy Innovation",
          "Pharmaceutical Tech",
          "Specialty Therapeutics",
        ],
      },
      diagnostics: {
        name: "Diagnostics",
        synonyms: [
          "Medical Testing",
          "Health Diagnostics",
          "Clinical Diagnostics",
          "Diagnostic Technology",
          "Testing Innovation",
        ],
      },
      "drug-discovery": {
        name: "Drug Discovery",
        synonyms: [
          "Pharma R&D",
          "Pharmaceutical Research",
          "Medicine Discovery",
          "Drug Development",
          "Therapeutic Development",
        ],
      },
      "clinical-trials-tech": {
        name: "Clinical Trials Tech",
        synonyms: [
          "Trial Management",
          "Clinical Research Technology",
          "Trial Innovation",
          "Study Technology",
          "Clinical Studies Tech",
        ],
      },
      "lab-automation": {
        name: "Lab Automation",
        synonyms: [
          "Laboratory Robotics",
          "Automated Research",
          "Scientific Automation",
          "Research Automation",
          "Lab Robotics",
        ],
      },
      bioinformatics: {
        name: "Bioinformatics",
        synonyms: [
          "Biological Data Science",
          "Computational Biology",
          "Biological Informatics",
          "Bio IT",
          "Life Sciences Data",
        ],
      },
      "synthetic-biology": {
        name: "Synthetic Biology",
        synonyms: [
          "SynBio",
          "Engineering Biology",
          "Biological Engineering",
          "Synthetic Genomics",
          "Biological Design",
        ],
      },
      oncology: {
        name: "Oncology",
        synonyms: [
          "Cancer Technology",
          "Cancer Treatment Tech",
          "Cancer Research Tech",
          "Cancer Therapeutics",
          "Oncology Innovation",
        ],
      },
      nanotechnology: {
        name: "Nanotechnology",
        synonyms: [
          "Nanoscience",
          "Molecular Technology",
          "Nanotech",
          "Nanomaterials",
          "Nanoscale Engineering",
        ],
      },
      biopharmaceuticals: {
        name: "Biopharmaceuticals",
        synonyms: [
          "Biologics",
          "Biopharma",
          "Biological Drugs",
          "Biotherapeutics",
          "Pharmaceuticals",
        ],
      },
      "biomedical-research": {
        name: "Biomedical Research",
        synonyms: [
          "Medical Research",
          "Biomedical Science",
          "Medical Science",
          "Biomedical Technology",
          "Biotechnology Research",
        ],
      },
      "gene-therapy": {
        name: "Gene Therapy",
        synonyms: [
          "Genetic Therapeutics",
          "Gene Editing",
          "CRISPR Technology",
          "Genetic Medicine",
          "DNA Therapeutics",
        ],
      },
      biomanufacturing: {
        name: "Biomanufacturing",
        synonyms: [
          "Biological Manufacturing",
          "Bio-Production",
          "Biological Process Technology",
          "Bio-Processing",
          "Biological Fabrication",
        ],
      },
      neurotechnology: {
        name: "Neurotechnology",
        synonyms: [
          "Brain Technology",
          "Neural Technology",
          "Neuroscience Tech",
          "Brain-Computer Interfaces",
          "Neural Engineering",
        ],
      },
      "longevity-tech": {
        name: "Longevity Tech",
        synonyms: [
          "Life Extension Technology",
          "Anti-aging Tech",
          "Longevity Science",
          "Age-related Disease Tech",
          "Lifespan Extension",
        ],
      },
    },
  },
  "consumer-technology": {
    name: "Consumer Technology",
    synonyms: [
      "Consumer Tech",
      "B2C Technology",
      "Consumer Internet",
      "Consumer Applications",
      "Consumer Platforms",
      "Consumer Digital",
      "Retail Technology",
      "Consumer Software",
      "Consumer Goods",
    ],
    subcategories: {
      "consumer-internet": {
        name: "Consumer Internet",
        synonyms: [
          "Internet Consumer Services",
          "Online Consumer Platforms",
          "Web Consumer",
          "Internet Services",
        ],
      },
      "consumer-products": {
        name: "Consumer Products",
        synonyms: [
          "Consumer Goods Tech",
          "Retail Products",
          "Consumer Hardware",
          "Smart Consumer Products",
        ],
      },
      "social-networks": {
        name: "Social Networks",
        synonyms: [
          "Social Media",
          "Social Platforms",
          "Community Platforms",
          "Online Communities",
          "Social Applications",
          "Social Technology",
        ],
      },
      "consumer-mobile-apps": {
        name: "Consumer Mobile Apps",
        synonyms: [
          "Mobile Applications",
          "Consumer Apps",
          "Smartphone Applications",
          "Lifestyle Apps",
          "Mobile Technologies",
        ],
      },
      "consumer-subscriptions": {
        name: "Consumer Subscriptions",
        synonyms: [
          "Subscription Services",
          "Recurring Consumer Services",
          "Consumer Membership",
          "Subscription Economy",
        ],
      },
      "social-commerce": {
        name: "Social Commerce",
        synonyms: [
          "Social Shopping",
          "Community Commerce",
          "Social Selling",
          "F-Commerce",
          "Community-Driven Commerce",
        ],
      },
      "direct-to-consumer": {
        name: "Direct-to-Consumer",
        synonyms: [
          "D2C",
          "DTC",
          "Direct Retail",
          "Brand-Direct",
          "Consumer Brands",
          "Direct Brand",
          "Next-gen Commerce",
          "New Commerce",
        ],
      },
      "consumer-privacy": {
        name: "Consumer Privacy",
        synonyms: [
          "Data Privacy",
          "Personal Privacy",
          "Consumer Data Protection",
          "Privacy Tech",
          "Digital Privacy",
        ],
      },
      "beauty-personal-care": {
        name: "Beauty & Personal Care",
        synonyms: [
          "Beauty Tech",
          "Personal Care Tech",
          "Cosmetics Technology",
          "Beauty Innovation",
          "Personal Care Innovation",
          "Beauty",
          "Personal Household Care",
        ],
      },
      "wellness-fitness": {
        name: "Wellness & Fitness",
        synonyms: [
          "Wellness Technology",
          "Fitness Tech",
          "Health & Wellness",
          "Lifestyle Health",
          "LOHAS",
          "Wellness",
          "Wellness Fitness",
        ],
      },
      "dating-relationships": {
        name: "Dating & Relationships",
        synonyms: [
          "Dating Technology",
          "Matchmaking",
          "Relationship Apps",
          "Dating Platforms",
          "Connection Technology",
          "Dating",
          "Dating Apps",
        ],
      },
      "fashion-apparel": {
        name: "Fashion & Apparel Tech",
        synonyms: [
          "Fashion Tech",
          "Apparel Technology",
          "Clothing Tech",
          "Style Technology",
          "Fashion Innovation",
          "Fashion",
        ],
      },
    },
  },
  marketplaces: {
    name: "Marketplaces & Ecommerce",
    synonyms: [
      "Digital Marketplaces",
      "Platform Businesses",
      "Two-sided Marketplaces",
      "Multi-sided Platforms",
      "Exchange Platforms",
      "Online Marketplaces",
      "Peer-to-Peer Platforms",
      "Ecommerce",
    ],
    subcategories: {
      ecommerce: {
        name: "E-commerce",
        synonyms: [
          "Electronic Commerce",
          "Online Retail",
          "Internet Commerce",
          "Digital Commerce",
          "Online Shopping",
          "Commerce Tech",
          "E-commerce Infrastructure",
        ],
      },
      "b2b-marketplaces": {
        name: "B2B Marketplaces",
        synonyms: [
          "Business Marketplaces",
          "Wholesale Platforms",
          "Industrial Marketplaces",
          "Enterprise Marketplaces",
        ],
      },
      "service-marketplaces": {
        name: "Service Marketplaces",
        synonyms: [
          "Service Platforms",
          "Professional Service Marketplaces",
          "Gig Service Platforms",
          "Digital Service Markets",
        ],
      },
      "rental-sharing-economy": {
        name: "Rental & Sharing Economy",
        synonyms: [
          "Sharing Economy",
          "Collaborative Consumption",
          "Access Economy",
          "On-demand Services",
          "Asset Sharing",
        ],
      },
      "labor-gig-economy": {
        name: "Labor & Gig Economy Platforms",
        synonyms: [
          "Freelance Platforms",
          "On-demand Labor",
          "Independent Work Platforms",
          "Gig Work",
          "Flexible Labor Markets",
        ],
      },
      "vertical-marketplaces": {
        name: "Vertical Marketplaces",
        synonyms: [
          "Industry-Specific Marketplaces",
          "Specialized Marketplaces",
          "Niche Marketplaces",
          "Category-Specific Platforms",
        ],
      },
      "marketplace-infrastructure": {
        name: "Marketplace Infrastructure",
        synonyms: [
          "Platform Infrastructure",
          "Marketplace Technology",
          "Platform Technology",
          "Multi-sided Market Tools",
        ],
      },
      "mobile-commerce": {
        name: "Mobile Commerce",
        synonyms: [
          "M-commerce",
          "Mobile Shopping",
          "App-based Commerce",
          "Mobile Retail",
          "Mobile Transactions",
        ],
      },
      "retail-technology": {
        name: "Retail Technology",
        synonyms: [
          "Retail Innovation",
          "Retail Solutions",
          "Store Technology",
          "Retail Operations Tech",
          "Retail",
        ],
      },
      "nextgen-commerce": {
        name: "Next-Gen Commerce",
        synonyms: [
          "Future Retail",
          "Innovative Commerce",
          "Digital-first Retail",
          "Commerce 3.0",
          "Experiential Commerce",
        ],
      },
    },
  },
  "media-content": {
    name: "Media, Content & Entertainment",
    synonyms: [
      "Digital Media",
      "Content Technology",
      "Media Tech",
      "Entertainment Technology",
      "Digital Content",
      "Media Platforms",
      "Content Distribution",
      "Content Creation Technology",
      "Media",
      "Media/Content",
      "Entertainment",
    ],
    subcategories: {
      "content-creation-tools": {
        name: "Content Creation Tools",
        synonyms: [
          "Creation Software",
          "Media Production",
          "Content Development",
          "Creative Tools",
          "Media Creation",
        ],
      },
      "content-distribution": {
        name: "Content Distribution",
        synonyms: [
          "Media Distribution",
          "Content Delivery",
          "Distribution Platforms",
          "Content Networks",
          "Media Circulation",
        ],
      },
      "creator-economy": {
        name: "Creator Economy",
        synonyms: [
          "Content Creators",
          "Influencer Platforms",
          "Creator Tools",
          "Creator Platforms",
          "Individual Media",
          "Influencer Marketing",
        ],
      },
      "entertainment-technology": {
        name: "Entertainment Technology",
        synonyms: [
          "Entertainment Tech",
          "Media Entertainment",
          "Digital Entertainment",
          "Interactive Entertainment",
          "Entertainment Platforms",
        ],
      },
      "gaming-esports": {
        name: "Gaming & Esports",
        synonyms: [
          "Video Games",
          "Game Development",
          "Competitive Gaming",
          "Game Technology",
          "Interactive Entertainment",
          "Gaming",
          "Games",
          "Gaming Technology",
          "Esports Technologies",
        ],
      },
      "digital-media": {
        name: "Digital Media",
        synonyms: [
          "Online Media",
          "New Media",
          "Internet Media",
          "Web Publishing",
          "Digital Publishing",
        ],
      },
      "streaming-platforms": {
        name: "Streaming Platforms",
        synonyms: [
          "Video Streaming",
          "Content Streaming",
          "OTT Platforms",
          "Media Streaming",
          "Streaming Services",
        ],
      },
      "audio-technology": {
        name: "Audio Technology",
        synonyms: [
          "Sound Tech",
          "Audio Processing",
          "Sound Innovation",
          "Audio Products",
          "Sound Technology",
          "Audiotech",
        ],
      },
      "ephemeral-content": {
        name: "Ephemeral Content",
        synonyms: [
          "Disappearing Content",
          "Temporary Media",
          "Time-Limited Content",
          "Vanishing Media",
          "Ephemeral Messaging",
        ],
      },
      "event-management": {
        name: "Event & Experience Technology",
        synonyms: [
          "Event Tech",
          "Event Platforms",
          "Experience Management",
          "Virtual Events",
          "Event Management",
        ],
      },
    },
  },
  "marketing-technology": {
    name: "Marketing & Advertising Technology",
    synonyms: [
      "MarTech",
      "Marketing Tech",
      "Digital Marketing Technology",
      "Marketing Software",
      "Marketing Platforms",
      "Marketing Automation",
      "Marketing Infrastructure",
      "Marketing Stack",
      "Digital Marketing",
      "Marketing",
      "Advertising",
      "Adtech",
      "Marketing Analytics",
      "Marketingtech",
    ],
    subcategories: {
      adtech: {
        name: "Adtech",
        synonyms: [
          "Advertising Technology",
          "Digital Advertising",
          "Programmatic Advertising",
          "Ad Technology",
          "Ad Platforms",
        ],
      },
      "marketing-automation": {
        name: "Marketing Automation",
        synonyms: [
          "Campaign Automation",
          "Marketing Workflows",
          "Automated Marketing",
          "Marketing Process Automation",
        ],
      },
      "influencer-marketing": {
        name: "Influencer Marketing",
        synonyms: [
          "Creator Marketing",
          "Social Media Marketing",
          "Influencer Management",
          "Influencer Platforms",
          "Creator Collaboration",
        ],
      },
      "performance-marketing": {
        name: "Performance Marketing",
        synonyms: [
          "Results-based Marketing",
          "ROI Marketing",
          "Conversion Marketing",
          "Performance Advertising",
          "Measurable Marketing",
        ],
      },
      "seo-sem-tools": {
        name: "SEO & SEM Tools",
        synonyms: [
          "Search Marketing",
          "Search Engine Tools",
          "Search Optimization",
          "Search Marketing Platforms",
          "Search Technology",
          "SEO",
        ],
      },
      "marketing-analytics": {
        name: "Marketing Analytics",
        synonyms: [
          "Marketing Measurement",
          "Marketing Intelligence",
          "Campaign Analytics",
          "Marketing Performance",
          "Marketing Insights",
        ],
      },
      "content-marketing": {
        name: "Content Marketing",
        synonyms: [
          "Content Strategy",
          "Brand Content",
          "Inbound Marketing",
          "Content Management",
          "Story Marketing",
        ],
      },
      "account-based-marketing": {
        name: "Account-Based Marketing",
        synonyms: [
          "ABM",
          "Target Account Marketing",
          "Key Account Marketing",
          "Enterprise Marketing",
          "Account-Based Marketing",
        ],
      },
      "marketing-channels": {
        name: "Marketing Channels & Integration",
        synonyms: [
          "Omnichannel Marketing",
          "Marketing Stack Integration",
          "Channel Management",
          "Marketing Touchpoints",
          "Integrated Marketing",
        ],
      },
      "personalization-engines": {
        name: "Personalization Engines",
        synonyms: [
          "Dynamic Content",
          "Customized Marketing",
          "Personalized Experiences",
          "Individual Marketing",
          "One-to-One Marketing",
        ],
      },
    },
  },
  "agriculture-food": {
    name: "Agriculture & Food Technology",
    synonyms: [
      "AgTech",
      "Agricultural Technology",
      "Farming Technology",
      "AgriTech",
      "Agriculture Innovation",
      "Digital Agriculture",
      "Smart Farming",
      "Precision Agriculture",
      "FoodTech",
      "Food Technology",
      "Food Systems",
      "Food & Agriculture",
      "Agri-Food",
      "Agri-Tech",
      "Agriculture",
      "Agriculture Food Tech",
      "Agriculture Technology",
      "Food Tech",
      "Food Supply Chain",
      "Food and Ag",
      "Food and Beverage",
      "Food Beverage",
    ],
    subcategories: {
      "farm-management-software": {
        name: "Farm Management Software",
        synonyms: [
          "Agricultural Management",
          "Farm Operations Tech",
          "Farm ERP",
          "Farming Software",
          "Agriculture Management",
        ],
      },
      "precision-agriculture": {
        name: "Precision Agriculture",
        synonyms: [
          "Smart Farming",
          "Data-Driven Farming",
          "Precision Farming",
          "Precision Ag",
          "Digital Farming",
        ],
      },
      "agricultural-biotechnology": {
        name: "Agricultural Biotechnology",
        synonyms: [
          "AgBiotech",
          "Crop Science",
          "Plant Technology",
          "Ag Biotech",
          "Farm Biotechnology",
        ],
      },
      "indoor-farming": {
        name: "Indoor Farming",
        synonyms: [
          "Vertical Farming",
          "Controlled Environment Agriculture",
          "Urban Farming",
          "Indoor Agriculture",
          "CEA",
        ],
      },
      "food-supply-chain": {
        name: "Food Supply Chain",
        synonyms: [
          "Ag Supply Chain",
          "Food Distribution Tech",
          "Farm-to-Table Technology",
          "Agricultural Logistics",
          "Food Systems",
          "Sustainable Food Agriculture",
          "Sustainable Food Solutions",
        ],
      },
      "agricultural-robotics": {
        name: "Agricultural Robotics",
        synonyms: [
          "Farm Robotics",
          "Agricultural Automation",
          "Farm Automation",
          "Ag Robotics",
          "Autonomous Farming",
        ],
      },
      "livestock-technology": {
        name: "Livestock Technology",
        synonyms: [
          "Animal Agriculture Tech",
          "Livestock Management",
          "Animal Tech",
          "Precision Livestock",
          "Livestock Innovation",
        ],
      },
      "sustainable-agriculture": {
        name: "Sustainable Agriculture",
        synonyms: [
          "Regenerative Agriculture",
          "Eco-Agriculture",
          "Green Farming",
          "Sustainable Farming",
          "Environmental Agriculture",
          "Agriculture Innovation",
        ],
      },
      "food-delivery-platforms": {
        name: "Food Delivery Platforms",
        synonyms: [
          "Meal Delivery Tech",
          "Food Logistics",
          "Online Food Ordering",
          "Food On-demand",
          "Meal Delivery Apps",
        ],
      },
      "restaurant-tech": {
        name: "Restaurant Tech",
        synonyms: [
          "Restaurant Management Software",
          "Restaurant POS",
          "Dining Technology",
          "Food Service Software",
          "Restaurant Operations",
        ],
      },
      "ghost-kitchens": {
        name: "Ghost Kitchens",
        synonyms: [
          "Cloud Kitchens",
          "Virtual Kitchens",
          "Dark Kitchens",
          "Delivery-only Restaurants",
          "Digital Restaurants",
        ],
      },
      "alternative-proteins": {
        name: "Alternative Proteins",
        synonyms: [
          "Plant-based Tech",
          "Clean Meat",
          "Protein Alternatives",
          "Future Foods",
          "Sustainable Protein",
          "Cultured Meat",
        ],
      },
      "food-science": {
        name: "Food Science",
        synonyms: [
          "Food Innovation",
          "Culinary Science",
          "Food Engineering",
          "Food Research",
          "Food Development",
          "Food AI",
        ],
      },
      "personalized-nutrition": {
        name: "Personalized Nutrition",
        synonyms: [
          "Custom Nutrition",
          "Nutrition Tech",
          "Diet Technology",
          "Precision Nutrition",
          "Nutritional Intelligence",
        ],
      },
      "cannabis-tech": {
        name: "Cannabis Technology",
        synonyms: [
          "Cannabis Tech",
          "Marijuana Technology",
          "Cannabis Innovation",
          "Hemp Technology",
          "Cannabis Software",
          "Cannabis Solutions",
        ],
      },
    },
  },
  "climate-sustainability": {
    name: "Climate & Sustainability",
    synonyms: [
      "ClimateTech",
      "CleanTech",
      "Green Technology",
      "Environmental Technology",
      "Sustainable Technology",
      "Eco-Innovation",
      "Green Innovation",
      "Environmental Solutions",
      "Climate",
      "Climate Action",
      "Climate Tech",
      "Sustainability",
      "Clean Tech",
      "Sustainable Brands",
    ],
    subcategories: {
      "clean-energy": {
        name: "Clean Energy",
        synonyms: [
          "Renewable Energy",
          "Alternative Energy",
          "Green Energy",
          "Sustainable Power",
          "Clean Power",
        ],
      },
      "carbon-management": {
        name: "Carbon Management",
        synonyms: [
          "Carbon Tech",
          "Emissions Technology",
          "Carbon Reduction Tech",
          "Carbon Solutions",
          "Emissions Management",
          "Carbon Removal Technology",
        ],
      },
      "sustainable-materials": {
        name: "Sustainable Materials",
        synonyms: [
          "Green Materials",
          "Eco-Materials",
          "Sustainable Resources",
          "Alternative Materials",
          "Environmental Materials",
          "Material Science",
          "Materials",
        ],
      },
      "climate-analytics": {
        name: "Climate Analytics",
        synonyms: [
          "Environmental Data",
          "Climate Intelligence",
          "Climate Modeling",
          "Environmental Analytics",
          "Climate Insights",
        ],
      },
      "environmental-monitoring": {
        name: "Environmental Monitoring",
        synonyms: [
          "Eco Monitoring",
          "Environmental Sensing",
          "Climate Monitoring",
          "Environmental Tracking",
          "Ecological Surveillance",
        ],
      },
      "circular-economy": {
        name: "Circular Economy",
        synonyms: [
          "Resource Efficiency",
          "Zero Waste",
          "Materials Recovery",
          "Circular Solutions",
          "Regenerative Economy",
          "Sustainable Production Consumption",
        ],
      },
      "green-building-tech": {
        name: "Green Building Tech",
        synonyms: [
          "Sustainable Construction",
          "Eco Buildings",
          "Green Construction",
          "Sustainable Architecture",
          "Environmental Building",
          "Buildings",
        ],
      },
      "water-technology": {
        name: "Water Technology",
        synonyms: [
          "Water Tech",
          "Hydro Technology",
          "Water Management",
          "Water Solutions",
          "Water Innovation",
          "Water",
        ],
      },
      "climate-fintech": {
        name: "Climate Fintech",
        synonyms: [
          "Carbon Finance",
          "Green Fintech",
          "Climate Finance Technology",
          "Sustainable Finance Tech",
          "Environmental Finance",
        ],
      },
      "sustainable-supply-chains": {
        name: "Sustainable Supply Chains",
        synonyms: [
          "Green Supply Chain",
          "Eco-friendly Logistics",
          "Sustainable Sourcing",
          "Responsible Supply Chain",
          "Low-carbon Supply Chain",
          "Sustainable Supply Chains",
        ],
      },
      "esg-tech": {
        name: "ESG Technology",
        synonyms: [
          "Environmental Social Governance Tech",
          "Corporate Sustainability Tech",
          "ESG Analytics",
          "ESG Reporting",
          "Impact Measurement",
          "ESG",
        ],
      },
    },
  },
  "energy-resources": {
    name: "Energy & Resources",
    synonyms: [
      "Energy Technology",
      "Resource Technology",
      "Energy Innovation",
      "Energy Systems",
      "Resource Management",
      "Power Technology",
      "Energy",
      "Energy Tech",
      "Energy Technology",
      "Energy Transition",
      "Energytech",
    ],
    subcategories: {
      "renewable-energy": {
        name: "Renewable Energy",
        synonyms: [
          "Clean Energy",
          "Sustainable Energy",
          "Green Power",
          "Renewable Power",
          "Alternative Energy",
          "Renewable Energy",
        ],
      },
      "energy-efficiency": {
        name: "Energy Efficiency",
        synonyms: [
          "Energy Conservation",
          "Energy Optimization",
          "Energy Management",
          "Efficient Energy Use",
          "Energy Saving Technology",
          "Energy Efficiency",
        ],
      },
      "energy-storage": {
        name: "Energy Storage",
        synonyms: [
          "Power Storage",
          "Battery Technology",
          "Grid Storage",
          "Storage Solutions",
          "Electrical Storage",
          "Energy Storage",
          "Hydrogen Storage",
        ],
      },
      "power-grid-tech": {
        name: "Power Grid & Utilities",
        synonyms: [
          "Smart Grid",
          "Grid Management",
          "Utility Technology",
          "Grid Innovation",
          "Power Distribution",
        ],
      },
      "oil-gas": {
        name: "Oil & Gas Technology",
        synonyms: [
          "Petroleum Technology",
          "Fossil Fuel Innovation",
          "Hydrocarbon Technology",
          "O&G Technology",
          "Traditional Energy Tech",
        ],
      },
      "energy-monitoring": {
        name: "Energy Monitoring & Management",
        synonyms: [
          "Energy Analytics",
          "Power Monitoring",
          "Energy Management Systems",
          "Energy Control",
          "Power Management",
        ],
      },
      "green-industrials": {
        name: "Green Industrial Processes",
        synonyms: [
          "Sustainable Manufacturing",
          "Green Production",
          "Industrial Decarbonization",
          "Clean Industry",
          "Low-carbon Industry",
          "Green Industrials",
        ],
      },
      "resource-management": {
        name: "Resource Management",
        synonyms: [
          "Natural Resource Tech",
          "Resource Optimization",
          "Resource Conservation",
          "Sustainable Resources",
          "Resource Technology",
        ],
      },
    },
  },
  "transportation-mobility": {
    name: "Transportation & Mobility",
    synonyms: [
      "Mobility Technology",
      "Transportation Tech",
      "Smart Transport",
      "Urban Mobility",
      "Connected Transport",
      "Transit Technology",
      "Mobility Innovation",
      "Transport Systems",
      "Mobility Tech",
      "Transportation Technology",
      "Auto Tech",
      "Automotive Technology",
    ],
    subcategories: {
      logistics: {
        name: "Logistics",
        synonyms: [
          "Supply Chain Technology",
          "Shipping Technology",
          "Freight Tech",
          "Distribution Technology",
          "Logistics Management",
          "3PL-Fulfillment-Centres",
        ],
      },
      "last-mile-delivery": {
        name: "Last-mile Delivery",
        synonyms: [
          "Final Delivery",
          "Urban Delivery",
          "Local Delivery Technology",
          "Last-mile Logistics",
          "Delivery Innovation",
        ],
      },
      "fleet-management": {
        name: "Fleet Management",
        synonyms: [
          "Vehicle Management",
          "Transportation Management",
          "Fleet Operations",
          "Vehicle Tracking",
          "Fleet Technology",
        ],
      },
      "autonomous-vehicles": {
        name: "Autonomous Vehicles",
        synonyms: [
          "Self-driving Technology",
          "Driverless Vehicles",
          "Autonomous Transport",
          "Autonomous Driving",
          "Self-driving Cars",
        ],
      },
      micromobility: {
        name: "Micromobility",
        synonyms: [
          "Shared Mobility",
          "Urban Transport",
          "Personal Mobility",
          "Light Mobility",
          "Micro-Transportation",
        ],
      },
      "electric-vehicles": {
        name: "Electric Vehicles",
        synonyms: [
          "EVs",
          "Electrification",
          "Electric Transport",
          "Battery Vehicles",
          "Zero Emission Vehicles",
        ],
      },
      "transportation-analytics": {
        name: "Transportation Analytics",
        synonyms: [
          "Mobility Data",
          "Transport Intelligence",
          "Traffic Analytics",
          "Transit Data",
          "Mobility Insights",
        ],
      },
      ridesharing: {
        name: "Ridesharing",
        synonyms: [
          "Ride-hailing",
          "Shared Rides",
          "On-demand Transportation",
          "Ride Services",
          "Carpooling Platforms",
        ],
      },
      carsharing: {
        name: "Carsharing",
        synonyms: [
          "Car-as-a-service",
          "Vehicle Sharing",
          "P2P Car Rental",
          "Car Clubs",
          "Hourly Car Rental",
        ],
      },
      "digital-value-chains": {
        name: "Digital Value Chains",
        synonyms: [
          "Connected Supply Chains",
          "Integrated Logistics",
          "Supply Network Platforms",
          "Value Web Technology",
          "Digital Value Chains",
        ],
      },
    },
  },
  "hardware-iot": {
    name: "Hardware & IoT",
    synonyms: [
      "Connected Devices",
      "Physical Computing",
      "Smart Devices",
      "Hardware Technology",
      "Internet of Things",
      "IoT",
      "Connected Hardware",
      "Embedded Systems",
      "Hardware",
    ],
    subcategories: {
      "internet-of-things": {
        name: "Internet of Things",
        synonyms: [
          "IoT",
          "Connected Devices",
          "Smart Devices",
          "Networked Objects",
          "Smart Objects",
          "Connected Products",
        ],
      },
      "consumer-hardware": {
        name: "Consumer Hardware",
        synonyms: [
          "Consumer Devices",
          "Consumer Electronics",
          "Personal Devices",
          "Retail Hardware",
          "Consumer Gadgets",
        ],
      },
      "enterprise-hardware": {
        name: "Enterprise Hardware",
        synonyms: [
          "Business Hardware",
          "Commercial Devices",
          "Industrial Hardware",
          "Corporate Hardware",
          "B2B Hardware",
        ],
      },
      "robotics-drones": {
        name: "Robotics & Drones",
        synonyms: [
          "Automation Hardware",
          "Robotic Systems",
          "Autonomous Machines",
          "UAVs",
          "Unmanned Systems",
          "Aerial Robotics",
        ],
      },
      "sensors-data-collection": {
        name: "Sensors & Data Collection",
        synonyms: [
          "Sensing Technology",
          "Sensor Networks",
          "Data Acquisition",
          "Monitoring Hardware",
          "Measurement Systems",
        ],
      },
      "wearable-technology": {
        name: "Wearable Technology",
        synonyms: [
          "Wearables",
          "Body Computing",
          "Wearable Devices",
          "Body Tech",
          "Worn Technology",
        ],
      },
      "smart-home": {
        name: "Smart Home",
        synonyms: [
          "Home Automation",
          "Connected Home",
          "Home IoT",
          "Intelligent Home",
          "Home Control Systems",
        ],
      },
      "industrial-iot": {
        name: "Industrial IoT",
        synonyms: [
          "IIoT",
          "Industrial Internet",
          "Connected Industry",
          "Smart Factory",
          "Industrial Connectivity",
        ],
      },
      "3d-printing": {
        name: "3D Printing",
        synonyms: [
          "Additive Manufacturing",
          "Rapid Prototyping",
          "3D Manufacturing",
          "Digital Fabrication",
          "3D Production",
        ],
      },
      microelectronics: {
        name: "Microelectronics",
        synonyms: [
          "Semiconductor Technology",
          "Chip Technology",
          "Integrated Circuits",
          "Electronic Components",
          "Micro Systems",
        ],
      },
      "hard-tech": {
        name: "Hard Tech",
        synonyms: [
          "Deep Tech Hardware",
          "Advanced Hardware",
          "Frontier Hardware",
          "Complex Hardware Systems",
          "Hardware Innovation",
        ],
      },
    },
  },
  "ar-vr": {
    name: "AR, VR & Spatial Computing",
    synonyms: [
      "AR/VR",
      "Extended Reality",
      "XR",
      "Immersive Technology",
      "Spatial Computing",
      "Immersive Media",
      "Virtual Environments",
      "Augmented Computing",
    ],
    subcategories: {
      "ar-vr-hardware": {
        name: "AR/VR Hardware",
        synonyms: [
          "Headsets",
          "Immersive Devices",
          "XR Hardware",
          "AR Glasses",
          "VR Headsets",
          "Immersive Computing",
        ],
      },
      "ar-vr-content": {
        name: "AR/VR Content",
        synonyms: [
          "Immersive Content",
          "XR Experiences",
          "VR Experiences",
          "AR Applications",
          "Immersive Media",
        ],
      },
      metaverse: {
        name: "Metaverse",
        synonyms: [
          "Virtual Worlds",
          "Digital Realms",
          "Persistent Virtual Spaces",
          "Shared Virtual Environments",
          "Immersive Internet",
        ],
      },
      "spatial-computing": {
        name: "Spatial Computing",
        synonyms: [
          "Environmental Computing",
          "Spatial Technology",
          "Space-aware Computing",
          "Spatial Tech",
          "Real-world Computing",
        ],
      },
      "enterprise-ar-vr": {
        name: "Enterprise AR/VR",
        synonyms: [
          "Business XR",
          "Industrial AR/VR",
          "Corporate Immersive",
          "Professional XR",
          "B2B Immersive Technology",
        ],
      },
      "gaming-ar-vr": {
        name: "Gaming AR/VR",
        synonyms: [
          "Immersive Gaming",
          "VR Games",
          "AR Gaming",
          "XR Entertainment",
          "Immersive Entertainment",
        ],
      },
      "mixed-reality": {
        name: "Mixed Reality",
        synonyms: ["MR", "Hybrid Reality", "Merged Reality", "Mixed Computing", "Blended Reality"],
      },
      "vr-training": {
        name: "VR Training & Simulation",
        synonyms: [
          "Immersive Learning",
          "Virtual Training",
          "Simulation Training",
          "XR Education",
          "Virtual Skill Development",
        ],
      },
      "therapeutic-vr": {
        name: "Therapeutic VR",
        synonyms: [
          "Medical VR",
          "Virtual Therapy",
          "VR Health",
          "Immersive Medicine",
          "VR Therapeutics",
        ],
      },
    },
  },
  "education-technology": {
    name: "Education Technology",
    synonyms: [
      "EdTech",
      "Educational Technology",
      "Learning Technology",
      "Digital Learning",
      "E-learning",
      "Online Education",
      "Learning Platforms",
      "Education Innovation",
      "Education",
    ],
    subcategories: {
      "learning-management-systems": {
        name: "Learning Management Systems",
        synonyms: [
          "LMS",
          "Course Management Software",
          "Educational Platforms",
          "Learning Platforms",
          "Education Management Systems",
        ],
      },
      "online-learning": {
        name: "Online Learning",
        synonyms: [
          "E-learning",
          "Distance Education",
          "Remote Learning",
          "Virtual Learning",
          "Digital Education",
        ],
      },
      "corporate-training": {
        name: "Corporate Training",
        synonyms: [
          "Workplace Learning",
          "Professional Development",
          "Enterprise Learning",
          "Business Education",
          "Workforce Training",
        ],
      },
      "higher-education-tech": {
        name: "Higher Education Tech",
        synonyms: [
          "University Technology",
          "College Technology",
          "Academic Technology",
          "Campus Tech",
          "Higher-Ed Innovation",
          "College",
          "MBA",
        ],
      },
      "k12-education-tech": {
        name: "K-12 Education Tech",
        synonyms: [
          "School Technology",
          "Primary Education Tech",
          "Secondary Education Tech",
          "Classroom Technology",
          "School Innovation",
        ],
      },
      "language-learning": {
        name: "Language Learning",
        synonyms: [
          "Language Education",
          "Language Acquisition Tech",
          "Language Training",
          "Linguistic Learning",
          "Foreign Language Tech",
        ],
      },
      "skills-assessment": {
        name: "Skills Assessment",
        synonyms: [
          "Competency Measurement",
          "Skills Evaluation",
          "Learning Assessment",
          "Performance Testing",
          "Knowledge Verification",
        ],
      },
      "educational-content": {
        name: "Educational Content",
        synonyms: [
          "Learning Materials",
          "Educational Resources",
          "Learning Content",
          "Educational Media",
          "Digital Curriculum",
        ],
      },
      "education-analytics": {
        name: "Education Analytics",
        synonyms: [
          "Learning Analytics",
          "Educational Data",
          "Student Performance Tracking",
          "Learning Insights",
          "Educational Intelligence",
        ],
      },
      "career-education": {
        name: "Career Education & Development",
        synonyms: [
          "Professional Education",
          "Career Training",
          "Vocational Technology",
          "Career Advancement",
          "Professional Skills",
        ],
      },
      "plagiarism-detection": {
        name: "Academic Integrity Tools",
        synonyms: [
          "Plagiarism Checkers",
          "Academic Honesty Tools",
          "Content Originality",
          "Attribution Technology",
          "Plagiarism Checker",
        ],
      },
    },
  },
  "real-estate-construction": {
    name: "Real Estate & Construction Technology",
    synonyms: [
      "PropTech",
      "Property Technology",
      "Real Estate Technology",
      "Built Environment Technology",
      "RealTech",
      "Building Technology",
      "Real Estate Innovation",
      "Construction Technology",
      "ConTech",
      "ConstructionTech",
      "Construction Tech",
      "Construction Digitization",
      "Construction Software",
      "Proptech",
    ],
    subcategories: {
      "real-estate-marketplaces": {
        name: "Real Estate Marketplaces",
        synonyms: [
          "Property Platforms",
          "Real Estate Listings",
          "Housing Marketplaces",
          "Property Marketplaces",
          "Home Listing Platforms",
        ],
      },
      "property-management-software": {
        name: "Property Management Software",
        synonyms: [
          "Real Estate Management",
          "Landlord Software",
          "Property Operations",
          "Asset Management Software",
          "Property Admin",
        ],
      },
      "smart-buildings": {
        name: "Smart Buildings & IoT",
        synonyms: [
          "Connected Buildings",
          "Intelligent Buildings",
          "Building Automation",
          "Smart Properties",
          "Building IoT",
        ],
      },
      "mortgage-financing-tech": {
        name: "Mortgage & Financing Tech",
        synonyms: [
          "Property Financing",
          "Real Estate Financial Technology",
          "Mortgage Technology",
          "Property Lending",
          "Real Estate Finance",
        ],
      },
      "commercial-real-estate-tech": {
        name: "Commercial Real Estate Tech",
        synonyms: [
          "CRE Tech",
          "Office Property Tech",
          "Commercial Property Technology",
          "Business Real Estate Tech",
        ],
      },
      "residential-real-estate-tech": {
        name: "Residential Real Estate Tech",
        synonyms: [
          "Residential PropTech",
          "Housing Technology",
          "Home Tech",
          "Consumer Real Estate Technology",
        ],
      },
      "real-estate-analytics": {
        name: "Real Estate Analytics",
        synonyms: [
          "Property Analytics",
          "Real Estate Data",
          "Property Intelligence",
          "Real Estate Insights",
          "Property Data Science",
        ],
      },
      "construction-management-software": {
        name: "Construction Management Software",
        synonyms: [
          "Project Management for Construction",
          "Construction ERP",
          "Construction Project Tools",
          "Building Management Software",
        ],
      },
      "building-information-modeling": {
        name: "Building Information Modeling",
        synonyms: [
          "BIM",
          "Digital Construction",
          "3D Building Design",
          "Digital Building Models",
          "Construction Modeling",
        ],
      },
      "construction-analytics": {
        name: "Construction Analytics",
        synonyms: [
          "Building Data",
          "Construction Intelligence",
          "Project Analytics",
          "Construction Monitoring",
          "Construction Insights",
        ],
      },
      "modular-construction": {
        name: "Modular Construction",
        synonyms: [
          "Prefabrication Tech",
          "Off-site Construction",
          "Modular Building",
          "Factory-Built Construction",
          "Prefab Technology",
        ],
      },
      "construction-robotics": {
        name: "Construction Robotics",
        synonyms: [
          "Building Automation",
          "Construction Automation",
          "Robotic Construction",
          "Autonomous Construction",
          "Building Robotics",
        ],
      },
      "construction-marketplaces": {
        name: "Construction Marketplaces",
        synonyms: [
          "Building Supply Platforms",
          "Construction Labor Platforms",
          "Equipment Marketplaces",
          "Material Marketplaces",
        ],
      },
      "construction-iot": {
        name: "Construction IoT",
        synonyms: [
          "Connected Construction",
          "Site Monitoring",
          "Smart Construction",
          "Connected Jobsites",
          "Construction Sensors",
        ],
      },
    },
  },
  "manufacturing-industrial": {
    name: "Manufacturing & Industrial Technology",
    synonyms: [
      "Smart Manufacturing",
      "Digital Manufacturing",
      "Manufacturing Technology",
      "Industry 4.0",
      "Factory Technology",
      "Modern Manufacturing",
      "Manufacturing Innovation",
      "Intelligent Manufacturing",
      "Industrial Technology",
      "Manufacturing",
    ],
    subcategories: {
      "industry-4-0": {
        name: "Industry 4.0",
        synonyms: [
          "Fourth Industrial Revolution",
          "Smart Industry",
          "Industrial IoT",
          "Connected Industry",
          "Industrial Digitization",
        ],
      },
      "smart-factories": {
        name: "Smart Factories",
        synonyms: [
          "Intelligent Factories",
          "Connected Manufacturing",
          "Digital Factories",
          "Factory of the Future",
          "Smart Production",
        ],
      },
      "digital-twins": {
        name: "Digital Twins",
        synonyms: [
          "Virtual Factory",
          "Manufacturing Simulation",
          "Process Digital Twin",
          "Virtual Replica",
          "Digital Modeling",
          "Digital Twins Cities",
        ],
      },
      "manufacturing-analytics": {
        name: "Manufacturing Analytics",
        synonyms: [
          "Production Analytics",
          "Factory Intelligence",
          "Manufacturing Insights",
          "Production Monitoring",
          "Factory Data",
        ],
      },
      "additive-manufacturing": {
        name: "Additive Manufacturing",
        synonyms: [
          "Industrial 3D Printing",
          "Direct Digital Manufacturing",
          "3D Production",
          "Additive Fabrication",
          "3D Manufacturing",
        ],
      },
      "industrial-automation": {
        name: "Industrial Automation",
        synonyms: [
          "Factory Automation",
          "Manufacturing Robotics",
          "Automated Production",
          "Process Automation",
          "Manufacturing Control",
          "Industrial Technology",
        ],
      },
      "manufacturing-optimization": {
        name: "Manufacturing Optimization",
        synonyms: [
          "Production Efficiency",
          "Lean Manufacturing",
          "Process Optimization",
          "Manufacturing Improvement",
          "Factory Optimization",
        ],
      },
      "quality-control-tech": {
        name: "Quality Control Tech",
        synonyms: [
          "Manufacturing Quality",
          "Quality Assurance Technology",
          "Inspection Tech",
          "Defect Detection",
          "Quality Systems",
        ],
      },
      "industrial-robotics": {
        name: "Industrial Robotics",
        synonyms: [
          "Manufacturing Robots",
          "Factory Robotics",
          "Robotic Production",
          "Automation Systems",
          "Industrial Cobots",
        ],
      },
      "advanced-materials": {
        name: "Advanced Materials",
        synonyms: [
          "New Materials",
          "Engineered Materials",
          "Material Science",
          "Industrial Materials",
          "Next-gen Materials",
        ],
      },
      "industrial-design": {
        name: "Industrial Design & Engineering",
        synonyms: [
          "Product Engineering",
          "Manufacturing Design",
          "Design for Manufacturing",
          "Engineering Design",
          "Product Development",
        ],
      },
    },
  },
  "supply-chain-technology": {
    name: "Supply Chain Technology",
    synonyms: [
      "Supply Chain Tech",
      "Logistics Technology",
      "Supply Network Technology",
      "Distribution Technology",
      "Supply Management Tech",
      "Digital Supply Chain",
      "Supply Innovation",
      "Chain Systems Technology",
      "Supply Chain",
      "Supplychain",
      "Supply Chain Tech",
    ],
    subcategories: {
      "supply-chain-visibility": {
        name: "Supply Chain Visibility",
        synonyms: [
          "Transparency Solutions",
          "Supply Tracking",
          "Chain Monitoring",
          "Visibility Platforms",
          "Supply Transparency",
        ],
      },
      "inventory-management": {
        name: "Inventory Management",
        synonyms: [
          "Stock Technology",
          "Inventory Control",
          "Warehouse Management",
          "Inventory Optimization",
          "Stock Control",
        ],
      },
      "procurement-technology": {
        name: "Procurement Technology",
        synonyms: [
          "Purchasing Software",
          "Sourcing Technology",
          "Supplier Management",
          "Procurement Platforms",
          "Buying Technology",
        ],
      },
      "warehouse-automation": {
        name: "Warehouse Automation",
        synonyms: [
          "Automated Warehousing",
          "Robotic Fulfillment",
          "Smart Warehousing",
          "Warehouse Robotics",
          "Storage Automation",
        ],
      },
      "logistics-optimization": {
        name: "Logistics Optimization",
        synonyms: [
          "Transport Optimization",
          "Shipping Efficiency",
          "Route Optimization",
          "Logistics Management",
          "Freight Optimization",
        ],
      },
      "supply-chain-analytics": {
        name: "Supply Chain Analytics",
        synonyms: [
          "Logistics Analytics",
          "Chain Intelligence",
          "Supply Data",
          "Distribution Analytics",
          "Network Insights",
        ],
      },
      "digital-supply-networks": {
        name: "Digital Supply Networks",
        synonyms: [
          "Connected Supply Chain",
          "Network Platforms",
          "Digital Supply Ecosystems",
          "Supply Chain Platforms",
          "Network Management",
        ],
      },
      "supply-chain-resilience": {
        name: "Supply Chain Resilience",
        synonyms: [
          "Risk Management",
          "Chain Continuity",
          "Supply Security",
          "Disruption Management",
          "Supply Reliability",
        ],
      },
      "business-services": {
        name: "Supply Chain Business Services",
        synonyms: [
          "Supply Chain Consulting",
          "Logistics Services",
          "Supply Chain Management",
          "Operations Services",
          "Business Services",
        ],
      },
      "shipping-solutions": {
        name: "Shipping & Freight Technology",
        synonyms: [
          "Freight Tech",
          "Cargo Technology",
          "Shipping Platforms",
          "Transport Solutions",
          "Carrier Management",
        ],
      },
    },
  },
  "space-aerospace": {
    name: "Space & Aerospace Technology",
    synonyms: [
      "SpaceTech",
      "Space Industry",
      "Aerospace Technology",
      "NewSpace",
      "Commercial Space",
      "Space Innovation",
      "Orbital Technology",
      "Space Systems",
      "Space",
      "Space Industry",
      "Aerospace",
    ],
    subcategories: {
      "satellite-systems": {
        name: "Satellite Systems",
        synonyms: [
          "Satellite Technology",
          "Orbital Platforms",
          "Satellite Services",
          "Space Hardware",
          "Satellite Innovation",
        ],
      },
      "launch-services": {
        name: "Launch Services",
        synonyms: [
          "Rocket Technology",
          "Space Transportation",
          "Orbital Launch",
          "Space Access",
          "Launch Systems",
        ],
      },
      "space-data-analytics": {
        name: "Space Data Analytics",
        synonyms: [
          "Satellite Data",
          "Earth Observation Analytics",
          "Space Intelligence",
          "Orbital Data",
          "Space Insights",
        ],
      },
      "space-manufacturing": {
        name: "Space Manufacturing",
        synonyms: [
          "Orbital Manufacturing",
          "In-space Production",
          "Space-based Manufacturing",
          "Microgravity Manufacturing",
        ],
      },
      "space-tourism": {
        name: "Space Tourism",
        synonyms: [
          "Commercial Spaceflight",
          "Space Travel",
          "Orbital Tourism",
          "Civilian Spaceflight",
          "Space Experiences",
        ],
      },
      "aerospace-technology": {
        name: "Aerospace Technology",
        synonyms: [
          "Space Systems",
          "Aerospace Engineering",
          "Aviation Tech",
          "Aeronautics",
          "Aerospace Innovation",
        ],
      },
      "space-infrastructure": {
        name: "Space Infrastructure",
        synonyms: [
          "Orbital Infrastructure",
          "Space Stations",
          "Space Facilities",
          "Space Habitats",
          "Space Logistics",
        ],
      },
      "earth-observation": {
        name: "Earth Observation",
        synonyms: [
          "Remote Sensing",
          "Satellite Imagery",
          "Earth Monitoring",
          "Planetary Surveillance",
          "Space-based Monitoring",
        ],
      },
      "space-communications": {
        name: "Space Communications",
        synonyms: [
          "Satellite Communications",
          "Space Networks",
          "Orbital Communication Systems",
          "Space-based Telecommunications",
          "Interplanetary Communications",
        ],
      },
      "quantum-space-technologies": {
        name: "Quantum Space Technologies",
        synonyms: [
          "Space Quantum Systems",
          "Quantum Satellites",
          "Quantum Communications in Space",
          "Orbital Quantum Tech",
          "Quantum Computing",
        ],
      },
    },
  },
  "emerging-technologies": {
    name: "Emerging & Frontier Technologies",
    synonyms: [
      "Emerging Technologies",
      "Frontier Tech",
      "Next-gen Innovation",
      "Future Technologies",
      "Nascent Markets",
      "Emerging Sectors",
      "Future Verticals",
      "Innovation Frontiers",
      "Deep Tech",
      "Deeptech",
      "Emerging Spaces",
      "Tech-enabled Startups",
    ],
    subcategories: {
      "quantum-technologies": {
        name: "Quantum Technologies",
        synonyms: [
          "Quantum Computing",
          "Quantum Sensing",
          "Quantum Cryptography",
          "Quantum Information",
          "Quantum Systems",
        ],
      },
      "psychedelic-medicine": {
        name: "Psychedelic Medicine",
        synonyms: [
          "Psychedelic Therapy",
          "Therapeutic Psychedelics",
          "Psychedelic Biotech",
          "Psychedelic Treatment",
          "Mental Health Compounds",
        ],
      },
      "voice-ai": {
        name: "Voice AI",
        synonyms: [
          "Voice Assistants",
          "Voice Technology",
          "Speech AI",
          "Conversational AI",
          "Voice Computing",
        ],
      },
      "computational-biology": {
        name: "Computational Biology",
        synonyms: [
          "Digital Biology",
          "Biological Computing",
          "In-silico Biology",
          "Computational Life Science",
          "Biocomputation",
        ],
      },
      "brain-computer-interfaces": {
        name: "Brain-Computer Interfaces",
        synonyms: [
          "BCI",
          "Neural Interfaces",
          "Mind-Machine Interfaces",
          "Neurotech",
          "Neural Technology",
        ],
      },
      "materials-science": {
        name: "Advanced Materials Science",
        synonyms: [
          "Smart Materials",
          "Programmable Materials",
          "Meta-materials",
          "Material Innovation",
          "Next-gen Materials",
        ],
      },
      "semiconductor-innovation": {
        name: "Semiconductor Innovation",
        synonyms: [
          "Next-gen Chips",
          "Advanced Processors",
          "Semiconductor Advancements",
          "Chip Technology",
          "Computing Hardware Innovation",
        ],
      },
      "sustainable-chemistry": {
        name: "Sustainable Chemistry",
        synonyms: [
          "Green Chemistry",
          "Circular Chemistry",
          "Bio-based Chemicals",
          "Sustainable Materials Processing",
          "Clean Chemical Processes",
        ],
      },
      "next-gen-networking": {
        name: "Next-Gen Networking",
        synonyms: [
          "6G",
          "Advanced Connectivity",
          "Future Communications",
          "Distributed Networks",
          "Next-generation Internet",
        ],
      },
      "human-augmentation": {
        name: "Human Augmentation",
        synonyms: [
          "Human Enhancement",
          "Augmented Capabilities",
          "Human-Machine Fusion",
          "Cognitive Enhancement",
          "Physical Augmentation",
        ],
      },
    },
  },
} as const satisfies RawSectorConfig;

export default sectorConfig;
