import axios, { AxiosInstance, CreateAxiosDefaults } from "axios";

import type { StreamListener } from "@/client/apiTypes";
import type { HatchetWorkflow, HatchetWorkflowInputs } from "@/hatchet/hatchetTypes";
import { logger } from "@/lib/logger";
import {
  AdvancedSearchRequest,
  AdvancedSearchType,
  AIModelKey,
  AssessmentContextResponse,
  AssessmentQAs,
  AutocompleteEntity,
  ChromeCookieUpdate,
  CompanyCardData,
  CompanyCheckResponse,
  ConnectionAnalysisResponse,
  ConnectionsAnalysesRequest,
  Cookie,
  CookieList,
  CrawlResultSansBody,
  DogfoodFeedback,
  EmailEntryPoint,
  EmailSearchResult,
  Entity,
  EntityType,
  EntityWithAttributes,
  ExtractedEntity,
  FilterQuery,
  FullDetectorOutput,
  GenericProfile,
  GraphScoreOutput,
  HighlightsFeedback,
  HighlightWithSources,
  ImageDetails,
  InterruptiblePromise,
  LinkedinSearchResults,
  LinkWithDescription,
  Meeting,
  MeetingResponse,
  MutualConnectionsResponse,
  OpenAIChatMessage,
  PartialEntity,
  PersonCardData,
  PrivateAttributeMutualConnection,
  ProfileFeedback,
  ProfileProblemCore,
  SavedSearchCreate,
  SearchEntityType,
  SearchExplainRequest,
  SearchQueryAPIResponse,
  SearchQueryAPIResult,
  SearchRanking,
  SearchRerankAPIResponse,
  SmartSearchResponse,
  SocialPosts,
  SocialServices,
  SuccessResponse,
  User,
  UserMeta,
  ValueOf,
  WorkplaceResolveRequest,
  WorkplaceResolveResponse,
} from "@/types";
import {
  AssessmentContext,
  Conversation,
  EntityFilter,
  EntitySearchQuery,
  EntitySource,
  EntitySourceUpdate,
  ExternalProfile,
  Fact,
  FeedbackCategory,
  Invite,
  Message,
  OAuthToken,
  ProfileProblem,
  Relationship,
  SavedSearch,
  UserProfile,
  WaitList,
} from "@prisma/client";

import {
  ListEntriesResource,
  ListInvitesResource,
  ListsResource,
  ListUsersResource,
  ListViewsResource,
} from "@/client/lists";
import {
  deleteRoute,
  getRoute,
  postRoute,
  putRoute,
  Resource,
  ResourceWithParent,
  SingleResource,
} from "@/client/resource";
import errorTracker from "@/lib/errorTracker";
import type { RankedEntityResult } from "@/models/entity/entitySearch";
import { BlockedSourceResponse } from "@/pages/api/admin/blockedSources";
import { SourceNoteResponse } from "@/pages/api/admin/sourceNotes";
import {
  ResolveEmailIdentityRequest,
  ResolveEmailIdentityResponse,
} from "@/pages/api/emails/resolveEmailIdentities";
import { ConnectionPath, ConnectionScore, RelationshipWithEntity } from "@/types";
import type { EvalResult, EvalRun } from "@/types/evals/eval";
import { EvalTestcase } from "@/types/evals/eval.typebox";
import { AuthoredMediaResponse } from "@/utils/scrapeSelectedAuthoredMedia";
import { SummarizedResult } from "@/utils/summarizeSelectedSources";

type EmailResolveRequest = {
  email: string;
  name?: string;
  meetingIds: string[];
};

type EmailResolveResponse = {
  results: {
    email: string;
    meetingIds: string[];
    entity?: EntityWithAttributes;
    resolutions?: EmailSearchResult[];
    error?: string;
  }[];
  errors?: string[];
};

class APIService {
  axios: AxiosInstance;

  endpoint: string;

  constructor() {
    this.endpoint = "/api";
    const config: CreateAxiosDefaults = {
      baseURL: this.endpoint,
    };
    this.axios = axios.create(config);

    // instrument axios calls + api calls on the frontend
    this.axios.interceptors.request.use((config) => {
      errorTracker.addBreadcrumb({
        action: "axios call",
        message: `${config.method?.toUpperCase()} ${config.url}`,
        category: "axios",
        metadata: { params: config.params ? JSON.stringify(config.params) : undefined },
      });
      return config;
    });
    this.axios.interceptors.request.use((config) => {
      errorTracker.addBreadcrumb({
        action: "api call",
        message: `${config.method?.toUpperCase()} ${config.url}`,
        category: "api",
        metadata: { params: config.params ? JSON.stringify(config.params) : undefined },
      });
      return config;
    });
  }

  // --- user management

  user = new SingleResource<User>(this, "users");

  userProfile = new SingleResource<UserProfile>(this, "users/profile");

  updateUserMeta = postRoute<{ meta: Partial<UserMeta> }, UserMeta>(this, `/users/meta`);

  updateCookies = postRoute<
    { cookies: CookieList },
    { meta?: UserMeta; entity?: ExtractedEntity; updatedAuthCookie?: ChromeCookieUpdate }
  >(this, `/users/cookies`);

  // --- entity resources

  sources = new Resource<EntitySource>(this, "sources");

  getDogfoodFeedbacks = getRoute<{ entityId?: string; createdToday?: boolean }, ProfileFeedback[]>(
    this,
    `/dogfoodFeedback`,
  );
  createDogfoodFeedback = postRoute<{
    entityId: string;
    userId: string;
    response?: object;
    feedbackCategoryIds?: string[];
  }>(this, `/dogfoodFeedback`);

  dogfoodFeedbacks = new Resource<DogfoodFeedback>(this, "dogfoodFeedback");
  feedbackCategories = new Resource<FeedbackCategory>(this, "dogfoodFeedback/categories");

  profileProblems = new Resource<
    ProfileProblem & { feedbackId?: string; unlinkFeedbackId?: string }
  >(this, "profileProblems");

  testProfileProblem = postRoute<
    {
      entityIds: string[];
      problem: ProfileProblemCore;
    },
    FullDetectorOutput[]
  >(this, "/profileProblems/test");

  getSources = getRoute<
    {
      entityId: string;
      sourceId?: string;
      userId?: string;
    },
    {
      sources: EntitySource[];
      scraped: CrawlResultSansBody[];
      images: ImageDetails[];
      sourceUpdates: EntitySourceUpdate[];
      scrapedAuthoredMedia: CrawlResultSansBody[];
      externalProfiles: ExternalProfile[];
      mediaCoverageSummary: string;
    }
  >(this, `/sources/load`);

  searchLiForEmployeesByUrn = getRoute<
    { entityId: string; urn: string; page?: number; limit?: number },
    {
      employees: PrivateAttributeMutualConnection[];
      paging: {
        total: number;
        start: number;
        count: number;
      };
    }
  >(this, `/search/employees`);

  getHighlights = getRoute<{ entityId: string }, HighlightWithSources[]>(this, `/highlights`);

  deleteTag = deleteRoute<{ tagId: string; entityId: string }>(this, `/entityTags`);

  deleteHighlights = deleteRoute<{ entityId: string }>(this, `/highlights`);

  updateHighlightsFromFeedback = postRoute<
    { entityId: string; feedback: string },
    { highlights: HighlightWithSources[]; feedback: HighlightsFeedback }
  >(this, `/highlights/feedback`);

  getHighlightsFeedback = getRoute<{ entityId: string }, { feedback: HighlightsFeedback[] }>(
    this,
    `/highlights/feedback`,
  );

  facts = new ResourceWithParent<Entity, Fact>(this, "entityId", "facts");

  getFilter = getRoute<{ entityId: string }, EntityFilter>(this, `/filters`);

  getQuery = getRoute<{ entityId: string }, EntitySearchQuery>(this, `/queries`);

  deleteEntity = deleteRoute<{ entityId: string }, { redirect: string }>(this, `/entities/delete`);

  mergeEntities = postRoute<{ fromEntityId: string; toEntityUrl: string }, { redirect: string }>(
    this,
    `/entities/merge`,
  );

  pronounsUpdated = postRoute<{ entityId: string; pronouns: string }>(
    this,
    `/entities/pronounsUpdated`,
  );

  getMutualConnections = getRoute<{ entityId: string }, MutualConnectionsResponse>(
    this,
    `/mutualConnections`,
  );

  analyzeConnections = postRoute<
    ConnectionsAnalysesRequest,
    Record<string, ConnectionAnalysisResponse>
  >(this, "/mutualConnections/analyses");

  getAuthoredMedia = getRoute<{ entityId: string }, AuthoredMediaResponse>(this, `/authoredMedia`);

  createAuthoredMedia = postRoute<
    { entityId: string; urls: string[]; isSource?: boolean },
    AuthoredMediaResponse
  >(this, `/authoredMedia/create`);

  deleteAuthoredMedia = deleteRoute<{ entityId: string; id: string }, { count: number }>(
    this,
    `/authoredMedia/delete`,
  );

  // --- search & create entities

  searchEntities = getRoute<{ query: string; page?: number }, PartialEntity[]>(this, `/entities`);

  searchEntitiesFull = getRoute<
    { query: string; page?: number; type?: "company" | "person" },
    RankedEntityResult[]
  >(this, `/search/entities`);

  searchWeb = getRoute<{ q: string; page?: number }, LinkWithDescription[]>(this, `/search`);

  searchScraped = postRoute<
    { url: string; html: string },
    LinkWithDescription[] | LinkedinSearchResults
  >(this, `/search/scraped`);

  searchLi = getRoute<
    { q: string; type: "all" | "people" | "companies"; page?: number; html?: string },
    LinkedinSearchResults
  >(this, `/search/li`);

  companyCheck = postRoute<
    { url: string; title: string; description: string },
    CompanyCheckResponse
  >(this, `/entities/companyCheck`);

  autocomplete = getRoute<
    { query: string; page?: number; type?: EntityType },
    AutocompleteEntity[]
  >(this, `/search/autocomplete`);

  liAutocomplete = getRoute<{ q: string }, AutocompleteEntity[] | null>(
    this,
    `/search/liAutocomplete`,
  );

  createEntity = postRoute<
    {
      url: string;
      name?: string;
      html?: string;
      fallback?: boolean;
      loadFull?: boolean;
      type?: EntityType;
    },
    Entity
  >(this, `/entities/create`);

  resolveEntity = getRoute<
    { query?: string; entityId?: string },
    { entity: EntityWithAttributes } | null
  >(this, `/entities/resolve`);

  /** NOTE! this counts towards entity visits */
  resolveWithSnapshot = getRoute<
    { query?: string; entityId?: string; fetchMissing?: boolean; refresh?: boolean },
    { entity: EntityWithAttributes; snapshot: GenericProfile } | null
  >(this, `/entities/resolve?snapshot=true`);

  getSnapshot = getRoute<{ entityId: string; refresh?: boolean }, GenericProfile>(
    this,
    `/snapshots`,
  );
  getSnapshots = getRoute<{ entityId: string[]; refresh?: boolean }, GenericProfile[]>(
    this,
    `/snapshots`,
  );

  getCardData = getRoute<{ entityId: string }, PersonCardData | CompanyCardData>(
    this,
    `/entities/cardData`,
  );

  resolveAllEntities = postRoute<
    { queries: string[]; skipAttributes?: boolean },
    Record<string, EntityWithAttributes>
  >(this, `/entities/resolveAll`);

  entities = new Resource<
    Entity,
    Entity[],
    Entity,
    { query?: string; ids?: string[]; page?: number; limit?: number }
  >(this, "entities");

  entityFromDomain = postRoute<
    { requests: WorkplaceResolveRequest[]; resolveMissing?: boolean },
    WorkplaceResolveResponse
  >(this, `/entities/entityFromDomain`);

  updateAliases = postRoute<{ entityId: string; aliases: string[] }, string>(
    this,
    `/entities/aliases`,
  );

  entityToText = getRoute<{ entityId: string }, string>(this, `/entities/toText`);

  // --- meetings

  meetings = new Resource<Meeting, Meeting[], MeetingResponse>(this, "meetings");

  meetingsUpdateNotes = postRoute<{ meetingId: string; notes: string }>(this, `/meetings/notes`);

  // --- calendars

  listCalendars = getRoute<{}, OAuthToken[]>(this, `/calendars/list`);

  getConnectUrl = getRoute<{ type: string; state?: string }, string>(this, `/calendars/connect`);

  syncCalendars = postRoute<{ date?: string }, Meeting[]>(this, `/calendars/sync`);

  deleteToken = deleteRoute<{ tokenId: string }>(this, `/oauth/delete`);

  // --- emails

  emailTokenList = getRoute<{}, OAuthToken[]>(this, `/emails/list`);

  emailWarmup = getRoute<{ email?: string }, SuccessResponse>(this, `/emails/warmup`);

  emailConfirm = postRoute<{
    email: string;
    confirmed: boolean;
    entityId: string;
    meetingId?: string;
    entryPoint?: EmailEntryPoint;
  }>(this, `/emails/confirm`);

  emailIgnore = postRoute<{ email: string }>(this, `/emails/ignore`);

  resolveEmailIdentities = postRoute<ResolveEmailIdentityRequest, ResolveEmailIdentityResponse>(
    this,
    `/emails/resolveEmailIdentities`,
  );

  // --- entity actions

  // This triggers a whole update workflow.
  // This should NOT be used to modify the entity itself.
  triggerEntityRefresh = postRoute<{ entityId: string }>(this, `/entities/update`);

  sourcesRegenerate = postRoute<{ entityId: string }>(this, `/sources/regenerate`);

  summarizeSource = postRoute<{ entityId: string; sourceId: string }, SummarizedResult>(
    this,
    `/sources/summarize`,
  );

  sourcesClearAll = postRoute<{ entityId: string }>(this, `/sources/clearAll`);

  // --- relationships

  relationships = new Resource<Relationship, RelationshipWithEntity[]>(this, "relationships");
  batchDeleteRelationships = deleteRoute<
    { entityId: string; toId: string | undefined; toName: string | undefined },
    boolean
  >(this, "/relationships/batch");
  batchUpdateRelationships = putRoute<
    Partial<Relationship>,
    {
      entityId: string;
      toId?: string;
      toName?: string;
    },
    { count: number }
  >(this, "/relationships/batch");

  relationshipsTo = getRoute<{ entityId: string }, RelationshipWithEntity[]>(
    this,
    `/relationships/to`,
  );

  // --- workflows

  triggerHatchetEvent = postRoute<
    { event: HatchetWorkflow; input: Partial<ValueOf<HatchetWorkflowInputs>>; step?: string },
    string
  >(this, `/hatchet/trigger`);

  // --- playground

  calculateTokens = postRoute<{ input: string }, number>(this, `/playground/tokens`);

  // eslint-disable-next-line @typescript-eslint/max-params
  playgroundResponse = (
    input: string,
    model: AIModelKey,
    cb: (message: string | object) => void,
    tools?: string,
  ): InterruptiblePromise => {
    return this.stream(`/playground/stream`, { input, model, tools }, cb);
  };

  // --- invites

  invites = new Resource<Invite>(this, "invites");

  // --- blocked sources

  // createBlockedSource = postRoute<{ source: string }, BlockedSource>(this, "admin/blockedSources");
  blockedSources = new Resource<BlockedSourceResponse>(this, "admin/blockedSources");

  //--- source notes
  sourceNotes = new Resource<SourceNoteResponse>(this, "admin/sourceNotes");
  //--- waitlist
  waitList = new Resource<WaitList>(this, "waitlist");

  // --- streaming logic

  stream = (url: string, body: unknown, onMessage: StreamListener): InterruptiblePromise => {
    const controller = new AbortController();
    const signal = controller.signal;

    // use fetch since this use of axios doesn't support streaming
    const promise = new Promise<void>((resolve, reject) => {
      fetch(this.endpoint + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        signal,
      })
        .then((response) => {
          if (!response.body) {
            reject(new Error("No response body"));
            return;
          }

          // Create a ReadableStream from the response body
          const reader = response.body.getReader();

          // Define a function to read the stream
          let previousText = "";
          function readStream(): Promise<void> {
            return reader.read().then(({ done, value }) => {
              if (done) {
                resolve();
                return;
              }

              const text = new TextDecoder().decode(value);
              try {
                const toParse = previousText + text;
                const messages = parsePartialMessages(toParse);
                messages.forEach((m) => {
                  onMessage(m);
                });
                previousText = "";
              } catch (error) {
                if (text.length > 2000) {
                  // this can happen because of a very long response
                  previousText += text;
                  return readStream();
                }
                errorTracker.sendError(error, { length: text.length });
                reject(error instanceof Error ? error : new Error(String(error)));
              }

              // Continue reading the stream
              return readStream();
            });
          }

          // Start reading the stream
          return readStream();
        })
        .catch((error: unknown) => {
          if (String(error).includes("aborted")) return;
          logger.info("chat error", error);
          reject(error instanceof Error ? error : new Error(String(error)));
        });
    });

    return {
      interrupt: () => {
        try {
          controller.abort();
        } catch (e) {}
      },
      promise,
    };
  };

  // --- notes
  sourceUpdates = new ResourceWithParent<Entity, EntitySourceUpdate>(
    this,
    "entityId",
    "sourceUpdates",
  );

  // --- social
  fetchSocialPosts = postRoute<{ service: SocialServices; url: string }, SocialPosts | null>(
    this,
    `/socials/posts`,
  );

  // --- support
  emailSupport = postRoute<
    { email: string; message: string; currentTitle: string; currentHref: string },
    string
  >(this, `/support/email`);

  // --- lists
  lists = new ListsResource(this);

  listEntries = new ListEntriesResource(this);

  listViews = new ListViewsResource(this);

  listInvites = new ListInvitesResource(this);

  listUsers = new ListUsersResource(this);

  // --- chat
  sendEntityChatMessage = postRoute<
    {
      entityId: string;
      message: string;
    },
    Message
  >(this, `/chat`);
  // --- conversations
  conversations = new Resource<Conversation>(this, "conversations");

  // --- messages
  messages = new Resource<
    Message,
    { messages: Message[]; hasMore: boolean; nextCursor: string | undefined }
  >(this, "conversations/messages");

  // --- chat
  exchangeEntityMessage = postRoute<
    {
      message: string;
      conversationId: string;
    },
    string
  >(this, `/chat/entity`);

  // --- assessment questions
  assessmentContext = new Resource<AssessmentContextResponse>(this, "assessments/context");

  answerAssessmentQuestions = postRoute<
    {
      entityId: string;
      assessmentContextId: string;
      regenerate?: boolean;
      searchFit?: number;
    },
    AssessmentQAs
  >(this, `/assessments/answerQuestions`);

  // -- version
  getVersion = getRoute<{}, { frontendRefreshVersion: number; gitHash: string }>(this, `/version`);

  // --- smart search

  smartSearchInitialize = postRoute<
    { queryType: AdvancedSearchType; entityType: SearchEntityType },
    { query: FilterQuery; ranking: SearchRanking }
  >(this, `/smartSearch/initialize`);

  guessSectors = postRoute<{ description: string }, { sectors: string[] }>(
    this,
    `/smartSearch/guessSectors`,
  );

  suggestMore = postRoute<{ field: string; values: string[] }, string[]>(
    this,
    `/smartSearch/suggestMore`,
  );

  smartSearchGeneratePlan = postRoute<
    {
      prompt: string;
      request: AdvancedSearchRequest;
    },
    SmartSearchResponse
  >(this, `/smartSearch/generatePlan`);

  smartSearchQuery = postRoute<
    {
      searchId: string;
      name: string;
      query?: FilterQuery;
      ranking?: SearchRanking;
      startingSet: string;
      queryType: AdvancedSearchType;
      entityType: SearchEntityType;
      assessmentContextId?: string;
      skipIds?: string[];
      count?: number;
    },
    SearchQueryAPIResponse
  >(this, `/smartSearch/query`);

  smartSearchSaveToList = postRoute<
    {
      query: FilterQuery;
      ranking?: SearchRanking;
      queryType: AdvancedSearchType;
      entityType: SearchEntityType;
      saveToListId: string;
      count: number;
    },
    SearchQueryAPIResponse
  >(this, `/smartSearch/saveToList`, { timeout: 5 * 60 * 1000 });

  smartSearchQueryExplain = postRoute<
    { searchId: string; documentId: string },
    { explanation: unknown }
  >(this, `/smartSearch/queryExplain`);

  smartSearchCreate = postRoute<SavedSearchCreate, { searchId: string }>(
    this,
    `/smartSearch/create`,
  );

  smartSearchSave = postRoute<
    {
      searchId: string;
      results: SearchQueryAPIResult[];
    },
    void
  >(this, `/smartSearch/save`);

  smartSearchRerank = postRoute<
    {
      searchId: string;
      query: FilterQuery;
      ranking: SearchRanking;
      queryType: AdvancedSearchType;
      entityType: SearchEntityType;
      results: SearchQueryAPIResult[];
    },
    SearchRerankAPIResponse
  >(this, `/smartSearch/rerank`);

  smartSearchExplain = postRoute<SearchExplainRequest, string>(this, `/smartSearch/explain`);

  // --- smart search crud routes
  savedSearch = new Resource<SavedSearch>(this, "savedSearch");

  // --- agent search
  agentSearchChat = postRoute<
    {
      content: string;
      conversationId: string;
      previousMessages?: OpenAIChatMessage[];
    },
    OpenAIChatMessage[]
  >(this, `/agentSearch/chat`);

  // --- eval endpoints

  evalRuns = new Resource<
    EvalRun & { results: EvalResult[] },
    EvalRun[],
    EvalRun & { results: EvalResult[] },
    { taskName: string }
  >(this, "admin/evals/runs");

  evalTestcases = new Resource<EvalTestcase>(this, "admin/evals/testcases");

  runEvaluation = postRoute<{ taskName: string }, { success: boolean; runId: string }>(
    this,
    `/admin/evals/runs`,
  );

  // --- graph endpoints

  graphIndexMe = postRoute<{}>(this, `/graph/me`);

  graphPreload = postRoute<{ entityIds: string[] }>(this, `/graph/preload`);

  graphConnectionScore = getRoute<
    { targetEntityId: string; maxDepth?: number },
    GraphScoreOutput | { disabled: boolean }
  >(this, `/graph/score`);

  graphConnectionPaths = getRoute<{ targetEntityId: string }, { paths: ConnectionPath[] }>(
    this,
    `/graph/paths`,
  );
}

const API = new APIService();

export default API;

const parsePartialMessages = (text: string): (object | string)[] => {
  try {
    return JSON.parse("[" + (text.endsWith(",") ? text.slice(0, -1) : text) + "]") as (
      | object
      | string
    )[];
  } catch (e) {
    return [text];
  }
};

interface SearchParent {
  id: string;
}
