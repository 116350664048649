import { Type } from "@sinclair/typebox";

import { DstLocation, DstProfile } from "./profile.typebox";

export enum DstInvestmentStage {
  PreSeed = "Pre-Seed",
  Seed = "Seed",
  SeriesA = "Series A",
  SeriesB = "Series B",
  SeriesC = "Series C",
  SeriesDPlus = "Series D+",
  Other = "Other",
}

export const DstInvestmentStageSchema = Type.Enum(DstInvestmentStage);

export const DstInvestorProfile = Type.Intersect([
  DstProfile,
  Type.Object({
    name: Type.String(),
    title: Type.Optional(Type.String()),
    investmentLocations: Type.Optional(Type.Array(DstLocation)),
    averageCheckSize: Type.Optional(Type.Number()),
    minCheckSize: Type.Optional(Type.Number()),
    maxCheckSize: Type.Optional(Type.Number()),
    sectors: Type.Optional(Type.Array(Type.String())),
  }),
]);

export const DstVCTeamMemberRole = Type.Union([
  Type.Literal("founder"),
  Type.Literal("partner"),
  Type.Literal("employee"),
]);

export const DstVCTeamMember = Type.Intersect([
  DstInvestorProfile,
  Type.Object({
    role: Type.Optional(DstVCTeamMemberRole),
  }),
]);

export const DstPortfolioCompany = Type.Intersect([
  Type.Object({
    name: Type.String(),
    description: Type.Optional(Type.String()),
    dstId: Type.Optional(Type.String()),
    sectors: Type.Optional(Type.Array(Type.String())),
    unicorn: Type.Optional(Type.Boolean()),
    website: Type.Optional(Type.String()),
  }),
]);

export const DstPortfolioInvestment = Type.Object({
  amount: Type.Optional(Type.Number()),
  year: Type.Optional(Type.Number()),
  stage: Type.Optional(DstInvestmentStageSchema),
  lead: Type.Optional(Type.Boolean()),
  company: DstPortfolioCompany,
});

export const DstVCFund = Type.Object({
  name: Type.Optional(Type.String()),
  size: Type.String(),
  year: Type.Optional(Type.Number()),
  stages: Type.Optional(Type.Array(Type.String())),
});

export const DstVCStage = Type.Object({
  stage: DstInvestmentStageSchema,
  lead: Type.Optional(Type.Boolean()),
});

export const DstVCFirmProfile = Type.Intersect([
  Type.Object({
    stages: Type.Array(DstVCStage),

    funds: Type.Optional(Type.Array(DstVCFund)),
    locations: Type.Optional(Type.Array(DstLocation)),
    investments: Type.Optional(Type.Array(DstPortfolioInvestment)),
    sectors: Type.Optional(Type.Array(Type.String())),
    team: Type.Optional(Type.Array(DstVCTeamMember)),
    totalFundsRaised: Type.Optional(Type.Number()),
  }),
]);
