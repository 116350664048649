import { AnalyticsEvent } from "@/types/analytics.typebox";

import { Type } from "@sinclair/typebox";

export const AIInvocationEvent = Type.Intersect([
  AnalyticsEvent,
  Type.Object({
    aiInvocationId: Type.String(),
    entityId: Type.Optional(Type.String()),
    model: Type.String(),
    content: Type.String(),
    response: Type.String(),
    gitHash: Type.Optional(Type.String()),
  }),
]);
