import "@/lib/error";
import { UserVisibleError } from "@/lib/error";
import { isAxiosError } from "axios";

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

function printAxiosData(data: unknown): string | undefined {
  if (typeof data === "string") {
    // Sometimes errors have DOCTYPE and sometimes just start with <html
    return data.match(/^\s*<(!DOCTYPE)?\s*html/i) ? undefined : data;
  }
  if (typeof data === "object" && data !== null) {
    const { error: err, message } = data as {
      error?: string | { message: string };
      message?: string;
    };
    if (typeof err === "string") return err;
    if (typeof message === "string") return message;
    if (typeof err === "object" && err !== null) {
      return typeof err.message === "string" ? err.message : JSON.stringify(err);
    }
    return Object.entries(data)
      .filter(([_, value]) => typeof value === "string" || typeof value === "number")
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");
  }
  return undefined;
}

/** produce a pretty string for an error */
export function prettyError(error: unknown): string {
  if (!error) return "error was missing";

  if (typeof error === "string") {
    return error;
  }

  if (error instanceof AggregateError) {
    return error.errors.map((err) => prettyError(err)).join("; ");
  }

  if (error instanceof UserVisibleError) {
    return error.message;
  }

  try {
    if (isAxiosError(error)) {
      if (error.code === "ECONNABORTED") return "Timeout";
      if (error.response) {
        const axiosData = printAxiosData(error.response.data);
        return axiosData ?
            `Response: ${axiosData}`
          : `Error (Status: ${error.response.status}, ${error.response.statusText})`;
      }
      return error.message ?? String(error);
    }
  } catch (e) {
    console.warn("Error unwrapping error", error, e);
    return `Error: ${String(error)}.`;
  }

  const normalizedError = getNormalizedError(error);
  if (normalizedError) {
    const name =
      (normalizedError as { name?: string })?.name ||
      normalizedError.constructor?.name ||
      "UnknownError";
    const message = normalizedError.message || "An error occurred";
    const cause = normalizedError.cause ? prettyError(normalizedError.cause) : undefined;
    if (cause && !cause.includes(message)) {
      return `${name}: ${message}. Cause: ${cause}`;
    }
    return `${name}: ${message}`;
  }

  if (typeof error === "string") {
    return error;
  }

  try {
    // if we have no clue what this is, we fall back to stringify, but we should
    // fix any case where we end up here.
    return JSON.stringify(error);
  } catch (e) {
    console.warn("Error unwrapping error", error, e);
    return `Error: ${String(error)}`;
  }
}

/*
 * Errors from the fetch API and possibly other builtins are returning error objects
 * that fail "instanceof Error" perhaps because they come from other execution contexts
 */
export function getNormalizedError(u: unknown): Error | null {
  if (u instanceof Error) return u;

  if (typeof u === "object" && u !== null) {
    const error = u as Partial<Error>;
    if (typeof error.message !== "string") {
      return null;
    }
    const message = error.message;
    const cause = error.cause ? getNormalizedError(error.cause) : undefined;
    const stack = error.stack;

    const result = new Error(message, { cause });
    if (error.name) result.name = error.name;
    if (stack) result.stack = stack;
    return result;
  }
  return null;
}

/** Return a more actionable error if there is a nested error.
 *  If error is a failed network request, return the response object, else return the error itself */
export function getUnderlyingError(error: unknown): unknown {
  if (!error) return "error was missing";

  if (isAxiosError(error)) {
    if (error.response?.data) {
      return error.response.data;
    }
  }

  const normalizedError = getNormalizedError(error);
  if (normalizedError) {
    const cause = normalizedError.cause ? getUnderlyingError(normalizedError.cause) : undefined;
    if (cause) {
      return getUnderlyingError(cause);
    }
  }

  return error;
}

export const favicon = (webpage: {
  favicon: string | null | undefined;
  url: string;
}): string | undefined => {
  const preferRootDomainForFavicon = ["medium.com"];
  try {
    if (!webpage.favicon) {
      const url = new URL(webpage.url);
      const rootDomain = url.hostname.split(".").slice(-2).join(".");
      const preferRootDomain = preferRootDomainForFavicon.find((d) => rootDomain.includes(d));
      return `https://www.google.com/s2/favicons?domain=${
        preferRootDomain ? rootDomain : url.hostname
      }&sz=32`;
    }

    const url = new URL(webpage.favicon, webpage.url);
    return url.toString();
  } catch (e) {
    return webpage.favicon?.startsWith("http") ? webpage.favicon : undefined;
  }
};

export const cachedImage = (image: string | undefined) => {
  if (!image) return undefined;

  if (image.startsWith("/api")) return image;

  if (["twimg.com", "licdn.com", "crunchbase.com"].find((d) => image?.includes(d))) {
    const prefix = process.env.NODE_ENV == "development" ? "https://distill.fyi" : "";
    image = `${prefix}/api/images/get?url=${encodeURIComponent(image)}`;
  }
  return image;
};

export function getRandomItem<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

export function assertUnreachable(x?: never): never {
  throw new Error("Didn't expect to get here: " + String(x));
}

export function assertIsDefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(`${String(value)} is not defined`);
  }
}

export function intersperse<A, B>(a: A[], b: B[]): (A | B)[] {
  const length = Math.max(a.length, b.length);
  const ret = [];
  for (let i = 0; i < length; i++) {
    if (i < a.length) {
      ret.push(a[i]);
    }
    if (i < b.length) {
      ret.push(b[i]);
    }
  }
  return ret;
}

export function updateUnsubscribe(
  self: { unsubscribe: null | (() => void) },
  unsubscribe: () => unknown,
  skipImmediateUnsubscribe?: boolean,
) {
  // unsubscribe any existing subscriptions as the first thing we do
  // this cleans up dev hot-reload issues, but it shouldn't hurt in general
  if (!skipImmediateUnsubscribe) unsubscribe();
  // then, if there was a previous subscription, unsubscribe to that too
  if (self.unsubscribe) self.unsubscribe();
  self.unsubscribe = unsubscribe;
}

export function isJest() {
  return !!process.env.JEST_WORKER_ID;
}

export function uniqueKey(baseKey: string, keys: Set<string>): string {
  let i = 0;
  let key = baseKey;
  while (keys.has(key)) {
    key = `${baseKey}-${i++}`;
  }
  keys.add(key);
  return key;
}

export function isLinkedinPostAuthor(postUrl: string, entityUrl: string): boolean {
  const postMatch = postUrl.match(/linkedin\.com\/posts\/([^\/\?#_]+)/i);
  const entityMatch = entityUrl.match(/linkedin\.com\/(?:in|company)\/([^\/\?#]+)/i);
  return !!postMatch && !!entityMatch && postMatch[1] === entityMatch[1];
}
