import "@/lib/error";
import { isLinkedInAuthWarning } from "@/lib/error";
import { logger } from "@/lib/logger";
import { HyperDX } from "@/lib/trackers/hyperdx";
import type { TrackerContext } from "@/lib/trackers/trackerContext";
import { Attributes, trace } from "@opentelemetry/api";

import Rollbar, { LogArgument } from "rollbar";

declare global {
  // eslint-disable-next-line no-var
  var __errorTracker: ErrorTracker | undefined;
}
export class ErrorTracker {
  private static instance: ErrorTracker | null = null;

  rollbar?: Rollbar;
  hyperDX?: HyperDX;

  isBrowser = typeof window !== "undefined";
  trackerContext?: TrackerContext | undefined;

  constructor(parent?: ErrorTracker) {
    if (parent) {
      this.rollbar = parent.rollbar;
      this.hyperDX = parent.hyperDX;
    }
  }

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  /* eslint-disable @typescript-eslint/no-unsafe-return */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static getInstance(parent?: ErrorTracker): ErrorTracker {
    if (typeof window !== "undefined") {
      if (!(window as any).__errorTracker) {
        (window as any).__errorTracker = new ErrorTracker(parent);
      }
      return (window as any).__errorTracker;
    } else {
      if (!(global as any).__errorTracker) {
        (global as any).__errorTracker = new ErrorTracker(parent);
      }
      return (global as any).__errorTracker;
    }
  }
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  /* eslint-enable @typescript-eslint/no-unsafe-return */
  /* eslint-enable @typescript-eslint/no-explicit-any */

  testConfig() {
    logger.info("hyperdx", {
      token: process.env.HYPERDX_API_KEY,
      hyperdx: this.hyperDX,
    });
    logger.info("rollbar", {
      token: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
      enabled: process.env.NEXT_PUBLIC_ROLLBAR_ENABLED,
      rollbar: this.rollbar,
    });
    logger.info("trackerContext", this.trackerContext);
  }

  /** this only works on the frontend */
  addBreadcrumb(breadcrumb: {
    category: string;
    action: string;
    message?: string;
    metadata?: Attributes;
  }) {
    logger.debug(breadcrumb);
    this.hyperDX?.addAction(breadcrumb.action, {
      category: breadcrumb.category,
      message: breadcrumb.message,
      ...breadcrumb.metadata,
    });
  }

  /**
   * @returns true if the error was sent, false if it was logged
   */
  logOrSendError(error: unknown, metadata?: Attributes): boolean {
    if (this.shouldSkipSendingError(error)) {
      logger.warn(error, metadata);
      return false;
    }
    this.sendError(error, metadata);
    return true;
  }

  private shouldSkipSendingError(error: unknown): boolean {
    if (isLinkedInAuthWarning(error)) {
      return true;
    }
    if (error instanceof AggregateError) {
      // Could call shouldSkipSendingError on each error,
      // but then we'd have to check for infinite recursion
      // and seems like overkill
      return error.errors.every((e) => isLinkedInAuthWarning(e));
    }
    return false;
  }

  sendError(error: unknown, metadata?: Attributes) {
    if (typeof error === "string") {
      // turn strings to errors to get a stack trace
      error = new Error(error);
    }

    // pull props out of current span
    const { request, user, workflow } = this.trackerContext?.get() || {};
    const traceId = trace.getActiveSpan()?.spanContext().traceId;
    if (traceId) metadata = { ...(metadata || {}), traceId };
    const requestObj = request && {
      ...request,
      rollbar_person: user,
    };
    if (requestObj?.headers) {
      delete requestObj.headers["cookie"];
    }

    logger.error(error, metadata);
    this.rollbar?.error(error as LogArgument, metadata, requestObj, workflow);
    this.hyperDX?.recordException(error, metadata);
  }

  setUser(user: { id: string; email?: string | null; name?: string | null }) {
    if (this.isBrowser) {
      this.hyperDX?.setGlobalAttributes?.({
        userId: user.id,
        userEmail: user.email ?? "",
        userName: user.name ?? "",
      });
      this.rollbar?.configure({
        payload: { person: { id: user.id, email: user.email ?? "", username: user.name ?? "" } },
      });
    } else {
      this.hyperDX?.setTraceAttributes?.({
        userId: user.id,
        userEmail: user.email ?? "",
        userName: user.name ?? "",
      });
    }
  }
}

const errorTracker = ErrorTracker.getInstance();
export default errorTracker;
