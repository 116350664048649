import { Attribute, LinkedinSearchResult } from "@/types/attributes";
import { CrawlResultSansBody, LinkWithDescription } from "@/types/crawlerType";
import { EntityType, ProfileSections, SourceCat, SourceIsRight } from "@/types/enums";
import { CalendarData, GEvent } from "@/types/google";
import type Prisma from "@prisma/client";
import type { JsonValue } from "@prisma/client/runtime/library";

export type User = {
  id: string;
  name: string | null;
  image?: string | null;
  email?: string | null;
  timezone?: string | null;
  meta?: UserMeta | null;
};

export function isUser(u: unknown): u is User {
  if (typeof u !== "object" || u == null) return false;
  const { id, name } = u as Partial<User>;
  return typeof id === "string" && typeof name === "string";
}

export type UserWithMeta = Omit<User, "meta"> & {
  meta: UserMeta | null;
};

export function isUserWithMeta(u: unknown): u is UserWithMeta {
  if (!isUser(u)) return false;
  const { meta } = u as UserWithMeta;
  return meta == null || typeof meta === "object";
}

export type UserMeta = {
  dev?: boolean; // dev mode
  li_profile?: string; // linkedin profile id
  li_profile_unv?: string; // unverified linkedin profile id
  entity_id?: string; // user's own entity
  onb?: boolean; // has onboarded
  ems?: boolean; // skipped email connect
  ua?: string; // user agent
  pes?: boolean; // prefer extension scraper
  hidden_emails?: string[];
  hidden_domains?: string[];
  ext_ver?: string;

  /** @deprecated */
  li_at?: string; // linkedin cookie
  /** @deprecated */
  li_rm?: string; // linkedin remember me
  /** @deprecated */
  li_premium?: boolean; // linkedin premium?
};

export const meta = <T>(u: { meta: null | JsonValue | T } | undefined | null) =>
  (u?.meta || {}) as T;

export const userMeta = meta<UserMeta>;

export type EntityMeta = {
  coverUrl?: string;
  screenshotUrl?: string;
  // If set to true, links to the entity should be disabled. Used for entities
  // like "Stealth Startup".
  disableLinks?: boolean;
};

export const entityMeta = meta<EntityMeta>;

export type OAuthTokenMeta = {
  email: string;
  calendars?: CalendarData[];
};

export const oauthTokenMeta = meta<OAuthTokenMeta>;

export type MeetingMeta = {
  tags: string[];
};

export const meetingMeta = meta<MeetingMeta>;

export type Member = {
  id: string;
  name: string;
  image?: string | null;
  role?: string | null;
};

// --- model types

export type Entity = Omit<Prisma.Entity, "type"> & {
  type: EntityType;
};

export function isEntityType(type: unknown): type is EntityType {
  if (typeof type !== "string") return false;
  return ["person", "company", "unknown"].includes(type);
}

export function isEntity(u: unknown): u is Entity {
  if (typeof u !== "object" || u === null) return false;
  const { id, type, name, url } = u as Partial<Entity>;
  return (
    typeof id === "string" &&
    isEntityType(type) &&
    typeof name === "string" &&
    typeof url === "string"
  );
}

export type AutocompleteEntity = Pick<Entity, "id" | "name" | "type" | "url" | "imageUrl"> & {
  subtitle?: string;
  source?: string;
};

export type ListItem = {
  id: string;
  type: EntityType;
  ts: number;
};

export type EntityList = Prisma.EntityList & {
  items: ListItem[];
};

export type EntityWithAttributes = Entity & {
  attributes: Attribute[];
  confirmed?: boolean;
};

export function isEntityWithAttributes(u: unknown): u is EntityWithAttributes {
  if (!isEntity(u)) return false;
  const { attributes } = u as Partial<EntityWithAttributes>;
  return Array.isArray(attributes);
}

export const hasAttributes = (entity: Entity | Prisma.Entity): entity is EntityWithAttributes => {
  if (!isEntityWithAttributes(entity)) return false;
  return entity.attributes.length > 0;
};

export type ScrapedPage = Omit<Prisma.ScrapedPage, "results"> & {
  results: CrawlResultSansBody[];
};

export type ContentItem = {
  category: string;
  type: "link" | "image" | "video" | "fact" | "snippet" | "quote" | "summary";
  text: string;
  href?: string;
  source?: string;
};

export type ProfileSectionContent = {
  summary?: string;
  quotes?: { text: string; author: string; url: string }[];
  facts?: { title: string; dates?: string[]; text?: string; url?: string }[];
  bullets?: HighlightWithSources[];
  links?: LinkWithDescription[];
  url?: string;
  urlTitle?: string;
  entityId?: string;
};

export type HighlightsCore = {
  bullets: HighlightWithSources[];
  sources: string[];
};

export type TypedHighlights = Omit<Prisma.Highlights, "highlights" | "sources"> & HighlightsCore;

export type HighlightWithSources = {
  text: string;
  sources?: string[];
};

export type HighlightsFeedback = Omit<Prisma.HighlightsFeedback, "userId"> & {
  author: string;
};

export type ProfileSection = Omit<Prisma.ProfileSection, "data" | "type"> & {
  type: ProfileSections;
  data: ProfileSectionContent;
};

export type Meeting = Omit<Prisma.Meeting, "attendees" | "conferencing" | "recurring" | "meta"> & {
  attendees?: GEvent["attendees"];
  conferencing?: GEvent["conferenceData"];
  recurring?: {
    recurrence?: string[];
    recurringEventId?: string;
  };
  meta: MeetingMeta | null;
};

export type MeetingProfile = Omit<Prisma.MeetingProfile, "entities"> & {
  entities: Record<string, string | null>;
};

export function isMeetingProfile(u: unknown): u is MeetingProfile {
  const p = u as MeetingProfile;
  return Boolean(p.id && p.userId && p.meetingId && p.entities);
}

export type DogfoodFeedback = Prisma.DogfoodFeedback & {
  isLatest?: boolean;
  feedbackCategories?: Prisma.FeedbackCategory[];
};

export type ProfileFeedback = Omit<
  Prisma.DogfoodFeedback,
  "entityId" | "userId" | "response" | "feedbackCategories"
> & {
  entity: {
    id: string;
    name: string;
    type: string;
    url: string;
    slug: string | null;
  };
  user: {
    name: string | null;
  };
  processedByUser?: {
    name: string | null;
  };
  response: {
    rating: number;
    text: string;
  };
  feedbackCategories?: { id: string; name: string }[];
  problemLinks?: { problemId: number }[];
  isLatest?: boolean;
};

export type ProfileProblemWithUser = Prisma.ProfileProblem & {
  createdByUser?: { name: string };
  feedbackLinks?: { feedbackId: string; resolvedAt: Date | null }[];
};

export type ConversationListing = {
  id: string;
  entityId?: string | null;
  type: string;
  context: string;
  createdAt: Date;
  userId: string;
  user?: {
    id: string;
    name: string;
    image: string;
  };
};

export type SourceSummaryItem = {
  date?: string;
  nugget: string;
  tags?: string[] | string; // the spec says string[] but sometimes the AI boofs
  highlight?: boolean;
};

export type EntitySourceWithSummaryItems = Omit<Prisma.EntitySource, "summaryItems"> & {
  summaryItems: SourceSummaryItem[];
};

export const SOURCE_RELEVANT_THRESHOLD = 3;
export function getSourceCategory(s: Prisma.EntitySource): SourceCat {
  if (s.hidden) return SourceCat.Hidden;
  if (s.error) return SourceCat.Error;
  if (s.isRight === SourceIsRight.No) return SourceCat.Wrong;
  if (s.isRight === SourceIsRight.Duplicate) return SourceCat.Duplicate;
  if (s.similarToUrl) return SourceCat.Duplicate;
  if (s.starred) return SourceCat.Starred;
  if (s.isRight === SourceIsRight.Unsure) return SourceCat.Unsure;
  if (s.relevance && s.relevance > SOURCE_RELEVANT_THRESHOLD) return SourceCat.Relevant;
  if (s.relevance != null) return SourceCat.Unhelpful;
  return SourceCat.NotScored;
}

export type EntityVisitWithEntity = Prisma.EntityVisit & {
  entity: Prisma.Entity & { attributes: Attribute[] };
};

export type GetEntity<T extends string | string[]> =
  | GetEntityById<T>
  | GetEntityByUrl<T>
  | GetEntityByEmail<T>
  | GetEntityByAlias<T>
  | GetEntityBySlug<T>;

export type GetEntityById<T extends string | string[]> = {
  discriminator: "id";
  id: T;
};

export type GetEntityByAlias<T extends string | string[]> = {
  discriminator: "alias";
  alias: T;
};

export type GetEntityByUrl<T extends string | string[]> = {
  discriminator: "url";
  url: T;
};

export type GetEntityByEmail<T extends string | string[]> = {
  discriminator: "email";
  email: T;
};

export type GetEntityBySlug<T extends string | string[]> = {
  discriminator: "slug";
  slug: T;
};
