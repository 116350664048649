import { Kind, TSchema, Type, TypeRegistry } from "@sinclair/typebox";
import { UUID } from "crypto";

export const Nullable = <T extends TSchema>(schema: T) => Type.Union([schema, Type.Null()]);
export const OptionalNullable = <T extends TSchema>(schema: T) => Type.Optional(Nullable(schema));

export const PagingOptions = Type.Object({
  page: Type.Number(),
  pageSize: Type.Optional(Type.Number()),
});

TypeRegistry.Set("UUID", (_, value) => typeof value === "string" && value.length === 36);

export const UUIDSchema = Type.Unsafe<UUID>({ [Kind]: "UUID" });
