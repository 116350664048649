import { Type, type Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

import {
  CorporateFactsDef,
  EntityFactsDef,
  FactsDef,
  PersonFactsDef,
  VCFactsDef,
} from "@/typeDef/factsDef";
import { extractLabels, extractSchemas, extractTypeDefKeys } from "@/typeDef/types";
import { type Fact } from "@prisma/client";

export const EntityFact = extractTypeDefKeys(EntityFactsDef);
export type EntityFact = keyof typeof EntityFactsDef;

export const PersonFact = extractTypeDefKeys(PersonFactsDef);
export type PersonFact = keyof typeof PersonFactsDef;

export const VCFact = extractTypeDefKeys(VCFactsDef);
export type VCFact = keyof typeof VCFactsDef;

export const CorporateFact = extractTypeDefKeys(CorporateFactsDef);
export type CorporateFact = keyof typeof CorporateFactsDef;

export const CompanyFact = {
  ...CorporateFact,
  ...VCFact,
} as const;
export type CompanyFact = CorporateFact | VCFact;

/** type validator with the complete set of fact values */
const FactSchemas = extractSchemas(FactsDef);
const AllFactValues = Type.Object(FactSchemas);

/** a type that contains all fact values */
export type FactValueSet = Static<typeof AllFactValues>;
export type FactType = keyof FactValueSet;

/** a type that can contain any fact value with mutable properties */
export type FactValuePartial = {
  -readonly [K in keyof FactValueSet]?: FactValueSet[K];
};

type FactFormatValidator<T extends FactType> = (val: FactValueSet[T]) => boolean;
type FactFormatValidatorMap = {
  [Type in FactType]: FactFormatValidator<Type>;
};

export const FactFormatValidator: Partial<FactFormatValidatorMap> = {
  [PersonFact.Birthday]: (val: string) => /^\d{2}-\d{2}$/.test(val),
  [PersonFact.Birthyear]: (val: number) => val > 1900 && val < 2100,
};

/** FactSet is a collection of available facts for a given entity */
export type FactSet = Partial<{
  [Type in FactType]: TypedFact<Type>;
}>;

export type FactPair<T extends FactType> = {
  type: T;
  value: FactValueSet[T];
};

/** a fact with a strongly-typed type and value */
export type TypedFact<T extends FactType> = Omit<Fact, "type" | "value"> & {
  type: T;
  value: FactValueSet[T];
};

// Separate "sub-TypedFact" for CorporateFact and VCFact to avoid any assumptions
// made by users of TypedFact
export type BizFact<T extends CorporateFact | VCFact> = Omit<Fact, "type" | "value"> & {
  type: T;
  value: FactValueSet[T];
};

// #region Type Validators
const FactTypeValidator = Type.Enum({
  ...EntityFact,
  ...PersonFact,
  ...CompanyFact,
});

export function isFactType(type: unknown): type is FactType {
  return Value.Check(FactTypeValidator, type);
}

const FactValueValidator = Type.Partial(AllFactValues);

export function isFactValue<T extends FactType>(value: unknown): value is FactPair<T> {
  return Value.Check(FactValueValidator, value);
}
// #endregion

// #region Fact dependencies
export const PersonFactDirectUpstreamDependencies: {
  [K in PersonFact]?: ReadonlyArray<PersonFact>;
} = {
  [PersonFact.LocationGeocoded]: [PersonFact.Location],
} as const;

export const CorporateFactDirectUpstreamDependencies: {
  [K in CorporateFact]?: ReadonlyArray<CorporateFact>;
} = {
  [CorporateFact.HeadquartersGeocoded]: [CorporateFact.Headquarters],
} as const;

export const AllFactDirectUpstreamDependencies: {
  [K in FactType]?: ReadonlyArray<FactType>;
} = {
  ...PersonFactDirectUpstreamDependencies,
  ...CorporateFactDirectUpstreamDependencies,
} as const;

/** Yields all facts which the given fact depends on directly. */
export function* directUpstreamDependencies(type: FactType): Generator<FactType> {
  yield* AllFactDirectUpstreamDependencies[type] ?? [];
}

/** Yields all fact types in order of dependency (upstream dependencies first). */
export const topologicallySortedFactTypes: () => Generator<FactType> = (() => {
  const result: FactType[] = [];
  const permanent = new Set<FactType>();

  function visit(type: FactType, temporary = new Set<FactType>()) {
    if (permanent.has(type)) return;
    if (temporary.has(type)) throw new Error(`Cycle detected: ${type}`);
    temporary.add(type);
    for (const dep of directUpstreamDependencies(type)) visit(dep, temporary);
    permanent.add(type);
    result.push(type);
  }

  for (const type of Object.keys(FactsDef)) visit(type as FactType);

  return function* (): Generator<FactType> {
    yield* result;
  };
})();
// #endregion

const factLabels = extractLabels(FactsDef);
export function getFactLabel(type: FactType): string {
  return factLabels[type];
}

export function getFactTypeForLabel(searchLabel: string): FactType | undefined {
  return Object.entries(factLabels).find(([_, label]) => label === searchLabel)?.[0] as FactType;
}

// Group facts by category for UI organization
export const FACT_GROUPS = {
  entity: Object.values(EntityFact),
  person: Object.values(PersonFact),
  company: Object.values(CompanyFact),
  vc: Object.values(VCFact),
} as const;
