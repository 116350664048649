import { TSchema } from "@sinclair/typebox";

/**
 * TypeDef represents a dictionary of objects containing schema and label properties.
 * Use it with extractTypeDefKeys to help create type-safe enums and
 * mappings from schema definitions that include labels.
 */
export type TypeDef = Record<string, { schema: TSchema; label: string }>;

/**
 * Extracts keys from a TypeDef and creates a value object where each key
 * is mapped to itself as a string. This allows for type-safe access to
 * property names at runtime.
 *
 * @template T - The TypeDef type to extract keys from
 * @param def - The TypeDef object containing schema and label definitions
 * @returns An object where each key is mapped to itself as a string value
 */
export function extractTypeDefKeys<T extends TypeDef>(
  def: T,
): {
  readonly [K in keyof T]: K & string;
} {
  return Object.fromEntries(Object.keys(def).map((key) => [key, key])) as {
    readonly [K in keyof T]: K & string;
  };
}

/**
 * Extracts the schemas from a TypeDef object.
 *
 * @param def - The TypeDef object containing schema and label definitions
 * @returns An object with just the schema properties
 */
export function extractSchemas<T extends TypeDef>(
  def: T,
): {
  [K in keyof T]: T[K]["schema"];
} {
  const result: Record<string, TSchema> = {};
  for (const key in def) {
    result[key] = def[key].schema;
  }
  return result as {
    [K in keyof T]: T[K]["schema"];
  };
}

/**
 * Extracts the labels from a TypeDef object.
 *
 * @param def - The TypeDef object containing schema and label definitions
 * @returns An object with just the label properties
 */
export function extractLabels<T extends TypeDef>(
  def: T,
): {
  [K in keyof T]: T[K]["label"];
} {
  const result: Record<string, string> = {};
  for (const key in def) {
    result[key] = def[key].label;
  }
  return result as {
    [K in keyof T]: T[K]["label"];
  };
}
