/* eslint-disable @typescript-eslint/no-deprecated */
import API from "@/client/api";
import { loggerWithPrefix } from "@/lib/logger";
import eventTracker from "@/lib/trackers/eventTracker";
import { CHROME_EXTENSION_IDS, uiStore } from "@/stores/uiStore";

const logger = loggerWithPrefix("[ext-cookie]");

export async function extractExtensionCookie(userId: string) {
  return new Promise<{
    cookie: string | null;
    id: string;
    li_rm?: string;
    li_a?: string;
  } | null>(async (res) => {
    // no chrome = no extension
    if (!chrome?.runtime) {
      res(null);
      return;
    }

    const payload = {
      user: userId,
      cookieCheck: true,
    };

    let responded = false;

    const results = await Promise.all(
      CHROME_EXTENSION_IDS.map(async (extensionId) => {
        // try to send it a wakeup message
        const working = await new Promise<boolean>((res) => {
          chrome.runtime.sendMessage(
            extensionId,
            { user: userId },
            (response: { cookie: string | null; li_rm?: string; li_a?: string }) => {
              // we access the last error so we don't log to console.
              const _err = chrome.runtime.lastError;
              res(!_err);
            },
          );

          // if the extension is around but sleeping, we won't get a response at all
          setTimeout(() => {
            res(true);
          }, 500);
        });

        if (!working) {
          return;
        }

        return extensionId;
      }),
    );

    const workingExtension = results.find((r) => r);
    if (!workingExtension) {
      res(null);
      return;
    }

    const extensionId = workingExtension;
    logger.info("extension found, sending payload", extensionId, payload);
    const start = Date.now();

    for (let i = 0; i < 20 && !responded; i++) {
      chrome.runtime.sendMessage(
        extensionId,
        payload,
        (response: {
          allCookies?: chrome.cookies.Cookie[];
          cookie: string | null;
          li_rm?: string;
          li_a?: string;
        }) => {
          const _err = chrome.runtime.lastError;
          if (response) {
            responded = true;
            updateVersion(extensionId);

            logger.info("chrome extension response:", response, extensionId);
            if (response.allCookies && response.allCookies.length > 1) {
              eventTracker.capture("multiple-cookies-detected", {
                cookies: JSON.stringify(response.allCookies),
              });
            }
            if (i > 2) {
              eventTracker.capture("chrome-extension-slow-response", {
                extensionId,
                duration: Date.now() - start,
              });
            }
            res({
              cookie: response.cookie,
              id: extensionId,
              li_rm: response.li_rm,
              li_a: response.li_a,
            });
          }
        },
      );
      await new Promise((res) => setTimeout(res, 500));
    }

    if (!responded) {
      eventTracker.capture("chrome-extension-no-response", {
        extensionId,
        duration: Date.now() - start,
      });

      res(null);
    }
  });
}

function updateVersion(extensionId: string) {
  chrome?.runtime?.sendMessage(
    extensionId,
    { message: "version" },
    (response: { version: string }) => {
      const user = uiStore.user.get();
      if (response.version !== user?.meta?.ext_ver) {
        void API.updateUserMeta({
          meta: { ext_ver: response.version },
        });
      }
      if (response.version) eventTracker.capture("chrome-extension-version", response);
    },
  );
}
