import { Type } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

import RawConfig, { sectorV1ToV2Mapping } from "@/config/sectorConfig";
import { RawSectorConfig as RawConfigType } from "@/config/types";
import { loggerWithPrefix } from "@/lib/logger";

const logger = loggerWithPrefix("[businessSectorTaxonomy]");

export type BusinessSectorType = keyof typeof RawConfig;

export const BusinessSectorTypeSchema = Type.Union(
  Object.keys(RawConfig).map((key) => Type.Literal(key as BusinessSectorType)),
);

export type BusinessSectorSubtype = {
  [K in BusinessSectorType]: keyof (typeof RawConfig)[K]["subcategories"];
};

export type BusinessSectorAssignment<T extends BusinessSectorType = BusinessSectorType> = {
  sector: T;
  subsectors: BusinessSectorSubtype[T][];
};

export const BusinessSectorAssignmentSchema = Type.Object({
  sector: BusinessSectorTypeSchema,
  subsectors: Type.Array(
    Type.Union(
      Object.values(RawConfig).flatMap((sector) =>
        Object.keys(sector.subcategories).map((subsector) =>
          Type.Literal(subsector as BusinessSectorSubtype[BusinessSectorType]),
        ),
      ),
    ),
  ),
});

export type BusinessSectorConfig = {
  readonly [key in BusinessSectorType]: {
    readonly name: string;
    readonly synonyms: string[];
    readonly subcategories: {
      readonly [subkey in BusinessSectorSubtype[key]]: {
        readonly name: string;
        readonly synonyms: string[];
      };
    };
  };
};

function initSubsectorsToParents(config: BusinessSectorConfig): Record<string, BusinessSectorType> {
  const result: Record<string, BusinessSectorType> = {};
  for (const [key, value] of Object.entries(config)) {
    const sectorType = key as BusinessSectorType;
    for (const subkey of Object.keys(value.subcategories)) {
      if (subkey in result) {
        logger.error(`Subsector ${subkey} already has a parent sector ${result[subkey]}`);
      }
      result[subkey] = sectorType;
    }
  }
  return result;
}

type SlimSectorConfig = {
  readonly [key in BusinessSectorType]: {
    readonly name: string;
    readonly subcategories: {
      readonly [subkey in BusinessSectorSubtype[key]]: string;
    };
  };
};

function initSlimSectorConfig(config: BusinessSectorConfig): SlimSectorConfig {
  return Object.fromEntries(
    Object.entries(config).map(([key, value]) => [
      key,
      {
        name: value.name,
        subcategories: Object.fromEntries(
          Object.entries(value.subcategories).map(([subkey, subvalue]) => [subkey, subvalue.name]),
        ),
      },
    ]),
  ) as SlimSectorConfig;
}

class SectorTaxonomy {
  readonly config: BusinessSectorConfig;
  readonly slimconfig: SlimSectorConfig;
  private readonly subsectorsToParents: Record<string, BusinessSectorType>;

  constructor({ config }: { config: RawConfigType }) {
    this.config = Object.freeze(config) as BusinessSectorConfig;
    this.slimconfig = Object.freeze(initSlimSectorConfig(this.config));
    this.subsectorsToParents = Object.freeze(initSubsectorsToParents(this.config));
  }

  public findParentSector(subsectorKey: unknown): BusinessSectorType | undefined {
    if (typeof subsectorKey !== "string") {
      return undefined;
    }
    return this.subsectorsToParents[subsectorKey];
  }

  public asSectorAssignment(maybeSubsector: unknown): BusinessSectorAssignment | undefined {
    if (typeof maybeSubsector !== "string") {
      return undefined;
    }
    if (this.isSectorType(maybeSubsector)) {
      return {
        sector: maybeSubsector,
        subsectors: [],
      };
    }
    const parentSector = this.findParentSector(maybeSubsector);
    if (!parentSector) {
      return undefined;
    }
    return {
      sector: parentSector,
      subsectors: [maybeSubsector as BusinessSectorSubtype[typeof parentSector]],
    };
  }

  public isSectorType(value: unknown): value is BusinessSectorType {
    return Value.Check(BusinessSectorTypeSchema, value) && this.config[value] !== undefined;
  }

  public isSectorSubtype(
    sector: BusinessSectorType,
    value: unknown,
  ): value is BusinessSectorSubtype[typeof sector] {
    if (typeof value !== "string") {
      return false;
    }
    return Object.keys(this.config[sector].subcategories).includes(value);
  }

  public isBusinessSectorAssignment(value: unknown): value is BusinessSectorAssignment {
    const partial = value as BusinessSectorAssignment;
    const sector = partial.sector;
    if (!this.isSectorType(sector)) {
      return false;
    }
    if (!Array.isArray(partial.subsectors)) {
      return false;
    }
    for (const subsector of partial.subsectors) {
      if (!this.isSectorSubtype(sector, subsector)) {
        return false;
      }
    }
    return true;
  }

  public maybeSector(maybeSector: unknown): BusinessSectorConfig[BusinessSectorType] | undefined {
    if (typeof maybeSector !== "string") {
      return undefined;
    }
    const sector = maybeSector as BusinessSectorType;
    const sectorConfig = this.config[sector];
    if (sectorConfig) {
      return sectorConfig;
    }
    const parentSector = this.findParentSector(maybeSector);
    if (parentSector) {
      return this.config[parentSector];
    }
    const v1Sector = sectorV1ToV2Mapping[maybeSector];
    if (v1Sector) {
      return this.config[v1Sector as BusinessSectorType];
    }
    return undefined;
  }

  public maybeName(maybeSector: string): string {
    return this.maybeSector(maybeSector)?.name ?? maybeSector;
  }

  public allParents(): BusinessSectorType[] {
    return Object.keys(this.config) as BusinessSectorType[];
  }

  public asMarkdown(): string {
    const rows: string[] = ["# Business Sector Taxonomy"];
    for (const category of Object.values(this.config)) {
      rows.push(`**${category.name}**`);
      rows.push(`*${category.synonyms.join(", ")}*`);
      rows.push("");
      rows.push("### Subcategories:");
      for (const subcategory of Object.values(category.subcategories)) {
        rows.push(`- **${subcategory.name}**: ${subcategory.synonyms.join(", ")}`);
      }
      rows.push("");
    }
    return rows.join("\n");
  }
}

const sectorTaxonomy = new SectorTaxonomy({ config: RawConfig });

export default sectorTaxonomy;
