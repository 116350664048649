import { Type } from "@sinclair/typebox";

export const DstProfileDataSource = Type.Union([
  Type.Literal("angelList"),
  Type.Literal("crunchbase"),
  Type.Literal("distill"),
  Type.Literal("facebook"),
  Type.Literal("github"),
  Type.Literal("linkedin"),
  Type.Literal("nfx"),
  Type.Literal("pitchbook"),
  Type.Literal("twitter"),
  Type.Literal("website"),
  Type.Literal("vcsheet"),
]);

const DstProfileDataRecord = Type.Partial(Type.Record(DstProfileDataSource, Type.String()));

const JsonValue = Type.Union([
  Type.String(),
  Type.Number(),
  Type.Boolean(),
  Type.Array(Type.Any()),
  Type.Record(Type.String(), Type.Any()),
]);

export enum DstSourceType {
  AdditionalSource = "additionalSource",
  Profile = "profile",
  Website = "website",
}

/**
 * Combination of EntitySource and ScrapedPage
 */
export const DstSource = Type.Object({
  url: Type.String(),
  type: Type.Enum(DstSourceType),
  unstructuredText: Type.Optional(Type.String()),
  crawlResult: Type.Optional(JsonValue),
  scrapeType: Type.Optional(Type.String()),
  summary: Type.Optional(Type.String()),
});

export const DstProfile = Type.Object({
  name: Type.String(),
  urls: DstProfileDataRecord,
  descriptions: Type.Optional(DstProfileDataRecord),
  sources: Type.Optional(Type.Array(DstSource)),
});

export const DstLocation = Type.Object({
  raw: Type.String(),
  region: Type.Optional(Type.String()),
  country: Type.Optional(Type.String()),
  state: Type.Optional(Type.String()),
  city: Type.Optional(Type.String()),
  headquarters: Type.Optional(Type.Boolean()),
});

export const DstCorporateProfile = Type.Intersect([
  DstProfile,
  Type.Object({
    companySize: Type.Optional(Type.Number()),
    foundedYear: Type.Optional(Type.Number()),
    founders: Type.Optional(Type.Array(Type.String())),
    locations: Type.Optional(Type.Array(DstLocation)),
    industries: Type.Optional(Type.Array(Type.String())),
    website: Type.Optional(Type.String()),
    totalFunding: Type.Optional(Type.Number()),
  }),
]);
