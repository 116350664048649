import { isJest, prettyError } from "@/lib/miscUtils";

const LS_LOGLEVEL = "loglevel";

export enum Level {
  TRACE = 0,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  OFF,
}

export type LogConfig = LevelConfig | Level;

export type LevelConfig = {
  level: Level;
  prefix: string[];
};

export function toLevelConfig(arg: LogConfig): LevelConfig {
  return typeof arg === "object" ? arg : { level: arg, prefix: [] };
}

const LevelMap: Record<string, Level> = {
  trace: Level.TRACE,
  debug: Level.DEBUG,
  info: Level.INFO,
  warn: Level.WARN,
  error: Level.ERROR,
};

export const LevelLabels: Record<number, string> = {
  [Level.TRACE]: "trace",
  [Level.DEBUG]: "debug",
  [Level.INFO]: "info",
  [Level.WARN]: "warn",
  [Level.ERROR]: "error",
};

export type LogListener = (config: LogConfig, ...args: unknown[]) => void;

type LogLevel = keyof Console;
type LogMethod = (...args: unknown[]) => void;

const ConsoleLogger: LogListener = (config: LogConfig, ...args: unknown[]) => {
  const { level, prefix } = toLevelConfig(config);
  const logMethod = console[LevelLabels[level] as LogLevel] as LogMethod;
  logMethod(...prefix, ...args);
};

const formatJSON = (args: unknown[]) => {
  const formattedJson = {};
  const message: string[] = [];
  args.forEach((arg) => {
    if (arg instanceof Error) {
      message.push(prettyError(arg));
    } else if (arg && typeof arg === "object" && !Array.isArray(arg)) {
      Object.assign(formattedJson, arg);
      const msgArg = arg as { message: string };
      if (msgArg.message) {
        message.push(msgArg.message);
      }
    } else {
      message.push(String(arg));
    }
  });
  return message.length ? { message, ...formattedJson } : formattedJson;
};

const JSONLogger: LogListener = (config: LogConfig, ...args: unknown[]) => {
  const formattedMessage = JSON.stringify(formatJSON(args));
  ConsoleLogger(config, formattedMessage);
};

const isNode = typeof localStorage == "undefined";

class Logger {
  logListeners: LogListener[] = [
    process.env.NODE_ENV !== "development" && isNode ? JSONLogger : ConsoleLogger,
  ];
  didNotLogListeners: LogListener[] = [];
  allLogListeners: LogListener[] = [];

  private _level: Level;

  constructor() {
    const envLogLevel = process.env.LOG_LEVEL;
    const defaultLevel =
      envLogLevel ? LevelMap[envLogLevel]
      : isNode ? Level.INFO
      : Level.WARN;

    try {
      const storedLogLevel = !isNode && localStorage.getItem(LS_LOGLEVEL);
      if (storedLogLevel && !LevelMap[storedLogLevel]) {
        console.warn(`Invalid log level in localStorage: ${storedLogLevel}`);
      }
      const level = storedLogLevel && LevelMap[storedLogLevel];
      this._level = level || defaultLevel;
    } catch (e) {
      this._level = defaultLevel;
    }
  }

  get level() {
    return this._level;
  }

  set level(level: Level) {
    if (!isJest()) this.info("[logger] setting level", LevelLabels[level]);
    this._level = level;
  }

  setLevelString = (level: string) => (this.level = LevelMap[level]);

  log(config: LogConfig, ...args: unknown[]) {
    const level = typeof config === "object" ? config.level : config;
    this.allLogListeners.forEach((l) => {
      l(config, ...args);
    });
    if (level < this._level) {
      this.didNotLogListeners.forEach((l) => {
        l(config, ...args);
      });
      return;
    }
    this.logListeners.forEach((l) => {
      l(config, ...args);
    });
  }

  saveDefaultLogLevel(level: string) {
    localStorage.setItem(LS_LOGLEVEL, level);
    this.setLevelString(level);
  }

  trace = (...args: unknown[]) => {
    this.log(Level.TRACE, ...args);
  };
  debug = (...args: unknown[]) => {
    this.log(Level.DEBUG, ...args);
  };
  info = (...args: unknown[]) => {
    this.log(Level.INFO, ...args);
  };
  warn = (...args: unknown[]) => {
    this.log(Level.WARN, ...args);
  };
  error = (...args: unknown[]) => {
    this.log(Level.ERROR, ...args);
  };

  withPrefix = (...prefix: string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const loggerInstance = this;
    return {
      get level(): Level {
        return loggerInstance._level;
      },
      set level(level: Level) {
        loggerInstance.level = level;
      },
      log: (level: Level, ...args: unknown[]) => {
        this.log({ level, prefix }, ...args);
      },
      trace: (...args: unknown[]) => {
        this.log({ level: Level.TRACE, prefix }, ...args);
      },
      debug: (...args: unknown[]) => {
        this.log({ level: Level.DEBUG, prefix }, ...args);
      },
      info: (...args: unknown[]) => {
        this.log({ level: Level.INFO, prefix }, ...args);
      },
      warn: (...args: unknown[]) => {
        this.log({ level: Level.WARN, prefix }, ...args);
      },
      error: (...args: unknown[]) => {
        this.log({ level: Level.ERROR, prefix }, ...args);
      },
    };
  };
}

const instance = new Logger();

export const logger = instance;

export const loggerWithPrefix = instance.withPrefix;

export interface DistillLogger extends Omit<ReturnType<typeof loggerWithPrefix>, "log"> {}
