import { Type } from "@sinclair/typebox";

export const UnresolvedPlace = Type.Object({
  address: Type.String(),
});

export const LatLng = Type.Object({
  latitude: Type.Number(),
  longitude: Type.Number(),
});

export const LatLngBounds = Type.Object({
  southwest: LatLng,
  northeast: LatLng,
});

export const ResolvedPlace = Type.Object({
  placeId: Type.String(),
  address: Type.String(),
  centroid: LatLng,
  softBounds: LatLngBounds,
  countrySubdivision: Type.Optional(Type.String()),
  country: Type.Optional(Type.String()),
});

export const Place = Type.Union([UnresolvedPlace, ResolvedPlace]);

export const Places = Type.Array(Place);
