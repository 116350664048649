import { PrivateAttributeMutualConnection } from "@/types/attributes";

export type PrivateAttributePersonAllConnections = {
  name: string;
  linkedinUrl: string;
  profileImage?: string;
  title?: string;
};

export type MutualConnectionsResponse = {
  connections: PrivateAttributeMutualConnection[];
  status: MutualConnectionsStatus;
};

export enum MutualConnectionsStatus {
  Reloading = "reloading",
  Pending = "pending",
  Current = "current",
  Skipped = "skipped",
}

export type ConnectionAnalysis = {
  summary: string;
  strength: number;
};

export enum ConnectionAnalysisStatus {
  SKIPPED = "skipped",
  EXISTING = "existing",
  NEW = "new",
}

export type ConnectionAnalysisResponse = {
  analysis?: ConnectionAnalysis;
  status: ConnectionAnalysisStatus;
};

export type ConnectionsAnalysesRequest = {
  entityId: string;
  otherEntityIds: string[];
  options?: {
    onlyExisting?: boolean;
  };
};
