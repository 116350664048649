import { AnalyticsEvent } from "@/types/analytics.typebox";
import { Type } from "@sinclair/typebox";

export const EmailContact = Type.Object({
  address: Type.String(),
  name: Type.Optional(Type.String()),
});

export const EmailParts = Type.Intersect([
  EmailContact,
  Type.Object({
    input: Type.String(),
    domain: Type.String(),
    local: Type.String(),
  }),
]);

export const GmailContactSource = Type.Union([
  Type.Literal("thread"),
  Type.Literal("inbox"),
  Type.Literal("scrape"),
]);

export const GmailContact = Type.Intersect([
  EmailContact,
  Type.Object({
    source: GmailContactSource,
  }),
]);

export const EmailEventType = Type.Union([
  Type.Literal("CANDIDATE"),
  Type.Literal("RESOLVED"),
  Type.Literal("IGNORED"),
  Type.Literal("CONFIRMED"),
  Type.Literal("REJECTED"),
  Type.Literal("ERRORED"),
  Type.Literal("UNKNOWN"),
]);

export const EmailEntryPoint = Type.Union([
  Type.Literal("gmail"),
  Type.Literal("gmailPoll"),
  Type.Literal("dashboard"),
  Type.Literal("meetings"),
  Type.Literal("manual"),
  Type.Literal("lists"),
  Type.Literal("search"),
]);

export const EmailLookupSource = Type.Union([
  Type.Literal("ai"),
  Type.Literal("tables"),
  Type.Literal("mixrank"),
  Type.Literal("find"),
  Type.Literal("crawl"),
  Type.Literal("existing"),
  Type.Literal("domain"),
]);

export const EmailEvent = Type.Intersect([
  AnalyticsEvent,
  Type.Object({
    contact: EmailContact,
    type: EmailEventType,
    source: Type.Optional(EmailLookupSource),
    entryPoint: EmailEntryPoint,
    searchResultURLs: Type.Optional(Type.Array(Type.String())),
    searchResultJSON: Type.Optional(Type.String()),
    entityId: Type.Optional(Type.String()),
    user: Type.Optional(Type.Object({ id: Type.String(), name: Type.Optional(Type.String()) })),
  }),
]);
