import { Type } from "@sinclair/typebox";

import { DstInvestmentStageSchema, DstPortfolioInvestment } from "@/dst/venture.typebox";
import { Place, Places } from "@/types/geo.typebox";
import { TypeDef } from "./types";

export const EntityFactsDef = {
  Name: { schema: Type.String(), label: "Name" },
  ImageUrl: { schema: Type.String(), label: "Image URL" },
  Title: { schema: Type.String(), label: "Title" },
  Description: { schema: Type.String(), label: "Description" },
  About: { schema: Type.String(), label: "About" },
} as const satisfies TypeDef;

export const PersonFactsDef = {
  Birthday: { schema: Type.String(), label: "Birthday" }, // mm-dd
  Birthyear: { schema: Type.Number(), label: "Birth Year" }, // yyyy
  Gender: { schema: Type.String(), label: "Gender" },
  Pronouns: { schema: Type.String(), label: "Pronouns" },
  Height: { schema: Type.String(), label: "Height" },
  AltNames: { schema: Type.Array(Type.String()), label: "Alternative Names" },
  LanguagesSpoken: { schema: Type.Array(Type.String()), label: "Languages Spoken" },
  Location: { schema: Type.String(), label: "Location" },
  LocationGeocoded: { schema: Place, label: "Location (Geocoded)" },
} as const satisfies TypeDef;

export const VCFactsDef = {
  NumFundsRaised: { schema: Type.Number(), label: "Funds Raised" },
  NumInvestments: { schema: Type.Number(), label: "Investments" },
  NumExits: { schema: Type.Number(), label: "Exits" },
  InvestmentRounds: { schema: Type.Array(Type.String()), label: "Investment Rounds" },
  FamousInvestments: { schema: Type.Array(Type.String()), label: "Famous Investments" },
  InvestmentSectors: { schema: Type.Array(Type.String()), label: "Sectors" },
  InvestmentGeographies: { schema: Type.Array(Type.String()), label: "Geographies" },
  LatestFundSize: { schema: Type.Number(), label: "Latest Fund Size" },
  LatestFundDate: { schema: Type.String(), label: "Latest Fund Date" }, // yyyy-mm-dd
  RecentInvestments: {
    schema: Type.Array(DstPortfolioInvestment),
    label: "Last 5 Lead Investments",
  },
  RecentLeadInvestmentsRollup: {
    schema: Type.Partial(Type.Record(DstInvestmentStageSchema, Type.Number())),
    label: "Recent Lead Investment Stats",
  },
} as const satisfies TypeDef;

export const CorporateFactsDef = {
  Employees: { schema: Type.Number(), label: "Employees" },
  FoundedYear: { schema: Type.Number(), label: "Founded Year" },
  Headquarters: { schema: Type.String(), label: "Headquarters" },
  HeadquartersGeocoded: { schema: Places, label: "Headquarters (Geocoded)" },
  Industry: { schema: Type.String(), label: "Industry" },
  CompanySize: { schema: Type.String(), label: "Size" },
  HistoricalHeadcount: {
    schema: Type.Array(
      Type.Object({
        date: Type.String(),
        headcount: Type.Number(),
      }),
    ),
    label: "Historical Headcount",
  },
  CompanyType: { schema: Type.String(), label: "Type" },
  TotalFunding: { schema: Type.String(), label: "Total Funding" },
  TotalFundingRounds: { schema: Type.Number(), label: "Total Funding Rounds" },
  LatestRound: {
    schema: Type.Object({
      moneyRaised: Type.Optional(Type.String()),
      fundingType: Type.Optional(Type.String()),
      announcedDate: Type.Optional(
        Type.Object({
          month: Type.Number(),
          day: Type.Number(),
          year: Type.Number(),
        }),
      ),
    }),
    label: "Latest Round",
  },
  Founders: { schema: Type.Array(Type.String()), label: "Founders" },
  State: {
    schema: Type.Union([
      Type.Literal("ACTIVE"),
      Type.Literal("ACQUIRED"),
      Type.Literal("POSSIBLY_ACQUIRED"),
      Type.Literal("INACTIVE"),
      Type.Literal("POSSIBLY_INACTIVE"),
    ]),
    label: "Status",
  },
  WebsiteOwnedByDifferentEntity: {
    schema: Type.Boolean(),
    label: "Website Owned By Different Entity",
  },
  Website: { schema: Type.String(), label: "Website" },
  Blog: { schema: Type.String(), label: "Blog" },
  TeamPage: { schema: Type.String(), label: "Team Page" },
  /** @deprecated use characteristics instead */
  BrandedBlurb: { schema: Type.String(), label: "Branded Blurb" },
} as const satisfies TypeDef;

export const CompanyFactsDef = {
  ...CorporateFactsDef,
  ...VCFactsDef,
} as const satisfies TypeDef;

export const FactsDef = {
  ...EntityFactsDef,
  ...PersonFactsDef,
  ...CompanyFactsDef,
} as const satisfies TypeDef;
