import { Type, type Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

import {
  CharacteristicsDef,
  CompanyCharacteristicsDef,
  CorporateCharacteristicsDef,
  PersonCharacteristicsDef,
  SoftwareCharacteristicsDef,
  StartupCharacteristicsDef,
  VCCharacteristicsDef,
} from "@/typeDef/characteristicsDef";
import { extractLabels, extractSchemas, extractTypeDefKeys } from "@/typeDef/types";
import type { EntityCharacteristic } from "@prisma/client";

export const PersonCharacteristic = extractTypeDefKeys(PersonCharacteristicsDef);
export type PersonCharacteristic = keyof typeof PersonCharacteristicsDef;

export const VCCharacteristic = extractTypeDefKeys(VCCharacteristicsDef);
export type VCCharacteristic = keyof typeof VCCharacteristicsDef;

export const CorporateCharacteristic = extractTypeDefKeys(CorporateCharacteristicsDef);
export type CorporateCharacteristic = keyof typeof CorporateCharacteristicsDef;

export const StartupCharacteristic = extractTypeDefKeys(StartupCharacteristicsDef);
export type StartupCharacteristic = keyof typeof StartupCharacteristicsDef;

export const SoftwareCharacteristic = extractTypeDefKeys(SoftwareCharacteristicsDef);
export type SoftwareCharacteristic = keyof typeof SoftwareCharacteristicsDef;

export const CompanyCharacteristic = extractTypeDefKeys(CompanyCharacteristicsDef);
export type CompanyCharacteristic = keyof typeof CompanyCharacteristicsDef;

/** a type that contains all characteristic values */
const CharacteristicSchemas = extractSchemas(CharacteristicsDef);
const AllCharacteristics = Type.Object(CharacteristicSchemas);
export type AllCharacteristics = Static<typeof AllCharacteristics>;
export type CharacteristicType = keyof AllCharacteristics;

/** unvalidated characteristics */
export type MaybeCharacteristic = Partial<Record<CharacteristicType, unknown>>;

/** a type that can contain any characteristic value */
export type PartialCharacteristic = {
  -readonly [K in keyof AllCharacteristics]?: AllCharacteristics[K];
};

/** CharacteristicSet is a collection of available characteristics for a given entity */
export type CharacteristicSet = Partial<{
  [Type in keyof AllCharacteristics]: TypedCharacteristic<Type>;
}>;

export type CharacteristicPair<T extends CharacteristicType> = {
  type: T;
  value: AllCharacteristics[T];
  confidence?: number;
};

/** a characteristic with a strongly-typed type and value */
export type TypedCharacteristic<T extends CharacteristicType> = Omit<
  EntityCharacteristic,
  "type" | "data"
> & {
  type: T;
  data: AllCharacteristics[T];
};

// #region Type Validators
const CharacteristicTypeValidator = Type.Enum({
  ...PersonCharacteristic,
  ...CompanyCharacteristic,
  ...SoftwareCharacteristic,
});

export function isCharacteristicType(type: unknown): type is CharacteristicType {
  return Value.Check(CharacteristicTypeValidator, type);
}

const CharacteristicValueValidator = Type.Partial(AllCharacteristics);

export function isCharacteristicValue<T extends CharacteristicType>(
  value: unknown,
): value is CharacteristicPair<T> {
  return Value.Check(CharacteristicValueValidator, value);
}
// #endregion

// Extract human-readable labels
export const CHARACTERISTIC_LABELS = extractLabels(CharacteristicsDef);
