import { Kind, Type, TypeRegistry } from "@sinclair/typebox";

import { EntityWithAttributes, isEntityWithAttributes, User } from "@/types/db";
import { EntityType } from "@/types/enums";
import { GenericProfile } from "@/types/snapshots";
import { Nullable } from "@/types/typebox";

export const PrismaModel = Type.Object({
  id: Type.Readonly(Type.String()),
  createdAt: Type.Readonly(Type.Date()),
  updatedAt: Type.Readonly(Type.Date()),
});

export const DeleteablePrismaModel = Type.Intersect([
  PrismaModel,
  Type.Object({ deletedAt: Nullable(Type.Date()) }),
]);

// Use this pattern for types that we want to include in Typebox type definitions
// but that aren't themselves defined in Typebox.

TypeRegistry.Set("EntityType", (_, value) => {
  if (typeof value !== "string") {
    return false;
  }
  return Object.values(EntityType).includes(value as EntityType);
});

TypeRegistry.Set("EntityWithAttributes", (_, value) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  return isEntityWithAttributes(value);
});

TypeRegistry.Set("GenericProfile", (_, value) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  const profile = value as Partial<GenericProfile>;
  return typeof profile.generated === "boolean";
});

TypeRegistry.Set("User", (_, value) => {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  const user = value as Partial<User>;
  return typeof user.id === "string";
});

TypeRegistry.Set("UUID", (_, value) => typeof value === "string" && value.length === 36);

export const EntityTypeSchema = Type.Unsafe<EntityType>({ [Kind]: "EntityType" });
export const EntityWithAttributesSchema = Type.Unsafe<EntityWithAttributes>({
  [Kind]: "EntityWithAttributes",
});
export const GenericProfileSchema = Type.Unsafe<GenericProfile>({ [Kind]: "GenericProfile" });
export const UserSchema = Type.Unsafe<User>({ [Kind]: "User" });
