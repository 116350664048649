import type { Entity, Relationship } from "@prisma/client";
import { EntityType } from "./enums";

export enum ExperienceType {
  Work = "work",
  Investment = "investment",
  Advisor = "advisor",
  BoardMember = "board-member",
  SideProject = "side-project",
  Partner = "partner",
  Member = "member",
  Volunteer = "volunteer",
}

/** you must create a new source for every different type of sync, as
 * syncing deletes any existing relationships for that source */
export enum RelationshipSource {
  LinkedInWork = "linkedin",
  LinkedInConnections = "linkedin-connections",
  LinkedInAcquisition = "linkedin-acquisition",
  CrunchbaseInvestment = "crunchbase",
  CrunchbaseFunding = "crunchbase-funding",
  CrunchbaseEmployee = "crunchbase-employee",
  InvestorProfile = "investor-profile",
  NFXEmployee = "nfx",
  Manual = "manual",
}

export const PersonCompanyRelationship = {
  // fromId represents Person, toId represents Company
  WorkedAt: "worked-at",
  InvestedIn: "invested-in",
  Founded: "founded",
  VolunteeredAt: "volunteered-at",
  EducatedAt: "educated-at",
  Advised: "advised",
  OtherExperience: "other-experience", // catch-all for non-work experience
  InvestingPartner: "investing-partner",
} as const;

// This is used in the UI for reclassification, so it can only include types
// that are being shown in the UI.
export const PersonCompanyRelationshipLabels = {
  [PersonCompanyRelationship.WorkedAt]: "Employment",
  [PersonCompanyRelationship.InvestedIn]: "Investment",
  // This is currently shown in the UI as "employment" so we do not
  // show it as a separate entity.
  // [PersonCompanyRelationship.Founded]: "Founded",
  [PersonCompanyRelationship.VolunteeredAt]: "Volunteering",
  [PersonCompanyRelationship.EducatedAt]: "Education",
  [PersonCompanyRelationship.OtherExperience]: "Other",
  // Not currently included in the UI
  // [PersonCompanyRelationship.Advised]: "Advised",
} as const;

// relationships are not bi-directional

export const CompanyPersonRelationship = {
  Employee: "employee",
  Founder: "founder",
  Advisor: "advisor",
  BoardMember: "board-member",
  Investor: "investor",
  CurrentEmployee: "current-employee",
  FormerEmployee: "former-employee",
} as const;

export const PersonPersonRelationship = {
  ConnectedTo: "connected-to",
  FriendOf: "friend-of",
} as const;

export const CompanyCompanyRelationship = {
  AcquiredBy: "acquired-by",
  ParentOf: "parent-of",
  SubsidiaryOf: "subsidiary-of",
  RaisedFrom: "raised-from",
  InvestedIn: "invested-in",
} as const;

const RelationshipType = {
  ...PersonCompanyRelationship,
  ...CompanyPersonRelationship,
  ...PersonPersonRelationship,
  ...CompanyCompanyRelationship,
} as const;

export type RelationshipType = (typeof RelationshipType)[keyof typeof RelationshipType];

export type BaseRelationshipData = {
  description?: string;
  order?: number; // order for items of equivalent sort (larger means more recent)
};

export type RelationshipDataWorkedAt = BaseRelationshipData & {
  title?: string;
  location?: string;
  type?: string;
};

export type RelationshipDataEducatedAt = BaseRelationshipData & {
  degree?: string;
  location?: string;
};

export type RelationshipDataVolunteeredAt = BaseRelationshipData & {
  role?: string;
  industry?: string;
};

export type RelationshipDataInvestedIn = {
  amount?: string;
  fundingRound?: string;
  leadInvestor?: boolean;
  investorName?: string;
  date?: string;
  followOn?: boolean;
};

type DefaultRelationshipData = {
  [Property in RelationshipType]: BaseRelationshipData;
};

export type RelationshipData = DefaultRelationshipData & {
  [PersonCompanyRelationship.WorkedAt]: RelationshipDataWorkedAt;
  [PersonCompanyRelationship.EducatedAt]: RelationshipDataEducatedAt;
  [PersonCompanyRelationship.VolunteeredAt]: RelationshipDataVolunteeredAt;
  [PersonCompanyRelationship.InvestedIn]: RelationshipDataInvestedIn;
};

export type RelationshipWithEntity = Relationship & {
  to?: Entity | undefined | null;
  from?: Entity | undefined | null;
};

/** a partial relationship that can be used to create a new relationship */
export type ProtoRelationship<T extends RelationshipType> = {
  toEntityUrl?: string | null;
  toEntityId?: string | null;
  toEntityName: string;
  toEntityType: EntityType;
  toEntityImageUrl?: string | null;
  toEntityDescription?: string | null;
  toEntityAliases?: string[] | null;

  key: string;
  source: RelationshipSource;
  type: T;
  data: RelationshipData[T];

  // YYYY or YYYY-MM or YYYY-MM-DD
  startedDate?: string;
  endedDate?: string;
  deleted?: boolean;
};

export function sortRelationships<T extends Relationship>(relationships: T[]) {
  const allItemsHaveOrder = relationships.every(
    (r) => (r.data as BaseRelationshipData)?.order !== undefined,
  );

  return relationships.sort((a, b) => {
    const aOrder = (a.data as BaseRelationshipData)?.order || 0;
    const bOrder = (b.data as BaseRelationshipData)?.order || 0;

    if (allItemsHaveOrder) {
      return bOrder - aOrder;
    }

    if (
      (!a.endedDate || a.endedDate === "Present") &&
      (!b.endedDate || b.endedDate === "Present")
    ) {
      if (b.startedDate == a.startedDate || (!!aOrder && !!bOrder)) {
        return bOrder - aOrder;
      }
      return b.startedDate?.localeCompare(a.startedDate || "") || 0;
    } else if (!a.endedDate || a.endedDate == "Present") {
      return -1;
    } else if (!b.endedDate || b.endedDate == "Present") {
      return 1;
    } else {
      if (b.endedDate == a.endedDate) {
        return bOrder - aOrder;
      }
      return b.endedDate.localeCompare(a.endedDate);
    }
  });
}
