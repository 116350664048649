/* eslint-disable @next/next/no-img-element */
import API from "@/client/api";
import { UserVisibleError } from "@/lib/error";
import { InputModalField, uiStore } from "@/stores/uiStore";
import { FeatureFlag, UserWithMeta } from "@/types";
import { useStore } from "@nanostores/react";
import Image from "next/image";
import Link from "next/link";
import { useFeatureFlagEnabled } from "posthog-js/react";

export default function SupportButton() {
  const user = useStore(uiStore.user);
  return (
    <>
      <button
        onClick={() => {
          uiStore.showInputModal.set(
            getSupportModalConfig(user, document.title, decodeURIComponent(window.location.href)),
          );
        }}
        className="px-2 text-black bg-gray-200 rounded hover:bg-gray-300 flex items-center h-6 whitespace-nowrap"
      >
        Report Bug
      </button>
    </>
  );
}

export function getSupportModalConfig(
  user: UserWithMeta | null,
  title: string,
  currentPage: string,
) {
  return {
    title: "Questions or issues?",
    subtitle: "",
    instructions: (
      <>
        <div className="text-sm mt-4">
          Current page: {title} ({currentPage})
        </div>
        <div className="text-sm mt-4">
          Please let us know and we&apos;ll get back to you right away! You can also email us
          anytime at{" "}
          <Link className="text-brand-600" href="mailto:feedback@distill.fyi">
            feedback@distill.fyi
          </Link>
        </div>
      </>
    ),
    type: "info" as const,
    fields: [
      {
        currentValue: user?.email || "",
        placeholder: "Your email address",
      },
      { currentValue: "", placeholder: "Describe what's wrong", multiline: true },
    ] as InputModalField[],
    onSubmit: async (values: string[]) => {
      if (!values[0]) {
        throw new UserVisibleError("Please enter your email address");
      }
      if (!values[1]) {
        throw new UserVisibleError("Please describe what's wrong");
      }
      await API.emailSupport({
        email: values[0],
        message: values[1],
        currentTitle: document.title,
        currentHref: currentPage,
      });
    },
  };
}
