import { LinkedinCompanyProfile, LinkedinProfile } from "@/types/attributes";
import { RelationshipDataInvestedIn } from "@/types/relationshipTypes";
import type { Relationship } from "@prisma/client";

// add all possible structured scrape shapes here
export const CrunchbaseScrapeType = {
  CrunchbasePerson: "crunchbase-person",
  CrunchbaseCompany: "crunchbase-company",
  CrunchbaseCompanyFinancials: "crunchbase-company-financials",
  CrunchbaseCompanyEmployee: "crunchbase-company-employee",
  CrunchbaseCompanyAdvisor: "crunchbase-company-advisor",
  CrunchbaseCompanyFunding: "crunchbase-company-funding",
  CrunchbaseCompanyFundingRound: "crunchbase-company-funding-round",
  CrunchbaseCompanyInvestments: "crunchbase-company-investments",
  CrunchbasePersonInvestments: "crunchbase-person-investments",
  CrunchbasePersonPartnerInvestments: "crunchbase-person-partner-investments",
  CrunchbaseBatchData: "crunchbase-batch-data",
  CrunchbaseVerifier: "crunchbase-verifier",
} as const;

const crunchbaseTypes = new Set(Object.values(CrunchbaseScrapeType));
export function isCrunchbaseScrapeType(type: string): type is CrunchbaseScrapeType {
  return crunchbaseTypes.has(type as CrunchbaseScrapeType);
}

export type CrunchbaseScrapeType = (typeof CrunchbaseScrapeType)[keyof typeof CrunchbaseScrapeType];

export const StructuredScrapeType = {
  ...CrunchbaseScrapeType,
  Github: "github",
  LinkedinPerson: "linkedin-person",
  LinkedinCompany: "linkedin-company",
  VCSheetCompany: "vcsheet-company",
  NFXFirm: "nfx-firm",
  NFXInvestor: "nfx-investor",
  PitchBookCompany: "pitchbook-company",
  LinkedinSalesNavCompany: "linkedin-sales-nav-company",
  LinkedinSalesNavPerson: "linkedin-sales-nav-person",
} as const;

const structuredScrapeTypes = new Set(Object.values(StructuredScrapeType));
export function isStructuredScrapeType(type: string): type is StructuredScrapeType {
  return structuredScrapeTypes.has(type as StructuredScrapeType);
}

export type StructuredScrapeType = (typeof StructuredScrapeType)[keyof typeof StructuredScrapeType];

export interface StructuredScrapeData<T extends StructuredScrapeType> {
  name: string;
  data: StructuredScrapeTypeMap[T];
}
export interface StructuredScrapeTypeMap {
  [StructuredScrapeType.CrunchbasePerson]: CrunchbaseProfile;
  [StructuredScrapeType.CrunchbaseCompany]: CrunchbaseCompanyProfile;
  [StructuredScrapeType.CrunchbaseCompanyFinancials]: CrunchbaseCompanyFinancialsProfile;
  [StructuredScrapeType.CrunchbaseCompanyEmployee]: CrunchbaseCompanyEmployeeProfile[];
  [StructuredScrapeType.CrunchbaseCompanyAdvisor]: CrunchbaseCompanyAdvisorProfile[];
  [StructuredScrapeType.CrunchbaseCompanyFunding]: CrunchbaseCompanyFundingProfile;
  [StructuredScrapeType.CrunchbaseCompanyFundingRound]: CrunchbaseCompanyFundingRoundProfile;
  [StructuredScrapeType.CrunchbaseCompanyInvestments]: CrunchbaseInvestmentsProfile;
  [StructuredScrapeType.CrunchbasePersonInvestments]: CrunchbaseInvestmentsProfile;
  [StructuredScrapeType.CrunchbasePersonPartnerInvestments]: CrunchbaseInvestmentsProfile;
  [StructuredScrapeType.CrunchbaseBatchData]: CrunchbaseBatchCompanyData;
  [StructuredScrapeType.CrunchbaseVerifier]: CrunchbaseProduct[];
  [StructuredScrapeType.Github]: GithubStructuredData;
  [StructuredScrapeType.LinkedinPerson]: LinkedinProfile;
  [StructuredScrapeType.LinkedinCompany]: LinkedinCompanyProfile;
  [StructuredScrapeType.VCSheetCompany]: VCSheetCompanyProfile;
  [StructuredScrapeType.NFXFirm]: NFXFirmProfile;
  [StructuredScrapeType.NFXInvestor]: NFXInvestorProfile;
  [StructuredScrapeType.PitchBookCompany]: PitchBookCompanyProfile;
  [StructuredScrapeType.LinkedinSalesNavCompany]: LinkedinCompanyProfile;
  [StructuredScrapeType.LinkedinSalesNavPerson]: LinkedinProfile;
}

export type GithubStructuredData = {
  userDetails: GithubUserDetails | null;
  repoDetails: GithubRepoDetails[] | null;
  eventDetails: GithubEventDetails[] | null;
};

export interface GithubUserDetails {
  login: string;
  name?: string;
  company?: string;
  blog?: string;
  location?: string;
  email?: string | null;
  bio?: string;
  public_repos: number;
  followers: number;
  following: number;
  created_at: string;
  updated_at: string;
  readme?: string;
}

export interface GithubRepoDetails {
  name: string;
  updated_at: string;
  language?: string | null;
  fork: boolean;
  stargazers_count: number;
  lastUpdate: string;
  description?: string | null;
  html_url: string;
  created_at: string;
  open_issues_count: number;
}

export interface GithubEventDetails {
  type: string;
  created_at: string;
}

export interface CrunchbaseProfile {
  name?: string;
  num_partner_investments?: string;
  num_investments?: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  investments?: CrunchbaseInvestment[];
  partnerInvestments?: CrunchbaseInvestment[];
}

export interface CrunchbaseCompanyProfile {
  name: string;
  description?: string;
  website?: string;
  founders?: string[];
  headquartersAddress?: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  categories?: string[];
}

export type ScrapedCrunchbaseCompanyInvestment = Omit<Relationship, "data"> & {
  data: RelationshipDataInvestedIn;
};

export interface CrunchbaseInvestment {
  date: string;
  company: string;
  companyUrl: string;
  investor?: string;
  investorUrl?: string;
  isLead: boolean;
  investorName: string | null; // only for partner investments
  fundingRound: string;
  fundingRoundUrl: string;
  moneyRaised: string;
}

export interface CrunchbaseInvestmentsProfile {
  name?: string;
  investments?: CrunchbaseInvestment[];
}

/*
 * The /organization/<company-name>/financial_details page
 * lists the funds that a VC firm has raised for investment, which
 * you can't get from the company funding profile.
 */
export interface CrunchbaseCompanyFinancialsProfile {
  companyName: string;

  /* Defined for investors */
  numberOfLeadInvestments?: number;
  numberOfInvestments?: number;
  numberOfFundsRaised?: number;
  numberOfExits?: number;
  exits?: {
    name: string;
    url?: string;
    description: string;
  }[];
  numberOfFundingRounds?: number;

  /* Defined for companies that have raised funds */
  fundsRaised: {
    date: string;
    name: string;
    url?: string;
    amount: string;
  }[];
  fundingTotal?: string;
}

export interface CrunchbaseCompanyFundingProfile {
  companyName?: string;
  numberOfFundingRounds?: number;
  totalFundingAmount?: string;
  fundingRoundUrls?: string[];
}

export interface CrunchbaseCompanyFundingRoundProfile {
  companyName: string;
  announcedDate: string;
  closedOnDate: string;
  fundingType: string;
  fundingStage: string;
  moneyRaised: string;
  preMoneyValuation: string;
  leadInvestors?: { investorName: string; url: string }[];
  investors?: { investorName?: string; investorUrl?: string; partners?: string }[]; // TODO(rhwang) array partners?
}

export interface CrunchbaseCompanyEmployeeProfile {
  name: string;
  title: string;
  url: string;
}
export interface CrunchbaseCompanyAdvisorProfile extends CrunchbaseCompanyEmployeeProfile {
  since?: string; // mm-yyyy
}

export type CrunchbaseBatchCompanyData = {
  properties: {
    website: {
      value: string;
    };
    identifier: {
      uuid: string;
      value: string;
      image_id: string;
      permalink: string;
      entity_def_id: string;
    };
    linkedin: {
      value: string;
    };
  };
};

export type CrunchbaseProduct = {
  cb_product_id: "id";
  id: "id";
  product_id: "id";
  zuora_product_id: "id";
  type: "product";
  product_class: "paid";
  product_type: "Crunchbase";
  url_slug: "cb-starter";
  product_url: "cb-starter";
  name: "Crunchbase Starter";
  display_name: "Crunchbase Starter";
  short_description: "Best-in-class data about the companies you want to track and monitor";
  long_description: "Track and monitor the companies you care about. Make more informed business decisions with Crunchbase’s best-in-class data and actionable insights. https://about.crunchbase.com/products/crunchbase-starter/";
  primary_image_id: "v1651543000/clientapp/product_catalog/crunchbase_starter.svg";
  requires_workspace: false;
  required_products: [];
  plans: [
    {
      id: "id";
      zuora_plan_id: "id";
      cb_plan_id: "id";
      type: "plan";
      display_name: "Crunchbase Starter";
      max_seat_count: 20;
      name: "Crunchbase Starter";
      fees: [
        {
          type: "fee";
          frequency: "yearly";
          model: "volume";
          tiers: [
            {
              type: "tier";
              tier_start: 1;
              tier_end: 20;
              model: "per_unit";
              price: {
                USD: "348.000000000";
              };
            },
          ];
        },
      ];
      sales_channel_type: "self_serve";
      trial_duration: 7;
    },
  ];
  family: "Starter";
  user_role: "licensor";
  subscription: {
    id: "id";
    type: "subscription";
    event_type: "subscription";
    state: "active";
    product_id: "id";
    plan_id: "id";
    plan_name: "Crunchbase Starter";
    price: 348;
    term_type: "monthly";
    term_start: "2024-02-01T00:00:00Z";
    term_end: "2025-02-01T00:00:00Z";
    total_license_count: 1;
    assigned_license_count: 1;
    pending_license_count: 0;
    total_seat_count: 1;
    assigned_seat_count: 1;
    pending_seat_count: 0;
    quantity: 0;
    plans: [
      {
        id: "id";
        name: "Crunchbase Starter";
        display_name: "Crunchbase Starter";
        product_id: "id";
        cb_product_id: "id";
        cb_plan_id: "id";
        fees: [
          {
            type: "fee";
            frequency: "yearly";
            model: "volume";
            tiers: [
              {
                type: "tier";
                tier_start: 1;
                tier_end: 20;
                model: "per_unit";
                price: "";
              },
            ];
          },
        ];
        is_trial: false;
        term_start: "2024-02-01T00:00:00Z";
        term_end: "2025-02-01T00:00:00Z";
        max_seat_count: 20;
        total_seat_count: 1;
        assigned_seat_count: 1;
        pending_seat_count: 0;
        seats_available_for_purchase: 19;
        is_user_manageable: true;
      },
    ];
  };
};

export interface VCSheetCompanyProfile {
  name: string;
  website?: string;
  description?: string;
  location?: string;
  checkSizeRanges?: { min?: number; max?: number }[];
  investmentRounds?: string[];
  leadRounds?: string[];
  sectors?: string[];
  geographies?: string[];
  unicornInvestments?: string[];
  topVerticals?: { percent: number; vertical: string }[];
  url?: string;
  twitter?: string;
  linkedin?: string;
  crunchbase?: string;
  partners?: VCSheetPartner[];
}
export interface VCSheetPartner {
  name: string;
  url: string;
  title?: string;
  linkedin?: string;
  twitter?: string;
  twitterDmOpen?: boolean;
  youtube?: string;
}

export interface NFXFirmProfile {
  name: string;
  url?: string;
  foundedYear?: number;
  website?: string;
  description?: string;
  locations?: string[];
  coinvestors?: { name: string; url: string }[];
  team?: NFXFirmTeamMember[];
  linkedin?: string;
  twitter?: string;
  angelList?: string;
  crunchbase?: string;
}
export interface NFXFirmTeamMember {
  name: string;
  url: string;
  title: string;
  checkSizeAverage?: number;
  checkSizeRange: { min?: number; max?: number };
  locations: string[];
  investmentCategories: string[];
}

export interface NFXInvestorProfile {
  name: string;
  website?: string;
  linkedin?: string;
  twitter?: string;
  angelList?: string;
  crunchbase?: string;
  title?: string;
}

export interface PitchBookCompanyProfile {
  name: string;
  description?: string;
  website?: string;
  headquarters?: string;
  founded?: string;
  employees?: string;
  revenue?: string;
  totalFunding?: string;
  fundingRounds?: {
    date: string;
    round: string;
    amount: string;
  }[];
  investors?: {
    name: string;
    url: string;
  }[];
  twitter?: string;
  linkedin?: string;
  facebook?: string;
  formerlyKnownAs?: string;
  primaryIndustry?: string;
  otherIndustries?: string[];
  verticals?: string[];
}
