import { Type } from "@sinclair/typebox";

import {
  BusinessSectorAssignmentSchema,
  BusinessSectorTypeSchema,
} from "@/types/businessSectorTaxonomy";
import { TypeDef } from "./types";

export const PersonCharacteristicsDef = {
  TopicsOfInterest: {
    schema: Type.String(),
    label: "Topics of Interest",
  },
} as const satisfies TypeDef;

export const VCCharacteristicsDef = {
  FocusArea: {
    schema: Type.String(),
    label: "Focus Area",
  },
  // Support the fallback "generalist" value in addition to specific business sectors
  PortfolioSectors: {
    schema: Type.Array(Type.Union([Type.Literal("generalist"), BusinessSectorTypeSchema])),
    label: "Portfolio Sectors",
  },
} as const satisfies TypeDef;

export const StartupCharacteristicsDef = {
  CustomerType: {
    schema: Type.String({
      enum: ["Enterprise", "Mid-market", "SMB", "Consumer"],
    }),
    label: "Customer Type",
  },
  ShortDescription: {
    schema: Type.String(),
    label: "Short Description",
  },
  TargetPersona: {
    schema: Type.String(),
    label: "Target Persona",
  },
  TopInvestors: {
    schema: Type.Boolean(),
    label: "Top Investors",
  },
} as const satisfies TypeDef;

export const SoftwareCharacteristicsDef = {
  B2BOrConsumer: {
    schema: Type.String({
      enum: ["B2B", "B2C", "Both"],
    }),
    label: "B2B or Consumer",
  },
  BusinessSectors: {
    schema: Type.Array(BusinessSectorAssignmentSchema),
    label: "Business Sectors",
  },
  IsSaaS: {
    schema: Type.Boolean(),
    label: "Is SaaS",
  },
} as const satisfies TypeDef;

export const CorporateCharacteristicsDef = {
  BrandedBlurb: {
    schema: Type.String(),
    label: "Branded Blurb",
  },
  Description: {
    schema: Type.String(),
    label: "Description",
  },
  IsActive: {
    schema: Type.Boolean(),
    label: "Is Active",
  },
} as const satisfies TypeDef;

export const CompanyCharacteristicsDef = {
  ...CorporateCharacteristicsDef,
  ...StartupCharacteristicsDef,
  ...VCCharacteristicsDef,
  ...SoftwareCharacteristicsDef,
} as const satisfies TypeDef;

export const CharacteristicsDef = {
  ...PersonCharacteristicsDef,
  ...CompanyCharacteristicsDef,
} as const satisfies TypeDef;
