/* eslint-disable @typescript-eslint/no-deprecated */
import API from "@/client/api";
import { logger } from "@/lib/logger";
import { CHROME_EXTENSION_IDS } from "@/stores/uiStore";
import {
  EntityType,
  LinkWithDescription,
  LinkedinSearchResults,
  UserMeta,
  UserWithMeta,
  userMeta,
} from "@/types";
import { Entity } from "@prisma/client";

type CanScrapeResponse = { canScrape?: number };
type ScrapeResponse = { scraped?: string; html?: string; failed?: string };

class ExtensionScraper {
  activeExtensionId?: string | null;
  scrapeLevel = 0;
  timedOut = false;
  preferExtensionScraper = false;
  TIMEOUT = 20_000;

  shouldPreferExtensionScrape = (meta: UserMeta) => {
    // default to extension scrape = off, because we have voyager API now
    return meta.pes === true;
  };

  async init(user: UserWithMeta) {
    if (typeof chrome === "undefined") {
      logger.warn("chrome not found");
      return;
    }

    const meta = userMeta(user);
    this.preferExtensionScraper = this.shouldPreferExtensionScrape(meta);
    this.activeExtensionId = null;
    await new Promise<void>((resolve) => {
      CHROME_EXTENSION_IDS.forEach((extensionId) => {
        try {
          chrome?.runtime?.sendMessage(
            extensionId,
            { canScrape: true },
            (response: CanScrapeResponse) => {
              // we access the last error so we don't log to console.
              const _err = chrome.runtime.lastError;
              if (this.activeExtensionId) return;
              if (response) {
                logger.info("[extensionScraper] init response:", response, extensionId);
                if (response.canScrape) {
                  this.activeExtensionId = extensionId;
                  this.scrapeLevel = Number(response.canScrape);
                  resolve();
                }
              }
            },
          );
        } catch (e) {
          // doesn't exist, continue to next one
        }
      });
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }

  // eslint-disable-next-line @typescript-eslint/max-params
  async tryScraping<T>(
    action: string,
    extensionScrape: () => Promise<T>,
    regularScrape: () => Promise<T>,
    skipSecondAttempt?: boolean,
  ): Promise<T> {
    const useExtensionFirst =
      this.activeExtensionId && !this.timedOut && this.preferExtensionScraper;

    if (useExtensionFirst) {
      try {
        logger.info(`${action} via extension`);
        return await extensionScrape();
      } catch (e) {
        logger.warn("extension scrape failed", e);
      }
    }

    let err: unknown;
    try {
      logger.info(`${action} via api`);
      return await regularScrape();
    } catch (e) {
      err = e;
      logger.warn("regular scrape failed", e);
    }

    // try again with extension
    if (!useExtensionFirst && this.activeExtensionId && !skipSecondAttempt) {
      try {
        logger.info(`${action} via extension 2`);
        return await extensionScrape();
      } catch (e) {
        logger.warn("extension scrape failed 2", e);
      }
    }

    throw err;
  }

  async createEntity(
    url: string,
    otherProps: { name?: string; type?: EntityType },
  ): Promise<Entity> {
    return await this.tryScraping(
      "create entity",
      async () => {
        const html = await new Promise<string | undefined>((resolve, reject) => {
          chrome?.runtime?.sendMessage(
            this.activeExtensionId,
            { scrape: url },
            (response: ScrapeResponse) => {
              if (response) {
                resolve(response.html);
              } else {
                resolve(undefined);
              }
            },
          );
        });
        return await API.createEntity({ url, ...otherProps, html });
      },
      () => API.createEntity({ url, ...otherProps }),
      true,
    );
  }

  async searchWeb(query: { q: string; page?: number }): Promise<LinkWithDescription[]> {
    if (!query.q?.trim()) {
      return [];
    }
    return await this.tryScraping(
      "search web",
      async () => {
        if (!query.page) query.page = 0;
        const url = `https://www.google.com/search?q=${encodeURIComponent(query.q)}&start=${
          query.page * 10
        }`;
        return await new Promise((resolve, reject) => {
          let responded = false;
          setTimeout(() => {
            if (!responded) {
              this.timedOut = true;
              reject(new Error("Extension timed out"));
            }
          }, this.TIMEOUT);

          chrome?.runtime?.sendMessage(
            this.activeExtensionId,
            { scrape: url },
            async (response: ScrapeResponse) => {
              if (response?.scraped && response?.html) {
                logger.info("[extensionScraper] scrape response:", response);
                const result = await API.searchScraped({
                  url: response.scraped,
                  html: response.html,
                });
                responded = true;
                resolve(result as LinkWithDescription[]);
              } else {
                reject(detectScraperFailure(response));
              }
            },
          );
        });
      },
      () => API.searchWeb(query),
    );
  }

  async searchLi(query: {
    q: string;
    type: "all" | "companies" | "people";
    page?: number;
    shouldSearchPersonalConnections?: boolean; // if true, q is ignored
  }): Promise<LinkedinSearchResults> {
    return await this.tryScraping(
      "search li",
      async () => {
        if (!query.page) query.page = 0;

        const urlObj = new URL(`https://www.linkedin.com/search/results/${query.type}`);
        urlObj.searchParams.set("page", `${query.page + 1}`);

        if (query.shouldSearchPersonalConnections) {
          urlObj.searchParams.set("network", '["F"]');
          urlObj.searchParams.set("origin", "MEMBER_PROFILE_CANNED_SEARCH");
          urlObj.searchParams.set("sid", "kHA");
        } else {
          urlObj.searchParams.set("keywords", query.q);
        }
        const url = urlObj.toString();

        return await new Promise((resolve, reject) => {
          let responded = false;
          setTimeout(() => {
            if (!responded) {
              this.timedOut = true;
              reject(new Error("Extension timed out"));
            }
          }, this.TIMEOUT);

          chrome?.runtime?.sendMessage(
            this.activeExtensionId,
            { scrape: url },
            async (response: ScrapeResponse) => {
              if (response?.scraped && response?.html) {
                logger.info("[extensionScraper] scrape response:", response);
                const result = await API.searchScraped({
                  url: response.scraped,
                  html: response.html,
                });
                responded = true;
                resolve(result as LinkedinSearchResults);
              } else {
                reject(detectScraperFailure(response));
              }
            },
          );
        });
      },
      () => API.searchLi(query),
    );
  }

  /**
   * Generic scraping function that returns raw HTML from a URL
   * @param url The URL to scrape
   * @returns Promise with the HTML content as a string
   */
  async scrapeUrl(url: string, sendToBackend?: boolean): Promise<string> {
    return await new Promise<string>((resolve, reject) => {
      let responded = false;
      setTimeout(() => {
        if (!responded) {
          this.timedOut = true;
          reject(new Error("Extension timed out"));
        }
      }, this.TIMEOUT);

      chrome?.runtime?.sendMessage(
        this.activeExtensionId,
        { scrape: url, sendToBackend },
        (response: ScrapeResponse) => {
          responded = true;
          if (response?.html) {
            logger.info("[extensionScraper] received HTML response");
            resolve(response.html);
          } else {
            reject(detectScraperFailure(response));
          }
        },
      );
    });
  }
}

const detectScraperFailure = (response: ScrapeResponse) => {
  if (response?.failed) {
    return new Error(response.failed);
  }
  if (response) {
    logger.info(response);
    return new Error("unknown response");
  }
  return new Error("no response");
};

const extensionScraper = new ExtensionScraper();
export default extensionScraper;
