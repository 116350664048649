import { CaptureOptions, PostHog as PostHogJS } from "posthog-js";
import { PostHog as PostHogNode } from "posthog-node";

/**
 * This wraps the Posthog JavaScript / Node SDK for event tracking.
 */
class EventTracker {
  posthogJS?: PostHogJS;
  posthogNode?: PostHogNode;

  /** on the server you MUST pass in a userId */
  capture(
    eventName: string,
    properties?: Record<string | number, any> | null,
    options?: CaptureOptions,
    userId?: string,
  ) {
    if (this.posthogJS) {
      return this.posthogJS.capture(eventName, properties, options);
    }

    if (this.posthogNode) {
      if (!userId) {
        // this is an error so that people don't forget to pass in a userId
        throw new Error("userId is required on the server");
      }
      this.posthogNode.capture({
        distinctId: userId,
        event: eventName,
        properties: properties || {},
      });
      return;
    }

    console.warn("EventTracker.capture called but posthog is not initialized", {
      eventName,
      properties,
      options,
    });
  }

  /** this is a server-only function to help mandate userId */
  captureServer(userId: string, eventName: string, properties: Record<string | number, any>) {
    if (!this.posthogNode) {
      return;
    }

    this.posthogNode.capture({
      distinctId: userId,
      event: eventName,
      properties: properties || {},
    });
  }
}

const eventTracker = new EventTracker();

export default eventTracker;
